import { API } from "aws-amplify";
import { replaceAllChars } from "./utilsLib";

export async function generatePdf(htmlBody) {
  let response = await API.post("pdfGenerator", `/new-pdf`, {
    body: htmlBody
  });
  return response;
}

async function logSentEmail(emailDestination, emailBody) {
  let response = await API.post("logger", `/add`, {
    body: { endpoint: `dashboard-email`, destination: emailDestination, email: emailBody, timestamp: Date.now(), logType: "dashboard" }
  });
  return response;
}

export async function createPdf(pdfObject) {
  const { filename, folderPath, html, bucket, tags, metadata } = pdfObject;
  let response = await API.post("pdfGenerator", `/new-pdf`, {
    body: { filename, folderPath, html, bucket, tags, metadata }
  });
  return response;
}

export const loadJsonFromS3 = async (path, setJson) => {
  const dataFromS3 = await getItemFromS3(path);
  const jsonBlob = new Blob([new Uint8Array(dataFromS3.Body.data)], { type: 'application/json' });
  const fr = new FileReader();
  fr.onload = (e) => {
    let parsedJson = JSON.parse(e.target.result);
    setJson(parsedJson);
  }
  fr.readAsText(jsonBlob);
}

export async function getItemFromS3(key, bucket) {
  const config = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_STAGE.toUpperCase()}-apiConfig`));
  const params = {
    "Bucket": bucket || config.s3.BUCKET,
    "Key": key
  }
  return await s3Interface('getObject', params)
}

export async function deleteItemFromS3(key, bucket) {
  const config = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_STAGE.toUpperCase()}-apiConfig`));
  const params = {
    "Bucket": bucket || config.s3.BUCKET,
    "Key": key
  }
  return await s3Interface('deleteObject', params)
}

export async function getSignedUrlFromS3(key, operation, bucket) {
  const config = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_STAGE.toUpperCase()}-apiConfig`));
  const params = {
    "Bucket": bucket || config.s3.BUCKET,
    "Key": key
  }
  let response = await API.post("zephyrx", `/s3`, {
    body: { method: 'getSignedUrlPromise', params, operation }
  });
  return response;
}

export async function insertLogoIntoS3(file, organizationID) {
  const config = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_STAGE.toUpperCase()}-apiConfig`));
  let response = await API.post("zephyrx", `/s3`, {
    body: {
      method: 'getSignedUrlPromise',
      params: {
        Bucket: config.s3.BUCKET,
        Key: `logo/${organizationID}/${file.name}`,
      },
      operation: 'putObject'
    }
  });
  await fetch(response, {
    method: 'PUT',
    body: file,
  })
  return file.name;
}

export async function insertJsonIntoS3(json, organizationID, type) {
  // type => contraindication or custom_survey
  const config = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_STAGE.toUpperCase()}-apiConfig`));
  let response = await API.post("zephyrx", `/s3`, {
    body: {
      method: 'getSignedUrlPromise',
      params: {
        Bucket: config.s3.BUCKET,
        Key: `survey/${organizationID}/${type}`,
      },
      operation: 'putObject'
    }
  });
  await fetch(response, {
    method: 'PUT',
    body: json,
  });
  return json.name;
}

async function s3Interface(method, params) {
  let response = await API.post("zephyrx", `/s3`, {
    body: { method, params }
  });
  return response;
}

export async function getJiraIssue(issue) {
  return await jiraInterface('get', { issue });
}

// key = ZEP, etc
// summary = title of issue
// description = text in issue
// type = bug, task, epic
// provider = name of logged in user creating the ticket
// assignee = name of ticket assignee ['brian', 'chris', 'alec', 'dru'] for right now

export async function createJiraIssue({ key, summary, description, type, provider, assignee }) {
  return await jiraInterface('create', { key, summary, description, type, provider, assignee });
};

async function jiraInterface(method, params) {
  let response = await API.post("zephyrx", `/jira`, {
    body: { method, params }
  });
  return response;
}

// to send an email:
/**
var params = {
  Destination: {  required
    CcAddresses: [
      'EMAIL_ADDRESS',
        more items
    ],
    ToAddresses: [
      'EMAIL_ADDRESS',
        more items
    ]
  },
  Message: {  required
    Body: {  required
      Html: {
        Charset: "UTF-8",
        Data: "HTML_FORMAT_BODY"
      },
      Text: {
        Charset: "UTF-8",
        Data: "TEXT_FORMAT_BODY"
      }
    },
    Subject: {
      Charset: 'UTF-8',
      Data: 'Test email'
    }
  },
  Source: 'SENDER_EMAIL_ADDRESS',  required
  ReplyToAddresses: [
    'EMAIL_ADDRESS',
      more items
  ],
};

*/

export function ping() {
  return API.get("zephyrx", "/ping");
}

export function sendEmail(email) {
  return API.post("zephyrx", "/email", {
    body: JSON.stringify(email)
  });
}

const signupConfirmationEmail = (emailAddress) => (
  {
    Destination: {
      ToAddresses: [emailAddress]
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data:
            `<!doctype html>
              <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
                <head>
                  <title>ZEPHYRx Dashboard Signup Complete</title> <!--[if !mso]>
                  <!-- -->
                    <meta http-equiv="X-UA-Compatible" content="IE=edge"> <!--<![endif]-->
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                    <style type="text/css">#outlook a{padding:0}body{margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%}table,td{border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt}img{border:0;height:auto;line-height:100%;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic}p{display:block;margin:13px 0}</style>
                    <!--[if mso]> <xml> <o:OfficeDocumentSettings> <o:AllowPNG/> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings> </xml> <![endif]--> <!--[if lte mso 11]>
                    <style type="text/css">.mj-outlook-group-fix{width:100% !important}</style>
                    <![endif]--> <!--[if !mso]>
                    <!-->
                    <link href="https://fonts.googleapis.com/css?family=Nunito" rel="stylesheet" type="text/css">
                    <style type="text/css">@import url(https://fonts.googleapis.com/css?family=Nunito);</style>
                  <!--<![endif]-->
                  <style type="text/css">@media only screen and (min-width:480px){.mj-column-per-100{width:100% !important;max-width:100%}}</style>
                  <style type="text/css">@media only screen and (max-width:480px){table.mj-full-width-mobile{width:100% !important}td.mj-full-width-mobile{width:auto !important}}</style>
                </head>
                <body>
                  <div style=""> <!--[if mso | IE]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" >
                      <tr>
                        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"> <![endif]-->
                          <div style="margin:0px auto;max-width:600px;">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                      <tbody>
                      <tr>
                    <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"> <!--[if mso | IE]>
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                    <tr>
                <td class="" style="vertical-align:top;width:600px;" > <![endif]-->
                <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                <tbody>
                <tr>
                <td style="width:550px;"> <img height="auto" src="https://zephyrx-development.s3.amazonaws.com/invitation_banner.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="550" />
              </td>
              </tr>
              </tbody>
              </table>
              </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <div style="font-family:Nunito, Avenir, sans-serif;font-size:16px;line-height:1.6;text-align:left;color:#000000;">
                  <strong>You have successfully registered your account with ZEPHYRx!</div>
              </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <div style="font-family:Nunito, Avenir, sans-serif;font-size:16px;line-height:1.6;text-align:left;color:#000000;">You can now use the dashboard to monitor your patients.</div>
              </td>
              </tr>
              <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%;">
                <tr>
                <td align="center" bgcolor="#2B8CF3" role="presentation" style="border:none;border-radius:16px;cursor:auto;mso-padding-alt:14px 25px;background:#2B8CF3;" valign="middle"> <a href="https://dashboard.zephyrx.com/" style="display:inline-block;background:#2B8CF3;color:#ffffff;font-family:Nunito, Avenir, sans-serif;font-size:16px;font-weight:normal;line-height:1.6;margin:0;text-decoration:none;text-transform:none;padding:14px 25px;mso-padding-alt:0px;border-radius:16px;" target="_blank">Click to return to the ZEPHYRx Provider Dashboard</a>
              </td>
              </tr>
              </table>
              </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <div style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">Link not working? You may instead copy and paste this link into a browser.<br/> <a href="https://dashboard.zephyrx.com/">dashboard.zephyrx.com</a>
              </div>
              </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <div style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">Need help? Email us at <a href="mailto:support@zephyrx.com">support@zephyrx.com</a>
                <br/> Learn more at <a href="https://www.zephyrx.com/">www.zephyrx.com</a>
              </div>
              </td>
              </tr>
              </table>
              </div> <!--[if mso | IE]>
              </td>
              </tr>
              </table> <![endif]-->
              </td>
              </tr>
              </tbody>
              </table>
              </div> <!--[if mso | IE]>
              </td>
              </tr>
              </table> <![endif]-->
              </div>
            </body>
          </html>`
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: 'ZEPHYRx Dashboard Signup Complete'
      }
    },
    Source: 'support@zephyrx.com',
    ReplyToAddresses: ['support@zephyrx.com'],
  }
)

export function sendSignupConfirmationEmail(emailAddress) {
  logSentEmail(emailAddress, signupConfirmationEmail(emailAddress));
  return API.post("zephyrx", "/email", {
    body: signupConfirmationEmail(emailAddress)
  });
}

const addedToOrganization = (invitedEmailAddress, providerEmailAddress, organizationName) => {
  const zephyrxLink = process.env.REACT_APP_STAGE.toUpperCase() === 'TEST' ? "https://test.dashboard.zephyrx.com/login" : process.env.REACT_APP_STAGE.toUpperCase() === 'PREVIEW' ? "https://preview.dashboard.zephyrx.com/login" : "https://dashboard.zephyrx.com/login"

  return ({
    Destination: {
      ToAddresses: [invitedEmailAddress]
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data:
            `<!doctype html>
              <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
              <head>
              <title>You have been added to a new organization on the ZEPHYRx Dashboard</title> <!--[if !mso]>
              <!-- -->
              <meta http-equiv="X-UA-Compatible" content="IE=edge"> <!--<![endif]-->
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1">
              <style type="text/css">#outlook a{padding:0}body{margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%}table,td{border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt}img{border:0;height:auto;line-height:100%;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic}p{display:block;margin:13px 0}</style>
              <!--[if mso]> <xml> <o:OfficeDocumentSettings> <o:AllowPNG/> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings> </xml> <![endif]--> <!--[if lte mso 11]>
              <style type="text/css">.mj-outlook-group-fix{width:100% !important}</style>
              <![endif]--> <!--[if !mso]>
              <!-->
                <link href="https://fonts.googleapis.com/css?family=Nunito" rel="stylesheet" type="text/css">
              <style type="text/css">@import url(https://fonts.googleapis.com/css?family=Nunito);</style>
              <!--<![endif]-->
              <style type="text/css">@media only screen and (min-width:480px){.mj-column-per-100{width:100% !important;max-width:100%}}</style>
              <style type="text/css">@media only screen and (max-width:480px){table.mj-full-width-mobile{width:100% !important}td.mj-full-width-mobile{width:auto !important}}</style>
              </head>
              <body>
              <div style=""> <!--[if mso | IE]>
              <table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" >
              <tr>
                <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"> <![endif]-->
                <div style="margin:0px auto;max-width:600px;">
                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                <tbody>
                <tr>
              <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"> <!--[if mso | IE]>
              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td class="" style="vertical-align:top;width:600px;" > <![endif]-->
                <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                <tbody>
                <tr>
                <td style="width:550px;"> <img height="auto" src="https://zephyrx-development.s3.amazonaws.com/invitation_banner.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="550" />
              </td>
              </tr>
              </tbody>
              </table>
              </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Nunito, Avenir, sans-serif;font-size:16px;line-height:1.6;text-align:left;color:#000000;">
                    <strong>You have been added to ${organizationName} by ${providerEmailAddress}.
                  </div>
                </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <div style="font-family:Nunito, Avenir, sans-serif;font-size:16px;line-height:1.6;text-align:left;color:#000000;">
                  To see patients in ${organizationName}, please log out and log back in again. No other action is required on your part.
                </div>
              </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <div style="font-family:Nunito, Avenir, sans-serif;font-size:16px;line-height:1.6;text-align:left;color:#000000;">
                  If you believe you were added in error, please contact <a href="mailto:support@zephyrx.com">support@zephyrx.com</a> or the inviting provider <a href="mailto:${providerEmailAddress}">${providerEmailAddress}</a> immediately.
                </div>
              </td>
              </tr>
              <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%;">
                <tr>
                <td align="center" bgcolor="#2B8CF3" role="presentation" style="border:none;border-radius:16px;cursor:auto;mso-padding-alt:14px 25px;background:#2B8CF3;" valign="middle"> <a href=${zephyrxLink} style="display:inline-block;background:#2B8CF3;color:#ffffff;font-family:Nunito, Avenir, sans-serif;font-size:16px;font-weight:normal;line-height:1.6;margin:0;text-decoration:none;text-transform:none;padding:14px 25px;mso-padding-alt:0px;border-radius:16px;" target="_blank">Click to Log In to the ZEPHYRx Provider Dashboard</a>
              </td>
              </tr>
              </table>
              </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <div style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">Link not working? You may instead copy and paste this link into a browser.<br/> <a href=${zephyrxLink}>${zephyrxLink}</a>
              </div>
              </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                <div style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">Need help? Email us at <a href="mailto:support@zephyrx.com">support@zephyrx.com</a>
                <br/> Learn more at <a href="https://www.zephyrx.com/">www.zephyrx.com</a>
              </div>
              </td>
              </tr>
              </table>
              </div> <!--[if mso | IE]>
              </td>
              </tr>
              </table> <![endif]-->
              </td>
              </tr>
              </tbody>
              </table>
              </div> <!--[if mso | IE]>
              </td>
              </tr>
              </table> <![endif]-->
              </div>
              </body>
            </html>`
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: 'You have been added to a new organization on the ZEPHYRx Dashboard'
      }
    },
    Source: 'support@zephyrx.com',
    ReplyToAddresses: ['support@zephyrx.com'],
  });
}

export function sendAddedToOrganizationEmail(invitedEmailAddress, providerEmailAddress, organizationName) {
  logSentEmail(invitedEmailAddress, addedToOrganization(invitedEmailAddress, providerEmailAddress, organizationName));
  return API.post("zephyrx", "/email", {
    body: addedToOrganization(invitedEmailAddress, providerEmailAddress, organizationName)
  });
}

const changedLoginEmail = (newEmail, oldEmail) => {
  const zephyrxLink = process.env.REACT_APP_STAGE.toUpperCase() === 'TEST' ? "https://test.dashboard.zephyrx.com/login" : process.env.REACT_APP_STAGE.toUpperCase() === 'PREVIEW' ? "https://preview.dashboard.zephyrx.com/login" : "https://dashboard.zephyrx.com/login"

  return ({
    Destination: {
      ToAddresses: [newEmail, oldEmail]
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data:
            `<html>
              <body>
                <a href="https://dashboard.zephyrx.com/"><img width="800px" src="https://zephyrx-development.s3.amazonaws.com/invitation_banner.png"></img></a>
                <p>You have updated your email address used for signing in to the ZEPHYRx Dashboard from ${oldEmail} to ${newEmail}.</p>
                <p>If this was an error or you did not perform this action, please contact <a href="mailto:support@zephyrx.com">support@zephyrx.com</a> immediately.</p>
                <p>Please log in at <a href=${zephyrxLink}>${zephyrxLink}</a></p>
              </body>
            </html>`
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: 'You have updated your login email address at ZEPHYRx'
      }
    },
    Source: 'support@zephyrx.com',
    ReplyToAddresses: ['support@zephyrx.com'],
  });
}

export function sendChangedLoginEmail(newEmail, oldEmail) {
  logSentEmail(newEmail, changedLoginEmail(newEmail, oldEmail));
  return API.post("zephyrx", "/email", {
    body: changedLoginEmail(newEmail, oldEmail)
  });
}

const changedPasswordEmail = (email) => {
  const zephyrxLink = process.env.REACT_APP_STAGE.toUpperCase() === 'TEST' ? "https://test.dashboard.zephyrx.com/login" : process.env.REACT_APP_STAGE.toUpperCase() === 'PREVIEW' ? "https://preview.dashboard.zephyrx.com/login" : "https://dashboard.zephyrx.com/login"

  return ({
    Destination: {
      ToAddresses: [email]
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data:
            `<!doctype html>
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
              <head>
                <title> You have updated your login password at ZEPHYRx</title>
                <!--[if !mso]><!-- --><meta http-equiv="X-UA-Compatible" content="IE=edge"> <!--<![endif]-->
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1">
                <style type="text/css">#outlook a{padding:0}body{margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%}table,td{border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt}img{border:0;height:auto;line-height:100%;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic}p{display:block;margin:13px 0}</style><!--[if mso]>
                <xml> <o:OfficeDocumentSettings> <o:AllowPNG/> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings> </xml> <![endif]--> <!--[if lte mso 11]><style type="text/css">.mj-outlook-group-fix{width:100% !important}</style><![endif]--> <!--[if !mso]><!-->
                <link href="https://fonts.googleapis.com/css?family=Nunito" rel="stylesheet" type="text/css"><style type="text/css">@import url(https://fonts.googleapis.com/css?family=Nunito);</style><!--<![endif]--><style type="text/css">@media only screen and (min-width:480px){.mj-column-per-100{width:100% !important;max-width:100%}}</style><style type="text/css">@media only screen and (max-width:480px){table.mj-full-width-mobile{width:100% !important}td.mj-full-width-mobile{width:auto !important}}</style>
              </head>
              <body>
                <div style=""> <!--[if mso | IE]>
                  <table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr>
                    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"> <![endif]-->
                      <div style="margin:0px auto;max-width:600px;">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                          <tbody>
                            <tr><td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"> <!--[if mso | IE]>
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>	<td class="" style="vertical-align:top;width:600px;" > <![endif]-->
                                <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">	<tr>	<td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                      <tbody>
                                        <tr>
                                          <td style="width:550px;">
                                            <img height="auto" src="https://zephyrx-development.s3.amazonaws.com/invitation_banner.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="550" />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                    <div style="font-family:Nunito, Avenir, sans-serif;font-size:16px;line-height:1.6;text-align:left;color:#000000;">
                                      <strong>You have updated your password used for signing in to the ZEPHYRx Dashboard.
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                    <div style="font-family:Nunito, Avenir, sans-serif;font-size:16px;line-height:1.6;text-align:left;color:#000000;">
                                      If this was an error or you did not perform this action, please contact <a href="mailto:support@zephyrx.com">support@zephyrx.com</a> immediately.
                                    </div>
                                  </td>
                                </tr>
                                  <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%;">
                                      <tr>
                                        <td align="center" bgcolor="#2B8CF3" role="presentation" style="border:none;border-radius:16px;cursor:auto;mso-padding-alt:14px 25px;background:#2B8CF3;" valign="middle">
                                          <a href=${zephyrxLink} style="display:inline-block;background:#2B8CF3;color:#ffffff;font-family:Nunito, Avenir, sans-serif;font-size:16px;font-weight:normal;line-height:1.6;margin:0;text-decoration:none;text-transform:none;padding:14px 25px;mso-padding-alt:0px;border-radius:16px;" target="_blank">
                                            Click to log in to the ZEPHYRx Provider Dashboard
                                          </a>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                    <div style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">
                                      Link not working? You may instead copy and paste this link into a browser.<br/> <a href=${zephyrxLink}>${zephyrxLink}</a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                    <div style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">
                                      Need help? Email us at <a href="mailto:support@zephyrx.com">support@zephyrx.com</a>
                                      <br/>
                                      Learn more at <a href="https://www.zephyrx.com/">www.zephyrx.com</a>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div> <!--[if mso | IE]></td></tr></table> <![endif]-->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> <!--[if mso | IE]></td></tr></table> <![endif]-->
                </div>
              </body>
            </html>`
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: 'You have updated your login password at ZEPHYRx'
      }
    },
    Source: 'support@zephyrx.com',
    ReplyToAddresses: ['support@zephyrx.com'],
  });
}

export function sendChangedPasswordEmail(email) {
  logSentEmail(email, changedPasswordEmail(email));
  return API.post("zephyrx", "/email", {
    body: changedPasswordEmail(email)
  });
}

const wrongCffID = (org, orgID, patientID, cffID, email, providerID) => (
  {
    Destination: {
      ToAddresses: ['support@zephyrx.com']
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data:
            `<html>
              <body>
                <p>Incorrect CFF ID for ${org}</p>
                <p>Organization ID: ${orgID}</p>
                <p>Patient ID: ${patientID}</p>
                <p>CFF ID: ${cffID}</p>
                <p>Reported by: ${email}</p>
                <p>Reporting Provider ID: ${providerID}</p>
              </body>
            </html>`
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: 'Incorrect CFF ID Reported'
      }
    },
    Source: 'support@zephyrx.com',
    ReplyToAddresses: ['support@zephyrx.com'],
  }
)

export function sendWrongCffIdEmail(provider, patientID, cffID) {
  return API.post("zephyrx", "/email", {
    body: wrongCffID(provider?.viewingAs?.name, provider?.viewingAs?.organizationID, patientID, cffID, provider.email, provider.providerID)
  });
}

/**
 *
 * @param {string} providerEmail
 * @param {string} patientID
 * @param {string} seriesID
 * @param {import("../Types/Common/PftTypes").PftType} pftType
 * @returns Object for body of POST request to send an email from ZEPHYRx
 */
const completedPFTNowEmail = (providerEmail, patientID, seriesID, pftType = 'fvc') => {
  const zephyrxLink = process.env.REACT_APP_STAGE.toUpperCase() === 'TEST' ? `https://test.dashboard.zephyrx.com/patient/${patientID}/${pftType}/${seriesID}` : process.env.REACT_APP_STAGE.toUpperCase() === 'PREVIEW' ? `https://preview.dashboard.zephyrx.com/patient/${patientID}/${pftType}/${seriesID}` : `https://dashboard.zephyrx.com/patient/${patientID}/${pftType}/${seriesID}`;
  return {
    Destination: {
      ToAddresses: [providerEmail]
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data:
            `<!doctype html>
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
              <head>
                <title> PFTNow Complete! </title>
                <!--[if !mso]><!-- --><meta http-equiv="X-UA-Compatible" content="IE=edge"> <!--<![endif]-->
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1">
                <style type="text/css">#outlook a{padding:0}body{margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%}table,td{border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt}img{border:0;height:auto;line-height:100%;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic}p{display:block;margin:13px 0}</style><!--[if mso]>
                <xml> <o:OfficeDocumentSettings> <o:AllowPNG/> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings> </xml> <![endif]--> <!--[if lte mso 11]><style type="text/css">.mj-outlook-group-fix{width:100% !important}</style><![endif]--> <!--[if !mso]><!-->
                <link href="https://fonts.googleapis.com/css?family=Nunito" rel="stylesheet" type="text/css"><style type="text/css">@import url(https://fonts.googleapis.com/css?family=Nunito);</style><!--<![endif]--><style type="text/css">@media only screen and (min-width:480px){.mj-column-per-100{width:100% !important;max-width:100%}}</style><style type="text/css">@media only screen and (max-width:480px){table.mj-full-width-mobile{width:100% !important}td.mj-full-width-mobile{width:auto !important}}</style>
              </head>
              <body>
                <div>
                  <!--[if mso | IE]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr>
                      <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
                  <![endif]-->
                  <div style="margin:0px auto;max-width:600px;">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                      <tbody>
                        <tr>
                          <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                            <!--[if mso | IE]>
                            <table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>	<td class="" style="vertical-align:top;width:600px;" >
                            <![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix"
                              style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                                <tr>
                                  <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                      <tbody>
                                        <tr>
                                          <td style="width:550px;">
                                            <img height="auto"
                                              src="https://zephyrx-development.s3.amazonaws.com/invitation_banner.png"
                                              style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;"
                                              width="550" />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                    <div style="font-family:Nunito, Avenir, sans-serif;font-size:16px;line-height:1.6;text-align:left;color:#000000;">
                                      <strong>Your patient has completed their PFTNow!</strong>
                                    </div>
                                  </td>
                                </tr>
                                <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%;">
                                    <tr>
                                      <td align="center" bgcolor="#2B8CF3" role="presentation" style="border:none;border-radius:16px;cursor:auto;mso-padding-alt:14px 25px;background:#2B8CF3;" valign="middle">
                                        <a href=${zephyrxLink}
                                          style="display:inline-block;background:#2B8CF3;color:#ffffff;font-family:Nunito, Avenir, sans-serif;font-size:16px;font-weight:normal;line-height:1.6;margin:0;text-decoration:none;text-transform:none;padding:14px 25px;mso-padding-alt:0px;border-radius:16px;"
                                          target="_blank">
                                          Click to view their results via the ZEPHYRx Dashboard
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                          </tr>
                          <tr>
                            <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                              <div style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">
                                Link not working? You may instead copy and paste this link into a browser.
                                <br />
                                <a href=${zephyrxLink}>${zephyrxLink}</a>
                              </div>
                            </td>
                          </tr>
                        <tr>
                          <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                            <div style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">
                              Need help? Email us at <a href="mailto:support@zephyrx.com">support@zephyrx.com</a>
                              <br />
                              Learn more at <a href="https://www.zephyrx.com/">www.zephyrx.com</a>
                            </div>
                          </td>
                        </tr>
                    </table>
                  </div>
                <!--[if mso | IE]></td></tr></table> <![endif]-->
                </td>
                </tr>
              </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td></tr></table> <![endif]-->
            </div>
            </body>
          </html>`
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: 'PFTNow Complete!'
      }
    },
    Source: 'support@zephyrx.com',
    ReplyToAddresses: ['support@zephyrx.com'],
  };
};

/**
 *
 * @param {string} providerEmail
 * @param {string} patientID
 * @param {string} seriesID
 * @param {import("../Types/Common/PftTypes").PftType} pftType
 * @returns A promise to send an email from ZEPHYRx to the passed in providerEmail
 */
export function sendCompletedPFTNowEmail(providerEmail, patientID, seriesID, pftType) {
  return API.post("zephyrx", "/email", {
    body: completedPFTNowEmail(providerEmail, patientID, seriesID, pftType)
  });
};

/**
 *
 * @param {string} providerEmail
 * @param {string} patientID
 * @returns Object for body of POST request to send an email from ZEPHYRx
 */
const incompletePFTNowEmail = (providerEmail, patientID) => {
  const zephyrxLink = process.env.REACT_APP_STAGE.toUpperCase() === 'TEST' ? `https://test.dashboard.zephyrx.com/patient/${patientID}` : process.env.REACT_APP_STAGE.toUpperCase() === 'PREVIEW' ? `https://preview.dashboard.zephyrx.com/patient/${patientID}` : `https://dashboard.zephyrx.com/patient/${patientID}`;
  return {
    Destination: {
      ToAddresses: [providerEmail]
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data:
            `<!doctype html>
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
              xmlns:o="urn:schemas-microsoft-com:office:office">

            <head>
              <title> Failure to complete PFTNow </title>
              <!--[if !mso]><!-- -->
              <meta http-equiv="X-UA-Compatible" content="IE=edge">
              <!--<![endif]-->
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1">
              <style type="text/css">
                #outlook a {
                  padding: 0
                }

                body {
                  margin: 0;
                  padding: 0;
                  -webkit-text-size-adjust: 100%;
                  -ms-text-size-adjust: 100%
                }

                table,
                td {
                  border-collapse: collapse;
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt
                }

                img {
                  border: 0;
                  height: auto;
                  line-height: 100%;
                  outline: none;
                  text-decoration: none;
                  -ms-interpolation-mode: bicubic
                }

                p {
                  display: block;
                  margin: 13px 0
                }
              </style>
              <!--[if mso]>
                            <xml> <o:OfficeDocumentSettings> <o:AllowPNG/> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings> </xml> <![endif]-->
              <!--[if lte mso 11]><style type="text/css">.mj-outlook-group-fix{width:100% !important}</style><![endif]-->
              <!--[if !mso]><!-->
              <link href="https://fonts.googleapis.com/css?family=Nunito" rel="stylesheet" type="text/css">
              <style type="text/css">
                @import url(https://fonts.googleapis.com/css?family=Nunito);
              </style>
              <!--<![endif]-->
              <style type="text/css">
                @media only screen and (min-width:480px) {
                  .mj-column-per-100 {
                    width: 100% !important;
                    max-width: 100%
                  }
                }
              </style>
              <style type="text/css">
                @media only screen and (max-width:480px) {
                  table.mj-full-width-mobile {
                    width: 100% !important
                  }

                  td.mj-full-width-mobile {
                    width: auto !important
                  }
                }
              </style>
            </head>

            <body>
              <div>
                <!--[if mso | IE]>
                                <table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr>
                                  <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
                              <![endif]-->
                <div style="margin:0px auto;max-width:600px;">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                    <tbody>
                      <tr>
                        <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                          <!--[if mso | IE]>
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>	<td class="" style="vertical-align:top;width:600px;" >
                                        <![endif]-->
                          <div class="mj-column-per-100 mj-outlook-group-fix"
                            style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;"
                              width="100%">
                              <tr>
                                <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                    style="border-collapse:collapse;border-spacing:0px;">
                                    <tbody>
                                      <tr>
                                        <td style="width:550px;">
                                          <img height="auto"
                                            src="https://zephyrx-development.s3.amazonaws.com/invitation_banner.png"
                                            style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;"
                                            width="550" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                  <div
                                    style="font-family:Nunito, Avenir, sans-serif;font-size:16px;line-height:1.6;text-align:left;color:#000000;">
                                    <strong>Your patient failed to complete their PFTNow!</strong>
                                  </div>
                                </td>
                              </tr>

                              <td align="center" vertical-align="middle"
                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                  style="border-collapse:separate;line-height:100%;">
                                  <tr>
                                    <td align="center" bgcolor="#2B8CF3" role="presentation"
                                      style="border:none;border-radius:16px;cursor:auto;mso-padding-alt:14px 25px;background:#2B8CF3;"
                                      valign="middle">
                                      <a href=${zephyrxLink}
                                        style="display:inline-block;background:#2B8CF3;color:#ffffff;font-family:Nunito, Avenir, sans-serif;font-size:16px;font-weight:normal;line-height:1.6;margin:0;text-decoration:none;text-transform:none;padding:14px 25px;mso-padding-alt:0px;border-radius:16px;"
                                        target="_blank">
                                        Click to view their patient profile via the ZEPHYRx Dashboard
                                      </a>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                      </tr>
                      <tr>
                        <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                          <div
                            style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">
                            Link not working? You may instead copy and paste this link into a browser.
                            <br />
                            <a href=${zephyrxLink}>${zephyrxLink}</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                          <div
                            style="font-family:Nunito, Avenir, sans-serif;font-size:12px;line-height:1.6;text-align:left;color:#666666;">
                            Need help? Email us at <a href="mailto:support@zephyrx.com">support@zephyrx.com</a>
                            <br />
                            Learn more at <a href="https://www.zephyrx.com/">www.zephyrx.com</a>
                          </div>
                        </td>
                      </tr>
                  </table>
                </div>
                <!--[if mso | IE]></td></tr></table> <![endif]-->
                </td>
                </tr>
                </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table> <![endif]-->
              </div>
            </body>
            </html>`
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: 'Incomplete PFTNow!'
      }
    },
    Source: 'support@zephyrx.com',
    ReplyToAddresses: ['support@zephyrx.com'],
  };
};

/**
 *
 * @param {string} providerEmail
 * @param {string} patientID
 * @returns A promise to send an email from ZEPHYRx to the passed in providerEmail
 */
export function sendIncompletePFTNowEmail(providerEmail, patientID) {
  return API.post("zephyrx", "/email", {
    body: incompletePFTNowEmail(providerEmail, patientID)
  });
};

export async function checkIfStudyIDProgramExists(program) {
  const response = await API.post("zephyrx", "/study", {
    body: { method: 'metadata', params: { program: program.toLowerCase() } }
  });
  return response;
}

export async function createProgramForStudyIDs(program) {
  const response = await API.post("zephyrx", "/study", {
    body: { method: 'add', params: { program: program.toLowerCase(), hide_names: true } }
  });
  return response;
}

export async function createStudyID(program, studyID, patientID) {
  const response = await API.post("zephyrx", "/study", {
    body: { method: 'add', params: { program: program.toLowerCase(), studyID, patientID } }
  });
  return response;
}

/**
 * @returns {Promise<{ studyID?: import("../Types/Common/BrandedTypes").StudyID }>}
 */
export async function getStudyID(program, patientID) {
  const response = await API.post("zephyrx", "/study", {
    body: { method: 'get', params: { program: program.toLowerCase(), patientID } }
  });
  return response;
}

export async function updateStudyID(program, oldStudyID, newStudyID) {
  const response = await API.post("zephyrx", "/study", {
    body: { method: 'update', params: { program: replaceAllChars(program, ' ', '').toLowerCase(), oldStudyID, newStudyID } }
  });
  return response;
}

/**
 * @param {string} program
 * @param {string} studyID
 * @returns {Promise<{ hashKey: string; rangeKey: string }>}
 */
export async function removeStudyID(program, studyID) {
  const response = await API.post("zephyrx", "/study", {
    body: { method: 'remove', params: { program: replaceAllChars(program, ' ', '').toLowerCase(), studyID } }
  });
  return response;
}

export async function getAllPrograms() {
  const response = await API.post("zephyrx", "/program", {
    body: { method: 'get' }
  });
  return response;
};

export async function createProgram(program) {
  const response = await API.post("zephyrx", "/program", {
    body: { method: "add", params: program }
  });
  return response;
};

export async function updateProgram(program) {
  const response = await API.post("zephyrx", "/program", {
    body: { method: "update", params: { ...program } }
  });
  return response;
};

export async function removeProgram(program) {
  const response = await API.post("zephyrx", "/program", {
    body: { method: "remove", params: { program: program.program } }
  });
  return response;
};

export async function samlProvider(email, local) {
  const response = await API.post("zephyrx", "/saml", {
    body: { method: "get", params: { email, local } }
  });
  return response;
}

export async function getSamlProvider(email, local) {
  return await API.get("zephyrx", `/saml/${email}`);
}

export async function getOverreadsForPatient(patientID) {
  const response = await API.post("zephyrx", "/overread", {
    body: {
      method: "get",
      params: {
        hashKey: `overread_${patientID}`
      }
    }
  });
  return response;
}

export async function addSeriesToOverread(patientID, allSeries) {
  const allSeriesArray = allSeries.map(series => (
    {
      numberOfEffortsNumber: series?.effortIDs?.length,
      patientID: patientID,
      pftType: series?.pftType ?? 'fvc',
      seriesID: series.seriesID,
      timestamp: series.roundtripTime
    }
  ));
  console.log('sending overread data for series:', allSeriesArray);
  const response = await API.post("zephyrx", "/overread", {
    body: {
      method: "update",
      params: {
        hashKey: `overread_${patientID}`,
        series: allSeriesArray
      }
    }
  });
  return response;
}

/**
 * @param {string} patientID
 * @param {string[]} seriesIDs
 */
export async function removeSeriesFromOverread(patientID, seriesIDs) {
  const response = await API.post("zephyrx", "/overread", {
    body: {
      method: "overread",
      params: {
        hashKey: `overread_${patientID}`,
        seriesIDs
      }
    }
  });
  return response;
}

export async function getSSOPools(params = {}) {
  return API.post("zephyrx", '/saml', { body: { method: 'get', params } });
};

export async function createSSOPool(params) {
  return API.post("zephyrx", '/saml', { body: { method: 'register', params } });
};

export async function deleteSSOPool(domain) {
  return API.post("zephyrx", '/saml', { body: { method: 'delete', params: { domain } } });
};

export async function getBillingOptions() {
  return API.get("zephyrx", '/billingOptions');
};

/**
 * @param {"region" | "accountOwner"} hashKey
 * @param {string} rangeKey
 */
export async function addBillingOption(hashKey, rangeKey) {
  const body = { hashKey, rangeKey };
  return API.post("zephyrx", '/billingOptions', { body });
};

export function chimeSessionInterface(method, params) {
  return API.post("zephyrx", `/chime`, {
    body: { method: method, params: params }
  });
}


export function rtcScheduler(method, params) {
  return API.post("zephyrx", `/rtcScheduler`, {
    body: { method: method, params: params }
  });
}

/**
 * @param {string} organizationID
 * @param {number} start
 * @param {number} end
 */
export const getOrganizationReimbursementInfo = (organizationID, start, end) => {
  return API.get("zephyrx", `/reimbursement?organizationID=${organizationID}&start=${start}&end=${end}`);
};