import { API } from "aws-amplify";
import { CSSObjectWithLabel } from "react-select";
import { Kiosk, MobileDevice, PatientForKiosk, Session, Spirometer, Demographics, KioskSessionPftReport, ArtiqTrialResult, PatientSelectOption } from "../Types/Common/KioskTypes";
import { FvcEffort, SpirometerID, OrganizationType } from "../Types/Common/Types";
import { getTimezoneOffsetInMilliseconds } from "./utilsLib";
import { FenoDeviceSerialNumber } from "../Types/Common/BrandedTypes";

const API_NAME = 'kiosk';
const API_PATH = '/kiosk';

export const getKioskAndOrgFromDeviceID = ({ deviceUniqueIdentifier }: Pick<MobileDevice, 'deviceUniqueIdentifier'>): Promise<{
  kiosk: Kiosk & Required<Pick<Kiosk, 'mobileDevice'>>;
  org: OrganizationType;
}
> => {
  return API.get(API_NAME, API_PATH, {
    queryStringParameters: {
      deviceUniqueIdentifier
    }
  });
};

export const getKiosksForOrganization = (organizationID: string, assign?: true): Promise<{ kiosks: Kiosk[] }> => {
  return API.get(API_NAME, API_PATH, {
    queryStringParameters: {
      organizationID,
      assign
    }
  });
};

export const createKiosk = (organizationID: string, name: string, mobileDevice?: MobileDevice): Promise<Kiosk> => {
  return API.post(API_NAME, API_PATH, {
    body: {
      organizationID,
      name,
      mobileDevice
    }
  });
};

export const deactivateKiosk = (kioskID: string): Promise<string> => {
  return API.put(API_NAME, API_PATH, {
    queryStringParameters: {
      kioskID,
      deactivate: true
    }
  });
};

export const deleteKiosk = (kioskID: string): Promise<200> => {
  return API.del(API_NAME, API_PATH, {
    queryStringParameters: {
      kioskID
    }
  });
};

export const deleteAllKiosksForOrganization = (organizationID: string): Promise<200> => {
  return API.del(API_NAME, API_PATH, {
    queryStringParameters: {
      organizationID
    }
  });
};

export const getAllKioskData = (kioskID: string, assign?: true): Promise<{
  kiosk: Kiosk,
  patients: Record<string, PatientForKiosk>,
  sessions: Session[],
  sessionsLastEvaluatedKey?: string;
}> => {
  return API.get(API_NAME, API_PATH, {
    queryStringParameters: {
      kioskID,
      assign
    }
  });
};

//  This is the time that it was assigned.
//    
//  NOTE: does not have 7 digits of sub-second precision like a normal {@link RoundtripTime}, only 3.
//    
//  roundtripTime: `${RoundtripTimeDate}T${number}${number}:${number}${number}:${number}${number}.${number}${number}${number}${TimezoneOffset}`;
export const assignMobileDeviceToKiosk = (kioskID: string, mobileDevice: MobileDevice): Promise<{ kioskID: string, mobileDevice: MobileDevice }> => {
  return API.put(API_NAME, API_PATH, {
    body: {
      kioskID,
      mobileDevice
    }
  });
};

export const unassignMobileDeviceFromKiosk = (kioskID: string): Promise<{ kioskID: string }> => {
  return API.get(API_NAME, API_PATH, {
    queryStringParameters: {
      kioskID,
      unassign: true
    }
  });
};

export const pairSpirometerToKiosk = (kioskID: string, spirometer: Partial<Spirometer>): Promise<{ kioskID: string, spirometer: Partial<Spirometer> }> => {
  return API.put(API_NAME, API_PATH, {
    body: {
      kioskID,
      spirometer,
      roundtripTime: new Date().toISOString()
    }
  });
};

export const unpairSpirometerFromKioskWithKiosk = (kioskID: string) => {
  return API.get(API_NAME, API_PATH, {
    queryStringParameters: {
      kioskID,
      unpair: true
    }
  });
};

export const unpairSpirometerFromKioskWithSpirometer = (spirometerID: string) => {
  return API.get(API_NAME, API_PATH, {
    queryStringParameters: {
      spirometerID,
      unpair: true
    }
  });
};

export const getKioskWithSpirometer = (spirometerID: string): Promise<{ 'kiosk': Kiosk | null }> => {
  return API.get(API_NAME, API_PATH, {
    queryStringParameters: {
      spirometerID,
    }
  });
};

type EditPatientBodyType = { patientID: string; demographics?: Demographics; program?: string; studyID?: string | null; spirometerID?: SpirometerID; fenoDeviceSerialNumber?: FenoDeviceSerialNumber };
interface CreatePatientBodyType extends Omit<EditPatientBodyType, 'patientID'> {
  organizationID: string;
  timezoneOffsetMs: number;
};

export const createPatient = (organizationID: string, demographics: Demographics, timezoneOffsetMs: number, program?: string, studyID?: string, spirometerID?: SpirometerID): Promise<PatientForKiosk> => {
  const body: CreatePatientBodyType = { organizationID, demographics, timezoneOffsetMs };
  if (program) body.program = program;
  if (studyID) body.studyID = studyID;
  if (spirometerID) body.spirometerID = spirometerID;
  return API.post(API_NAME, API_PATH, { body });
};

export const editPatient = (patientID: string, demographics?: Demographics, program?: string, studyID?: string | null, spirometerID?: SpirometerID, fenoDeviceSerialNumber?: FenoDeviceSerialNumber): Promise<PatientForKiosk> => {
  const body: EditPatientBodyType = { patientID, studyID };
  if (demographics) body.demographics = demographics;
  if (program) body.program = program;
  if (spirometerID) body.spirometerID = spirometerID;
  if (fenoDeviceSerialNumber) body.fenoDeviceSerialNumber = fenoDeviceSerialNumber;
  return API.put(API_NAME, API_PATH, { body });
};

export const startKioskSession = (kioskID: string, patientID: string, providerID: string, spirometerID?: string): Promise<Session> => {
  return API.post(API_NAME, API_PATH, {
    body: {
      kioskID,
      patientID,
      providerID,
      spirometerID,
      timezoneOffsetMs: getTimezoneOffsetInMilliseconds()
    }
  });
};

export const endSession = (kioskID: string): Promise<{ kioskID: string, kioskSessionID: string }> => {
  return API.put(API_NAME, API_PATH, {
    body: {
      kioskID,
    }
  });
};

export const getKioskSession = (kioskID: string, kioskSessionID: string): Promise<KioskSessionPftReport[]> => {
  return API.get(API_NAME, API_PATH, {
    queryStringParameters: {
      kioskID,
      kioskSessionID
    }
  })
};

export const sendMessageToCSharp = (type: string, message: any) => {
  try {
    window?.vuplex?.postMessage(btoa(JSON.stringify({ type, message })));
  } catch (e) {
    console.log(`Can't send message to vuplex (type: ${type}, message: ${message}): `, e);
  }
};

export const findArtiqTrialResult = (trialResults: ArtiqTrialResult[], effort: FvcEffort) => {
  return trialResults.find(result => result?.parameters?.every(parameter => Number(effort[parameter.name])?.toFixed(6) === Number(parameter.value)?.toFixed(6)));
};

export const filterPatientOption = (option: PatientSelectOption, inputValue: string, filterTypes = { name: true, studyID: true, patientID: false }) => {
  if (!option.label?.props?.patient) return false;
  if (!inputValue) return true;

  const { patient } = option.label.props;
  const filterByName = filterTypes.name === false ? false : patient.name?.toLowerCase()?.includes(inputValue.toLowerCase()) ?? false;
  const filterByStudyID = filterTypes.studyID === false ? false : patient.studyID?.toLowerCase()?.includes(inputValue.toLowerCase()) ?? false;
  const filterByPatientID = filterTypes.patientID === false ? false : patient.patientID?.toLowerCase()?.includes(inputValue.toLowerCase()) ?? false;

  return filterByName || filterByStudyID || filterByPatientID;
};

export const patientSelectStyles = {
  input: (currentStyles: CSSObjectWithLabel) => ({ ...currentStyles, height: 72, fontSize: 28, }),
  placeholder: (currentStyles: CSSObjectWithLabel) => ({ ...currentStyles, fontStyle: 'italic', fontSize: 28, position: 'absolute', left: 10, color: '#666', zIndex: 10 }),
  dropdownIndicator: (currentStyles: CSSObjectWithLabel) => ({ ...currentStyles, color: '#333' }),
  control: (currentStyles: CSSObjectWithLabel) => ({ ...currentStyles, borderColor: '1px solid #333', height: 80, }),
};

export const demoKioskData: Kiosk = {
  kioskID: '1-121-12321-121-1',
  organizationID: 'selected-organization-id',
  name: 'Demo-Kiosk-1',
  spirometer: {
    spirometerID: "SM-005-Z000000",
    address: "PLACEHOLDER",
    serialNumber: "Z000000",
    name: "Spirobank Smart",
    protocol: "6.0",
    firmwareVersion: "4.5",
    identifier: "4d6f6f21-a640-4a4f-888c-ab54437968cd",
    roundtripTime: "2022-08-01T00:00:00.0000000-05:00"
  },
  mobileDevice: {
    kioskPublicKey: "PLACEHOLDER",
    appVersion: "1.12.0",
    deviceModel: "android",
    // deviceUniqueIdentifier: "E541B530-3E19-566B-B4D9-41BC69996D70",
    // deviceUniqueIdentifier: "abc123",
    // deviceUniqueIdentifier: "def456",
    // deviceUniqueIdentifier: "1f7468b074d11e7b68ace738f50c877d", // CONOR TEST KIOSK
    // deviceUniqueIdentifier: "E541B530-3E19-566B-B4D9-41BC69996D70", // VIDEO DEMO
    // deviceUniqueIdentifier: '9bbac123e538faf6893b535b3aea70fb', // DRU TEST KIOSK PREVIEW
    deviceUniqueIdentifier: "64864a50f954a3ed4abca5cc801acaa3", // DRU TEST KIOSK TEST
    continent_code: "NA",
    country_code: "US",
    region_code: "en-US",
    time_zone_abbr: "EST",
    time_zone_name: "America/New York",
    time_zone_offset: "-0500",
    languageCode: "en-US",
    operatingSystem: "android",
    processorCount: "4",
    processorFrequency: "100hz",
    processorType: "arm64",
    systemMemorySize: "4kb",
    timezoneName: "EST",
    roundtripTime: "2022-08-01T00:00:00.0000000-05:00",
  }
};
