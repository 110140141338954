import * as React from 'react';
import { Container, Spinner } from 'react-bootstrap';

interface LoaderContainerProps {
  text?: string;
}

export const LoaderContainer: React.FC<LoaderContainerProps> = ({ text }) => {
  return (
    <Container className="mt-5 d-flex align-items-center justify-content-center text-center">
      <Spinner animation="grow" variant="primary" />{text && <h5 className="m-0 py-0 pr-0 pl-2">{text}</h5>}
    </Container>
  );
};