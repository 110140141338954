import { Reducer } from 'redux';
import defaultTranslationText from '../../translations/en.json';
import { TranslationType } from '../../Types/MiscTypes';
import { CLEAR_TRANSLATIONS, SET_TRANSLATIONS } from './actionTypes';

interface TranslationsReducerAction {
  type: string;
  payload: TranslationType;
};

export const translationsReducer: Reducer<TranslationType, TranslationsReducerAction> = (state = defaultTranslationText, action) => {
  switch (action.type) {
    case SET_TRANSLATIONS:
      return action.payload;
    case CLEAR_TRANSLATIONS:
      return defaultTranslationText;
    default:
      return state;
  }
};