import * as React from 'react';
import { shallowEqual, } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom'

import { useAppSelector } from '../../redux/store';
import { OrganizationType } from '../../Types/Common/Types';
import { ProviderStatus } from '../../libs/providerLib';
import { isEmptyObjectOrArray } from '../../libs/utilsLib';
import { getCurrentOrganization } from '../../libs/dataLib';

import './Navbars.css';

const { useMemo } = React;

interface AuthenticatedNavbarProps {
  handleLogout: (redirect?: string) => void;
}

export const AuthenticatedNavbar: React.FC<AuthenticatedNavbarProps> = ({ handleLogout }) => {
  const location = useLocation();
  const authenticatedProvider = useAppSelector((state) => state.provider, shallowEqual);
  const navbarText = useAppSelector(state => state.translations, shallowEqual);
  const { isKioskMode, kiosk, selectedOrganization, isInSession, mobileDevice } = useAppSelector(state => state.kiosk);
  const { detailedOrganizationsForProvider, organizationsForProviderIDs, } = useAppSelector(state => state.organizationsForProvider);
  const { providerPermissionsForOrganization } = useAppSelector(state => state.providers);
  const { detailedOrganizations } = useAppSelector(state => state.organizations);
  let organization: OrganizationType = useAppSelector(state => authenticatedProvider.fullAdmin ? state.organizations.detailedOrganizations[authenticatedProvider.viewingAs.organizationID] : state.organizationsForProvider.detailedOrganizationsForProvider[authenticatedProvider.viewingAs.organizationID]);
  organization = getCurrentOrganization(authenticatedProvider.viewingAs, authenticatedProvider.fullAdmin ? detailedOrganizations : detailedOrganizationsForProvider);
  const program = useAppSelector(state => Object.values(state.programs).find(program => organization?.program?.toLowerCase() === program.program), shallowEqual);
  const isKioskInPath = useMemo(() => location.pathname.includes('/kiosk'), [location]);
  const isTrainingMode = !!program?.featureSet?.enableTrainingMode && authenticatedProvider?.viewingAs?.organizationID?.includes('training');

  const isOrganizationActive = useMemo(() => {
    return organization && !isEmptyObjectOrArray(organization) && organization?.status !== ProviderStatus.inactive;
  }, [organization]);

  const isUnauthorizedForKiosk = useMemo(() => {
    return (Object.keys(detailedOrganizationsForProvider).length > 0 && !detailedOrganizationsForProvider[kiosk?.organizationID ?? '']) || organizationsForProviderIDs === 'noOrganizations' || authenticatedProvider.status.toUpperCase() === 'LOCKED' || organization?.status === ProviderStatus.inactive || providerPermissionsForOrganization[authenticatedProvider.providerID]?.status?.toUpperCase() === ProviderStatus.inactive;
  }, [detailedOrganizationsForProvider, kiosk, providerPermissionsForOrganization, authenticatedProvider, organization, organizationsForProviderIDs]);

  const showKioskTabs = useMemo(() => {
    return !isUnauthorizedForKiosk && isOrganizationActive && isKioskMode && !!kiosk && !!selectedOrganization && !isInSession && mobileDevice;
  }, [isUnauthorizedForKiosk, isOrganizationActive, isKioskMode, kiosk, selectedOrganization, isInSession, mobileDevice]);

  const showKioskManagement = useMemo(() => {
    return showKioskTabs && (!!authenticatedProvider.viewingAs.orgAdmin || !!authenticatedProvider.fullAdmin);
  }, [showKioskTabs, authenticatedProvider]);

  const showYourAccount = useMemo(() => {
    return showKioskTabs || (!isKioskMode && !isKioskInPath && !isEmptyObjectOrArray(authenticatedProvider));
  }, [showKioskTabs, isKioskMode, isKioskInPath, authenticatedProvider]);

  const handleClickLogout = () => handleLogout();

  return (
    <>
      {(!isKioskMode && !isKioskInPath) && (
        <>
          {!!authenticatedProvider?.fullAdmin && (
            <NavLink to='/admin' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"} >
              {navbarText.admin || 'Admin'}
            </NavLink>
          )}
          {isOrganizationActive && !isTrainingMode && (
            <>
              <NavLink to='/organization' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"}>
                {navbarText.organization || 'Organization'}
              </NavLink>
              {(!!authenticatedProvider?.fullAdmin || !!authenticatedProvider?.viewingAs?.orgAdmin) && (
                <NavLink id='providersMenu' to='/providers' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"} >
                  {navbarText.providers || 'Providers'}
                </NavLink>
              )}
            </>
          )}
          <NavLink end to='/' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"} >
            {program?.config_ShowTrialMode ? navbarText.participants || 'Participants' : navbarText.patients || 'Patients'}
          </NavLink>
          {isOrganizationActive && !isTrainingMode ?
            !program?.config_ShowTrialMode
              ? (
                <NavLink to='/reports' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"} >
                  {navbarText.reports || 'Reports'}
                </NavLink>
              )
              : (
                <NavLink to='/reports' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"} >
                  {'Reporting'}
                </NavLink>
              )
            : null}
        </>
      )}
      {showKioskTabs && (
        <NavLink end to='/kiosk' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"} >
          {navbarText.kiosk || 'Kiosk'} {navbarText.home || 'Home'}
        </NavLink>
      )}
      {showKioskManagement && (
        <NavLink end to='/kiosk/manage' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"}>
          {navbarText.manageKiosk || 'Manage Kiosk'}
        </NavLink>
      )}
      {showYourAccount && !isKioskMode && !isTrainingMode && (
        <NavLink end to='/account' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"}>
          {navbarText.account || 'Your Account'}
        </NavLink>
      )}
      {showYourAccount && isKioskMode && (
        <NavLink end to='/kiosk/account' className={({ isActive }) => isActive ? 'Navbar-NavLink-Style-Active' : "Navbar-NavLink-Style"}>
          {navbarText.account || 'Your Account'}
        </NavLink>
      )}
      <NavLink to="" className='Navbar-NavLink-Style' onPointerDown={handleClickLogout}>{navbarText.logout || 'Log out'}</NavLink>
    </>
  );
};