import { Dispatch } from 'redux';
import { batch } from 'react-redux';
import { SET_PROVIDERS_FOR_ORGANIZATION, SET_ADMINS_FOR_ORGANIZATION, REMOVE_PROVIDERS, SET_PROVIDER_PERMISSIONS_FOR_ORGANIZATION } from './actionTypes';
import { getProvider } from '../../libs/providerLib';
import { getProvidersForOrganization, getProviderFromOrganization } from "../../libs/organizationProviderLib";
import { Provider, OrganizationProvider } from '../../Types/Common/Types';

export const setProvidersForOrganization = (payload: Record<string, Provider>) => ({ type: SET_PROVIDERS_FOR_ORGANIZATION, payload });

export const setAdminsForOrganization = (payload: Record<string, Provider>) => ({ type: SET_ADMINS_FOR_ORGANIZATION, payload });

export const setProviderPermissionsForOrganization = (payload: Record<string, OrganizationProvider>) => ({ type: SET_PROVIDER_PERMISSIONS_FOR_ORGANIZATION, payload });

export const removeProviders = () => ({ type: REMOVE_PROVIDERS });

/**
 * @param {string} organizationID
 */
export const fetchProvidersForOrganization = (organizationID: string) => {
  return async (dispatch: Dispatch) => {
    const { providerIDs } = await getProvidersForOrganization(organizationID);
    const permissionsList: Record<string, OrganizationProvider> = {};

    const detailedProviders = (await Promise.all(providerIDs.map(async (id: string) => {
      try {
        const [provider, providerFromOrganization] = await Promise.all([getProvider(id), getProviderFromOrganization(organizationID, id)]);
        permissionsList[providerFromOrganization.providerID] = providerFromOrganization;
        return provider;
      } catch (e) {
        console.log("error fetching individual provider", e);
      };
    }))).filter(p => !!p);

    const objectProviderList = detailedProviders.reduce((acc, cur) => ({ ...acc, [cur.providerID]: cur }), {});
    const objectAdminList = detailedProviders
      .reduce((acc, cur) => !!cur.fullAdmin || !!permissionsList[cur.providerID].orgAdmin ? { ...acc, [cur.providerID]: cur } : acc, {});

    batch(() => {
      dispatch(setProvidersForOrganization(objectProviderList));
      dispatch(setAdminsForOrganization(objectAdminList));
      dispatch(setProviderPermissionsForOrganization(permissionsList));
    });
  };
};
