import { Reducer } from "redux";
import { OrganizationType } from "../../Types/Common/Types";
import { OrgSelectOption } from "../../Types/MiscTypes";
import { SET_ORGANIZATIONS_FOR_PROVIDER, CLEAR_ORGANIZATIONS_FOR_PROVIDER, SET_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN, SET_DETAILED_ORGANIZATIONS_FOR_PROVIDER, SET_ORGANIZATIONS_FOR_PROVIDER_IDS, SET_SELECTED_ORGANIZATION_FOR_DROPDOWN, UPDATE_DETAILED_ORGANIZATIONS_FOR_PROVIDER, UPDATE_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN } from "./actionTypes";

const INITIAL_STATE = {
  organizationsForProviderIDs: [] as string[] | 'noOrganizations',
  detailedOrganizationsForProvider: {} as Record<string, OrganizationType>,
  organizationsForProviderDropdown: [] as OrgSelectOption[],
  selectedOrg: {} as OrgSelectOption
};

type Action = {
  type: string;
  id?: string;
  payload: string[] | Record<string, OrganizationType> | OrgSelectOption[] | OrgSelectOption | 'noOrganizations';
}

const returnInitialState = () => INITIAL_STATE;
type StateType = ReturnType<typeof returnInitialState>;

export const organizationProviderReducer: Reducer<StateType, Action> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS_FOR_PROVIDER:
      if (typeof action.payload === 'string') return { ...state, organizationsForProviderIDs: action.payload };
      return { ...state, organizationsForProviderIDs: action.payload };
    case SET_ORGANIZATIONS_FOR_PROVIDER_IDS:
      return { ...state, organizationsForProviderIDs: action.payload };
    case SET_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN:
      return { ...state, organizationsForProviderDropdown: action.payload };
    case SET_DETAILED_ORGANIZATIONS_FOR_PROVIDER:
      return { ...state, detailedOrganizationsForProvider: action.payload };
    case UPDATE_DETAILED_ORGANIZATIONS_FOR_PROVIDER:
      if (!action.id || typeof action.payload !== 'object') return state;
      return { ...state, detailedOrganizationsForProvider: { ...state.detailedOrganizationsForProvider, [action.id]: { ...action.payload } } };
    case UPDATE_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN:
      if (!action.id) return state;
      const organizationsForProviderDropdown = state.organizationsForProviderDropdown.map(org => org.id === action.id ? action.payload : org);
      return { ...state, organizationsForProviderDropdown };
    case SET_SELECTED_ORGANIZATION_FOR_DROPDOWN:
      return { ...state, selectedOrg: action.payload };
    case CLEAR_ORGANIZATIONS_FOR_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
};