import { API } from "aws-amplify";
import { getProvidersForOrganization } from "./organizationProviderLib"

const PROVIDER_API = "provider";
const LOGGER_API = "logger";

export const ProviderStatus = /** @type {const} */ ({
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  invited: 'INVITED',
  equals: (inputStatus, statusToCompare) =>
    inputStatus && statusToCompare
    && typeof (inputStatus) === 'string' && typeof (statusToCompare) === 'string'
    && inputStatus.toLowerCase() === statusToCompare.toLowerCase()
});

export const renderProviderLastLogin = (provider) => {
  const providerTimestamp = provider?.lastLogin;
  if (!providerTimestamp) return 'Unknown';
  return new Date(providerTimestamp).toDateString();
};

export const isProviderSiteAdmin = provider =>
  provider &&
  (provider.fullAdmin === true
    || provider.fullAdmin === "true"
    || provider.fullAdmin === 1
    || provider.fullAdmin === "1");

export const isProviderOrgAdmin = provider => {
  return !!(provider &&
    (provider.orgAdmin === true
      || provider.orgAdmin === "true"
      || provider.orgAdmin === 1
      || provider.orgAdmin === "1"
      || !!provider?.viewingAs?.orgAdmin));
};

export const shouldShowOrganizations = (provider, organizations) =>
  (isProviderOrgAdmin(provider) && organizations?.length > 1)
  || isProviderSiteAdmin(provider);

export const shouldShowProviders = (provider, organizations) =>
  isProviderSiteAdmin(provider) || isProviderOrgAdmin(provider) || organizations?.length > 1;

export const isEmailInWhitelist = (whitelist, email) => {
  try {
    const whitelistResults = [];
    if (!Array.isArray(whitelist)) whitelist = [];
    whitelist.forEach(allowedEmail => {
      let emailParts = allowedEmail.split('@');
      if (emailParts[0] === '*') emailParts[1]?.trim() === email.split('@')[1]?.trim() ? whitelistResults.push(true) : whitelistResults.push(false);
      else allowedEmail?.toLowerCase()?.trim() === email?.toLowerCase()?.trim() ? whitelistResults.push(true) : whitelistResults.push(false);
    })
    return whitelistResults.some(result => result);
  }
  catch (e) {
    console.log(e);
    return false;
  }
};

/** @returns {Promise<{ active: boolean }>} */
export function checkActiveLogin(providerID) {
  return API.get(PROVIDER_API, `/active-login/${providerID}`);
}

export function pingProviderAPI() {
  return API.get(PROVIDER_API, "/ping");
}

export function checkProviderAPI() {
  return API.get(PROVIDER_API, "/check");
}

export function createProvider(provider, organizationID) {
  return API.post(PROVIDER_API, "/create", {
    body: provider
  });
}

export function adminSignup(admin) {
  return API.post(PROVIDER_API, "/adminSignup", {
    body: admin
  });
}

export function getProvider(providerID) {
  return API.get(PROVIDER_API, `/${providerID}`);
}

export function getPatientsForProvider(providerID) {
  return API.get(PROVIDER_API, `/patients/${providerID}`);
}

export function getAllProviders() {
  return API.get(PROVIDER_API, `/list`);
}

export function removeProvider(providerID) {
  return API.get(PROVIDER_API, `/remove/${providerID}`);
}

/**
 * @param {string} providerID
 * @returns {Promise<{ mfa : string;}>} The active MFA code for a provider trying to log in
 */
export function getActiveMfaCodeForProvider(providerID) {
  return API.get(PROVIDER_API, `/mfa?providerID=${providerID}`);
};

export async function updateProvider(updatedProvider) {
  const response = await API.put(PROVIDER_API, `/${updatedProvider.providerID}`, {
    body: updatedProvider
  });
  return response;
}

/********************* REMOVED PER ZEP-2084 (pentest) Dasboard v1.5.1 release
 *
export async function changeProviderEmail(providerID, newEmail) {
  const response = await API.put(PROVIDER_API, `/update-email/${providerID}`, {
    body: { email: newEmail }
  });
  return response;
}

export async function adminChangeProviderEmail(providerID, newEmail) {
  const response = await API.put(PROVIDER_API, `/admin-update-email/${providerID}`, {
    body: { email: newEmail }
  });
  return response;
}
 *
***************************/

export function getProviderFromCognitoID(cognitoID) {
  return API.get(PROVIDER_API, `/cognito/${cognitoID}`);
}

export function getProviderFromEmail(email) {
  return API.get(PROVIDER_API, `/email/${email}`);
}

export function reinviteProviderFromEmail(email) {
  let providerEmail = typeof (email) === 'object' ? email.email : email;
  return API.get(PROVIDER_API, `/reinvite/${providerEmail}`);
}

export function resetProvider(email) {
  return API.get(PROVIDER_API, `/reset/${email}`);
}

export function forgotPassword(email) {
  return API.get(PROVIDER_API, `/forgotPassword/${email}`);
}

// needs email, code, and password
export function confirmForgotPassword(codePassword) {
  return API.post(PROVIDER_API, "/confirmForgotPassword", {
    body: codePassword
  });
}

/*
actions = [
  {'create-pdf-report': patientID},
  {'create-pdf-screen': patientID},
  {'access-organization-providers': organizationID},
  {'access-edit-organization': organizationID},
  {'access-patient': patientID}
]
*/

export async function logProviderAction(providerID, action) {
  let response = await API.post(LOGGER_API, `/add`, {
    body: { endpoint: "provider-access-action", providerID, action, logType: "dashboard" }
  });
  return response
}

export async function logProviderSigninError(email, errorMessage) {
  let response = await API.post(LOGGER_API, `/add`, {
    body: { endpoint: "provider-signin-error", providerEmail: email, error: errorMessage, logType: "dashboard" }
  });
  return response
}

export async function logProviderSignin(providerID, userAgent) {
  let response = await API.post(LOGGER_API, `/add`, {
    body: { endpoint: `login-dashboard-${providerID}`, providerID: providerID, userAgent, timestamp: Date.now(), logType: "dashboard" }
  });
  return response
}

export async function logProviderChangeEmailError(oldEmail, newEmail) {
  let response = await API.post(LOGGER_API, `/add`, {
    body: { endpoint: "provider-change-email-error", providerOldEmail: oldEmail, providerNewEmail: newEmail, logType: "dashboard" }
  });
  return response
}

export async function getNotifications(providerID) {
  return API.get(PROVIDER_API, `/notification/${providerID}`);
}

/**
Example body for /password
{
  "providerID": "861b39b0-89a4-404c-834b-c5510acfba1b",
  "options": true,
  "get-settings": true,
  "check-active-login": true,
  "check-expired": true,
  "check-locked": true,
  "unlock": true,
  "check-unused": "Jamboxx2020",
  "register": "Jamboxx2020"
}
 */

export async function getPasswordConfig(passwordConfig) {
  let response = await API.post(PROVIDER_API, `/password`, {
    body: passwordConfig
  });
  return response;
}

export async function postNotification(providerID, notification) {
  if (typeof (notification) !== 'object') notification = { message: notification, seen: false, category: "message" }
  let response = await API.post(PROVIDER_API, `/notification/${providerID}`, {
    body: notification
  });
  return response;
}

export async function createIncompleteSurveyNotification(providerID) {
  let response = await API.post(PROVIDER_API, `/notification/${providerID}`, {
    body: { message: `You have a remote PFT session with incomplete surveys, performed on ${(new Date()).toString()}`, seen: false, category: "incompleteSurvey" }
  });
  return response;
}

export async function deleteNotification(providerID, created) {
  return await API.del(PROVIDER_API, `/notification/${providerID}/${created}`);
}

export async function postNotificationToOrganization(organizationID, notification) {
  let providerIDList = await getProvidersForOrganization(organizationID);
  await Promise.all(providerIDList.providerIDs.map(
    async providerID => {
      try {
        let response = await postNotification(providerID, notification);
        return response;
      } catch (e) {
        console.log(providerID, e);
        return null;
      }
    }
  ));
}

export const getProvidersFromIDs = async providerIDs =>
  (await
    Promise.all(providerIDs.map(
      async providerID => {
        try {
          const provider = await getProvider(providerID);
          return provider;
        } catch (e) {
          console.log(providerID, e);
          return null;
        }
      }
    ))
  ).filter(provider => !!provider);

export const postSamlAuth = async (email, providerID, organizationID) => {
  return API.post(PROVIDER_API, '/post-auth-saml', {
    body: { email, providerID, organizationID }
  });
};