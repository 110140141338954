import React, { lazy } from 'react';

export const lazyWithRetry = (componentImport: () => Promise<{
  default: React.FC<any>;
}>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(
        'page-has-been-force-refreshed'
      ) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        'page-has-been-force-refreshed',
        'false'
      );

      return component;
    } catch (error) {
      console.log(error);
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Let's refresh the page immediately and try again.
        window.localStorage.setItem(
          'page-has-been-force-refreshed',
          'true'
        );
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      // if (error instanceof Error) throw error;
      console.log('ENCOUNTERED CHUNK ERROR DESPITE RELOAD');
      alert('Sorry, there was a problem loading the application. Please try again');
      throw error;
    }
  });

  // Credit: https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb