import { REMOVE_PROVIDER, SET_PROVIDER } from './actionTypes';
import { getProvider, updateProvider } from '../../libs/providerLib';
import { removeProviders } from '../providers/actionCreators';
import { clearPatients } from '../patients/actionCreators'

export const setProvider = (payload) => ({ type: SET_PROVIDER, payload });

export const removeProvider = () => ({ type: REMOVE_PROVIDER });

/**
 * @param {string} providerID 
 */
export const fetchProvider = (providerID) => {
  return async (dispatch) => {
    try {
      const provider = await getProvider(providerID);
      dispatch(setProvider(provider));
    } catch (e) {
      console.log(e);
    };
  };
};

/**
 * @param {object} provider - full and fully modified provider object to be updated in db and store
 */
export const updateAndSetProvider = (provider) => {
  return async (dispatch) => {
    try {
      const updatedProvider = await updateProvider({ ...provider });
      dispatch(setProvider(updatedProvider));
    } catch (e) {
      console.log(e);
    };
  };
};

/**
 * @param {string} providerID 
 * @param {object} viewingAs - future viewingAs for this provider
 */
export const updateViewingAs = (providerID, viewingAs) => {
  return async (dispatch) => {
    try {
      const updatedProvider = await updateProvider({ providerID, viewingAs });
      dispatch(setProvider(updatedProvider));
      dispatch(removeProviders());
      dispatch(clearPatients());
    } catch (e) {
      console.log(e);
    };
  };
};