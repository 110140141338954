import Amplify from 'aws-amplify';
import { ENVIRONMENT_URL } from './libs/dataLib';

export const connectToApis = async () => {
  let saved_config = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_STAGE.toUpperCase()}-apiConfig`));
  if (saved_config) {
    remapApi(saved_config)
    return 'connected'
  } else {
    try {
      const response = await fetch(`${ENVIRONMENT_URL}${process.env.REACT_APP_STAGE.toLowerCase()}`).catch(e => console.log(e, 'failed to connect'));
      const environmentData = await response.json();
      let config = remapConfig(environmentData);
      localStorage.setItem(`${process.env.REACT_APP_STAGE.toUpperCase()}-apiConfig`, JSON.stringify(config));

      if (environmentData?.serviceAlert || environmentData?.serviceAlert === '') handleServiceAlert(environmentData.serviceAlert);
      remapApi(config)
      return 'connected';
    } catch (e) {
      console.log(e);
      console.log("Failed to connect");
    };
  }
};

export const handleServiceAlert = (serviceAlert) => {
  const existingServiceAlert = localStorage.getItem('serviceAlert');
  if (serviceAlert) localStorage.setItem('serviceAlert', serviceAlert);
  if (!serviceAlert && existingServiceAlert) localStorage.removeItem('serviceAlert');
};

export const remapConfig = (newConfig) => {
  let config = {
    s3: {
      REGION: newConfig.region,
      BUCKET: newConfig.s3.bucket,
      PDFS_BUCKET: newConfig.s3.pdfsBucket
    },
    surveyS3: {
      REGION: newConfig.region,
      BUCKET: "zephyrx-custom-survey",
    },
    organization: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.organization}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    provider: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.provider}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    organizationProvider: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis['organization-provider']}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    patient: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.patient}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    organizationConsent: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis['organization-consent']}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    coachingSession: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis['coaching-session']}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    patientDemographic: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis['patient-demographic']}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    pftSeries: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis['pft-series']}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    pftEffort: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis['pft-effort']}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    spirometer: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.spirometer}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    incentiveSpirometry: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis['incentive-spirometry']}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    logger: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.logger}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    zephyrx: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.zephyrx}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    pdfGenerator: {
      REGION: newConfig.pdfGenerator.REGION,
      URL: newConfig.pdfGenerator.URL
    },
    chartGenerator: {
      REGION: newConfig.chartGenerator.REGION,
      URL: newConfig.chartGenerator.URL
    },
    reporting: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.reporting}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    passwordCheck: {
      DOMAIN: newConfig.passwordCheck.domain,
      APP_CLIENT_ID: newConfig.passwordCheck.appClientID
    },
    cognito: {
      REGION: newConfig.cognitoRegion,
      USER_POOL_ID: newConfig.userPoolID || "us-east-1_2gV7inkZS",
      APP_CLIENT_ID: newConfig.appClientID || "4sof407fqtogh6m67cfjbc9c1p",
      IDENTITY_POOL_ID: newConfig.identityPoolID || "us-east-1:d16aee9a-46da-428c-8321-f80e9011a874",
    },
    kiosk: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.v2}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    emr: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.v2}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    admin: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.v2}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    },
    v2Patient: {
      REGION: newConfig.region,
      URL: `https://${newConfig.apis.v2}.execute-api.${newConfig.region}.amazonaws.com/${process.env.REACT_APP_STAGE.toLowerCase()}`
    }
  };
  return config;
}

export const remapApi = (config) => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      authenticationFlowType: 'CUSTOM_AUTH',  // for migration -> 'USER_PASSWORD_AUTH',
      oauth: {
        domain: process.env.REACT_APP_STAGE.toLowerCase() === 'prod'
          ? `zephyrx.auth.${config.cognito.REGION}.amazoncognito.com`
          : process.env.REACT_APP_STAGE.toLowerCase() === 'preview'
            ? `zephyrx-preview.auth.${config.cognito.REGION}.amazoncognito.com`
            : `zephyrx-test.auth.${config.cognito.REGION}.amazoncognito.com`,
        redirectSignIn: process.env.REACT_APP_USE_LOCAL_REDIRECT === 'true'
          ? 'http://localhost:3000'
          : process.env.REACT_APP_STAGE.toLowerCase() === 'prod'
            ? `https://dashboard.zephyrx.com`
            : `https://${process.env.REACT_APP_STAGE.toLowerCase()}.dashboard.zephyrx.com`,
        redirectSignOut: process.env.REACT_APP_USE_LOCAL_REDIRECT === 'true'
          ? 'http://localhost:3000'
          : process.env.REACT_APP_STAGE.toLowerCase() === 'prod'
            ? `https://dashboard.zephyrx.com`
            : `https://${process.env.REACT_APP_STAGE.toLowerCase()}.dashboard.zephyrx.com`,
        responseType: 'code'
      },
    },
    Storage: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
      identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
      endpoints: [
        {
          name: "patient",
          endpoint: config.patient.URL,
          region: config.patient.REGION
        },
        {
          name: "patient-demographic",
          endpoint: config.patientDemographic.URL,
          region: config.patientDemographic.REGION
        },
        {
          name: "organization",
          endpoint: config.organization.URL,
          region: config.organization.REGION
        },
        {
          name: "organization-consent",
          endpoint: config.organizationConsent.URL,
          region: config.organizationConsent.REGION
        },
        {
          name: "organization-provider",
          endpoint: config.organizationProvider.URL,
          region: config.organizationProvider.REGION
        },
        {
          name: "provider",
          endpoint: config.provider.URL,
          region: config.provider.REGION
        },
        {
          name: "coaching-session",
          endpoint: config.coachingSession.URL,
          region: config.coachingSession.REGION
        },
        {
          name: "spirometer",
          endpoint: config.spirometer.URL,
          region: config.spirometer.REGION
        },
        {
          name: "pft-effort",
          endpoint: config.pftEffort.URL,
          region: config.pftEffort.REGION
        },
        {
          name: "pft-series",
          endpoint: config.pftSeries.URL,
          region: config.pftSeries.REGION
        },
        {
          name: "incentive-spirometry",
          endpoint: config.incentiveSpirometry.URL,
          region: config.incentiveSpirometry.REGION
        },
        {
          name: "logger",
          endpoint: config.logger.URL,
          region: config.logger.REGION
        },
        {
          name: "zephyrx",
          endpoint: config.zephyrx.URL,
          region: config.zephyrx.REGION
        },
        {
          name: "pdfGenerator",
          endpoint: config.pdfGenerator.URL,
          region: config.pdfGenerator.REGION
        },
        {
          name: "chartGenerator",
          endpoint: config.chartGenerator.URL,
          region: config.chartGenerator.REGION
        },
        {
          name: "reporting",
          endpoint: config.reporting.URL,
          region: config.reporting.REGION
        },
        {
          name: 'kiosk',
          endpoint: config.kiosk.URL,
          region: config.kiosk.REGION
        },
        {
          name: 'emr',
          endpoint: config.emr.URL,
          region: config.emr.REGION
        },
        {
          name: 'admin',
          endpoint: config.admin.URL,
          region: config.admin.REGION
        },
        {
          name: 'v2Patient',
          endpoint: config.v2Patient.URL,
          region: config.v2Patient.REGION
        },
      ]
    }
  });
}