import { SET_ENTITY, SET_ENTITIES, REMOVE_ENTITY, CLEAR_BILLING, SET_OPTIONS } from './actionTypes';
import { getBillingEntities, createBillingEntity, updateBillingEntity, deleteBillingEntity } from '../../libs/organizationLib';
import { generateBillingEntityMap } from '../../libs/utilsLib';
import { getBillingOptions } from '../../libs/zephyrxLib';

export const setBillingEntities = (payload) => ({ type: SET_ENTITIES, payload });

export const setBillingOptions = (payload) => ({ type: SET_OPTIONS, payload });

export const setBillingEntity = (id, payload) => ({ type: SET_ENTITY, id, payload });

const removedBillingEntity = (payload) => ({ type: REMOVE_ENTITY, payload });

export const clearBilling = () => ({ type: CLEAR_BILLING });

export const fetchBillingEntities = () => {
  return async (dispatch) => {
    try {
      const { items } = await getBillingEntities();
      items.sort((a, b) => a.displayTag <= b.displayTag ? -1 : 1);
      const entityMap = generateBillingEntityMap(items);
      dispatch(setBillingEntities(entityMap));
    } catch (e) {
      console.log(e);
      dispatch(clearBilling());
    };
  };
};

export const fetchBillingOptions = () => {
  return async (dispatch) => {
    try {
      const response = await getBillingOptions();
      dispatch(setBillingOptions(response));
    } catch (e) {
      console.log(e);
      dispatch(clearBilling());
    };
  };
};

export const fetchBilling = () => {
  return async (dispatch) => {
    try {
      const [options, entities] = await Promise.all([getBillingOptions(), getBillingEntities()]);

      options.accountOwners.sort((a, b) => a <= b ? -1 : 1);
      options.regions.sort((a, b) => a <= b ? -1 : 1);
      const items = entities.items.sort((a, b) => a.displayTag <= b.displayTag ? -1 : 1);
      const entityMap = generateBillingEntityMap(items);

      dispatch(setBillingEntities(entityMap));
      dispatch(setBillingOptions(options));
    } catch (e) {
      console.log(e);
      dispatch(clearBilling());
    };
  };
};

/**
 * @param {{displayTag: string, externalBillingID: string, accountOwner: string, region: string, notes?: string}} body 
 */
export const addBillingEntity = (body) => {
  return async (dispatch) => {
    try {
      const response = await createBillingEntity(body);
      dispatch(setBillingEntity(response.billingID, response));
    } catch (e) {
      console.log(e);
      dispatch(clearBilling());
    };
  };
};

/**
 * @param {{billingID: string, displayTag?: string, externalBillingID?: string, accountOwner?: string, region?: string, notes? : string}} body 
 */
export const editBillingEntity = (body) => {
  if (!body.billingID) throw new Error('billing ID required in editBillingEntity action creator body');
  return async (dispatch) => {
    try {
      const response = await updateBillingEntity(body);
      dispatch(setBillingEntity(body.billingID, response));
    } catch (e) {
      console.log(e);
      dispatch(clearBilling());
    };
  };
};

export const removeBillingEntity = (billingID) => {
  return async (dispatch) => {
    try {
      const response = await deleteBillingEntity(billingID);
      if (!response.deleted) throw new Error('Error deleting billing entity');
      dispatch(removedBillingEntity(billingID));
    } catch (e) {
      console.log(e);
      dispatch(clearBilling());
    };
  };
};