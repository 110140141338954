export { }

if (!Array.prototype.at) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.at = function (index) {
    let arrayIndex = Math.trunc(index) || 0;

    if (arrayIndex < 0) arrayIndex += this.length;
    if (arrayIndex < 0 || arrayIndex > this.length - 1) return undefined;
    return this[arrayIndex];
  }
}