import { combineReducers } from 'redux';
import { programReducer } from './programs/programReducer';
import { languageReducer } from './language/languageReducer';
import { organizationsReducer } from './organization/organizationsReducer';
import { organizationProviderReducer } from './organizationProvider/organizationProviderReducer';
import { providerReducer } from './provider/providerReducer';
import { providersReducer } from './providers/providersReducer';
import { patientsReducer } from './patients/patientsReducer';
import { billingReducer } from './billing/billingReducer';
import { ssoReducer } from './sso/ssoReducer';
import { translationsReducer } from './translations/translationsReducer';
import { patientReducer } from './patient/patientReducer';
import { seriesReducer } from './series/seriesReducer';
import { kioskReducer } from './kiosk/kioskReducer';

export const rootReducer = combineReducers({
  programs: programReducer,
  language: languageReducer,
  translations: translationsReducer,
  organizations: organizationsReducer,
  organizationsForProvider: organizationProviderReducer,
  provider: providerReducer,
  providers: providersReducer,
  patients: patientsReducer,
  patient: patientReducer,
  billing: billingReducer,
  series: seriesReducer,
  ssoPools: ssoReducer,
  kiosk: kioskReducer
});

export type RootState = ReturnType<typeof rootReducer>;