import { Reducer } from 'redux';
import { PftSeries, PftType, } from '../../Types/Common/PftTypes';
import { FvcChartReport, PatientDemographics, PefChartReport, SeriesBaseline, SvcChartReport, } from '../../Types/Common/Types';
import { SET_ALL_SERIES, SET_PFT_TYPE, SET_SERIES, CLEAR_SERIES, SET_DETAILED_SERIES, SET_SERIES_DEMOGRAPHICS, SET_CHART_REPORT, CLEAR_DETAILED_SERIES, SET_DETAILED_CHARTS, SET_SERIES_BASELINES } from './actionTypes';

const DEFAULT_STATE = {
  series: {} as PftSeries,
  detailedSeries: [{}] as PftSeries[],
  chartReport: {} as FvcChartReport | SvcChartReport | PefChartReport,
  detailedCharts: {} as any,
  seriesDemographics: {} as PatientDemographics,
  seriesBaselines: null as SeriesBaseline | null,
  allSeries: {} as Record<string, PftSeries>,
  pftType: 'fvc' as PftType
};

interface SeriesReducerAction {
  type: typeof SET_ALL_SERIES | typeof SET_SERIES | typeof SET_DETAILED_SERIES | typeof SET_PFT_TYPE | typeof SET_SERIES_DEMOGRAPHICS | typeof SET_CHART_REPORT | typeof CLEAR_DETAILED_SERIES | typeof SET_DETAILED_CHARTS | typeof CLEAR_SERIES | typeof SET_SERIES_BASELINES;
  payload: PftType | PftSeries | Record<string, PftSeries> | (FvcChartReport | SvcChartReport | PefChartReport) | PatientDemographics;
  seriesID: string;
};

const returnDefaultState = () => DEFAULT_STATE;
type StateType = ReturnType<typeof returnDefaultState>;

export const seriesReducer: Reducer<StateType, SeriesReducerAction> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_SERIES:
      return { ...state, series: action.payload };
    case SET_DETAILED_SERIES:
      return { ...state, detailedSeries: action.payload };
    case SET_SERIES_DEMOGRAPHICS:
      return { ...state, seriesDemographics: action.payload };
    case SET_ALL_SERIES:
      return { ...state, allSeries: action.payload };
    case SET_CHART_REPORT:
      return { ...state, chartReport: action.payload };
    case SET_DETAILED_CHARTS:
      return { ...state, detailedCharts: action.payload };
    case SET_PFT_TYPE:
      return { ...state, pftType: action.payload };
    case SET_SERIES_BASELINES:
      return { ...state, seriesBaselines: action.payload };
    case CLEAR_DETAILED_SERIES:
      return { ...state, detailedSeries: [{}] };
    case CLEAR_SERIES:
      return DEFAULT_STATE;
    default:
      return state;
  }
};