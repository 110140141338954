export const SET_PATIENTS_FOR_KIOSK = 'SET_PATIENTS_FOR_KIOSK';

export const ADD_PATIENT_TO_KIOSK = 'ADD_PATIENT_TO_KIOSK';

export const EDIT_PATIENT_FOR_KIOSK = 'EDIT_PATIENT_FOR_KIOSK';

export const SET_SESSIONS = 'SET_SESSIONS';

export const SET_KIOSK = 'SET_KIOSK';

export const SET_CONFIG = 'SET_CONFIG';

export const CLEAR_KIOSK_DATA = 'CLEAR_KIOSK_DATA';

export const CLEAR_KIOSK_STORE = 'CLEAR_KIOSK_STORE';

export const SET_KIOSK_MODE = 'SET_KIOSK_MODE';

export const SET_IN_SESSION = 'SET_IN_SESSION';

export const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION';

export const SET_SELECTED_PATIENT = 'SET_SELECTED_PATIENT';

export const CLEAR_SELECTED_PATIENT = 'CLEAR_SELECTED_PATIENT';

export const SET_MOBILE_DEVICE = 'SET_MOBILE_DEVICE';

export const SET_SPIROMETER_CONNECTED = 'SET_SPIROMETER_CONNECTED';

export const SET_RESET_BLUETOOTH_COMPLETED = 'SET_RESET_BLUETOOTH_COMPLETED';