import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import Select from "react-select";
import TruncateMiddle from "../TruncateMiddle";


export const FilterDropdown = ({ organizationList, onOrganizationChange, selected }) => {

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      textOverflow: "hidden",
      maxWidth: "120%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial"
    })
  };

  const CustomSingleValue = ({ children, props }) => <div className="custom-singleValue" {...props}><TruncateMiddle value={children} /></div>;

  return (
    <Row className="align-items-center">
      <Col>
        <Form inline={true} className="mb-3">
          <Select
            className="form-select"
            isSearchable={false}
            value={selected}
            options={organizationList}
            onChange={onOrganizationChange}
            components={{ SingleValue: CustomSingleValue }}
            styles={customStyles}
          />
        </Form>
      </Col>
    </Row>
  )
};