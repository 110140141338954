import { API } from "aws-amplify";

const SPIROMETER_API = "spirometer";

export function checkSpirometerAPI() {
  return API.get(SPIROMETER_API, "/check");
}

export function linkSpirometer(patientID, spirometerID) {
  return API.post(SPIROMETER_API, "/link", {
    body: JSON.stringify({ patientID, spirometerID })
  });
}

export function removeSpirometer(patientID, spirometerID) {
  return API.post(SPIROMETER_API, "/remove", {
    body: JSON.stringify({ patientID, spirometerID })
  });
}

export function getPatientFromSpirometerID(spirometerID) {
  return API.get(SPIROMETER_API, `/patient/${spirometerID}`);
}

/** @returns {Promise<{ spirometerIDs: import("../Types/Common/Types").SpirometerID[] }>} */
export function getSpirometerFromPatientID(patientID) {
  return API.get(SPIROMETER_API, `/spirometers/${patientID}`);
}

export function removeSpirometersFromPatient(patientID) {
  return API.get(SPIROMETER_API, `/remove/${patientID}`);
}