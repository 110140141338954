import * as React from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import { shallowEqual, } from 'react-redux';
import { Link } from 'react-router-dom'
import { IoIosArrowDropleft, IoIosArrowDropdown } from 'react-icons/io';

import { AuthenticatedNavbar } from './AuthenticatedNavbar';
import { UnauthenticatedNavbar } from './UnauthenticatedNavbar';
import { SuperAdminNav } from './SuperAdminNav';

import { OrganizationType } from '../../Types/Common/Types';
import { isEmptyObjectOrArray } from '../../libs/utilsLib';
import { version } from '../../../package.json'
import { useAppSelector } from '../../redux/store';
import { getCurrentOrganization } from '../../libs/dataLib';

const { useState } = React;

interface ZephyrxNavbarProps {
  handleLogout: (redirect?: string) => void;
}

export const ZephyrxNavbar: React.FC<ZephyrxNavbarProps> = ({ handleLogout }) => {
  const authenticatedProvider = useAppSelector(state => state.provider, shallowEqual);
  const { detailedOrganizationsForProvider } = useAppSelector(state => state.organizationsForProvider);
  const { detailedOrganizations } = useAppSelector(state => state.organizations);
  let organization: OrganizationType = useAppSelector(state => authenticatedProvider?.fullAdmin ? state?.organizations?.detailedOrganizations[authenticatedProvider?.viewingAs?.organizationID] : state?.organizationsForProvider?.detailedOrganizationsForProvider[authenticatedProvider?.viewingAs?.organizationID]);
  if (authenticatedProvider.viewingAs) {
    organization = getCurrentOrganization(authenticatedProvider.viewingAs, authenticatedProvider.fullAdmin ? detailedOrganizations : detailedOrganizationsForProvider);
  };
  const { isKioskMode, selectedOrganization } = useAppSelector(state => state.kiosk);
  const program = useAppSelector(state => state.programs[isKioskMode ? selectedOrganization?.program?.toUpperCase() : organization?.program?.toUpperCase()]);
  const { selectedOrg } = useAppSelector(state => state.organizationsForProvider, shallowEqual);
  const translationText = useAppSelector(state => state.translations, shallowEqual);
  const [showingSuperAdminNav, setShowingSuperAdminNav] = useState(false);
  const isTrainingMode = isKioskMode
    ? !!program?.featureSet?.enableTrainingMode && selectedOrganization?.organizationID?.includes('training')
    : !!program?.featureSet?.enableTrainingMode && authenticatedProvider?.viewingAs?.organizationID?.includes('training');
  const toggleSuperAdminNav = () => setShowingSuperAdminNav(showing => !showing);

  return (
    <>
      <Navbar bg="light" expand="lg" className={isTrainingMode ? "d-flex flex-column mb-0" : "d-flex flex-column"}>
        <Container fluid className="px-5">
          <Navbar.Brand>
            {isKioskMode
              ? (
                <Link to="/kiosk">
                  <Image src="/zephyrx-kiosk_logo_blue.png" width={200} className="logo" data-testid="Navbar-Kiosk-Logo" />
                </Link>
              )
              : (
                <Link to="/">
                  <Image src="/zephyrx-provider-dashboard_logo_blue.png" width={275} className="logo" />
                </Link>
              )}
          </Navbar.Brand>
          {!!authenticatedProvider && !!authenticatedProvider.viewingAs?.readOnly && !isKioskMode && <Nav.Item className='font-weight-600 pr-2'>{translationText.readOnly || 'Read Only'}</Nav.Item>}
          {!!authenticatedProvider && !!authenticatedProvider?.fullAdmin && <Nav.Item><small>{version || '1.3'}</small></Nav.Item>}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {authenticatedProvider?.providerID
                ? <AuthenticatedNavbar handleLogout={handleLogout} />
                : <UnauthenticatedNavbar />}
            </Nav>
          </Navbar.Collapse>
          {!!authenticatedProvider.fullAdmin && <>
            {showingSuperAdminNav
              ? <IoIosArrowDropdown size='24' onClick={toggleSuperAdminNav} className="pl-1" data-testid="arrow-open" role="button" />
              : <IoIosArrowDropleft size='24' onClick={toggleSuperAdminNav} className="pl-1" data-testid="arrow-closed" role="button" />}
          </>}
        </Container>

        {!isEmptyObjectOrArray(selectedOrg) && <SuperAdminNav showingSuperAdminNav={showingSuperAdminNav} />}
      </Navbar>
      {isTrainingMode &&
        <div style={{ width: '100%', height: '56px', backgroundColor: '#dc3545' }} className='d-flex align-items-center justify-content-center mb-2'>
          <h2 className='mb-0' style={{ fontWeight: 'bold', color: 'white' }}>TRAINING MODE</h2>
        </div>
      }
    </>
  )
};