import { API } from "aws-amplify";
import { getPatientDemographic } from "./patientDemographicLib";
import { getSpirometerFromPatientID } from "./spirometerLib";
import { getStudyID } from "./zephyrxLib";

const API_PATH = "patient";

export function checkPatientAPI() {
  return API.get(API_PATH, "/check");
}

export function createPatient(patient) {
  return API.post(API_PATH, "/create", {
    body: patient
  });
}

export function getPatientBySpirometerID(spirometerID) {
  return API.get(API_PATH, `/spirometer/${spirometerID}`);
}

export function getIsPatientSharing(patientID) {
  return API.get(API_PATH, `/sharing/${patientID}`);
}

export function getIsPatientSharingWithOrg(patientID, organizationID) {
  return API.get("organization-consent", `/sharing/${organizationID}/${patientID}`);
}

export function getPatient(patientID) {
  return API.get(API_PATH, `/${patientID}`);
}

export function getConfig(patientID) {
  return API.get(API_PATH, `/config/${patientID}`);
}

export function getActivity(patientID) {
  return API.get(API_PATH, `/activity/${patientID}`);
}

export function getCffRegistryID(spirometerID) {
  return API.get(API_PATH, `/cffRegistryID/${spirometerID}`)
}

export function getPatientChart(patient) {
  let patientID = typeof (patient) === 'object' ? patient.patientID : patient;
  return API.get(API_PATH, `/data/${patientID}`);
}

export function listPatients() {
  return API.get(API_PATH, "/list");
}

export function getInitials(patientID) {
  return API.get(API_PATH, `/initials/${patientID}`);
}

export function getCurrentTasks(patientID, organizationID) {
  return API.get(API_PATH, `/tasks/${patientID}`);
}

export async function updatePatientDemographic(patientID, updatedDemographic) {
  const response = await API.put(API_PATH, `/demographic/${patientID}`, {
    body: updatedDemographic
  });
  return response;
}

export async function updatePatient(updatedPatient) {
  const response = await API.put(API_PATH, `/update/${updatedPatient.patientID}`, {
    body: updatedPatient
  });
  return response;
}

export function removePatient(patientID) {
  return API.get(API_PATH, `/remove/${patientID}`);
}

/**
 * This function does not make a safety check, it simply tries to create a patient account
 * You will get an error if the patient account exists
 * @param {String} organizationID
 * @param {String} spirometerID
 * @returns {Promise}
 */
export const preRegisterPatient = async (organizationID, spirometerID) => {
  const body = { organizationID, spirometerID };
  return API.post(API_PATH, '/pre-register', { body });
};

/**
 *
 * @param {String} organizationID
 * @param {String[]} spirometerIDs
 * @returns object with key of error or an array of data for each successful spirometer registered
 */
export const preRegisterPatients = async (organizationID, spirometerIDs) => {
  const body = { organizationID, spirometerIDs };
  return API.post(API_PATH, '/pre-register/bulk', { body });
};

export const createPftNowNotification = async (data) => {
  const response = await API.put(API_PATH, `/pftnow`, {
    body: {
      patientID: data.patientID,
      pftType: data.pftType?.toLowerCase() ?? 'fvc',
      organizationID: data.organizationID,
      providerID: data.providerID,
      recommendedNumberOfEfforts: data.recommendedEfforts || 3,
      notes: data.notes || ''
    }
  });
  return response;
}

export const deletePftNowNotification = async (patientID, taskID) => {
  return await API.del(API_PATH, `/pftnow/${patientID}/${taskID}`)
}

export const createTestNotification = async (patientID, text = 'Your provider sent you a test notification!') => {
  return API.put(API_PATH, `/testNotifications/${patientID}`, {
    body: { patientID, text }
  });
};

export const updateCachedPatients = async (patients, orgName, orgProgram, orgID, providerOrganizationList, studyIDSettings) => {
  return (await
    Promise.all(patients.map(
      async patient => {
        try {
          let results = await Promise.all([getIsPatientSharingWithOrg(patient.patientID, orgID), getActivity(patient.patientID)]);
          patient.consent = results[0];
          patient.consentRange = [results[0].consentTimestamp, results[0].expirationTimestamp];
          patient.activity = results[1];
          let studyIDObject;
          if (studyIDSettings.useStudyID) studyIDObject = await getStudyID(orgProgram.toLowerCase(), patient.patientID);
          patient.display = studyIDObject?.studyID ? studyIDSettings.nameStudyID ? studyIDObject.studyID : `${providerOrganizationList.includes(orgID) ? patient.name : patient.spirometer[0]} (${studyIDObject.studyID})` : providerOrganizationList.includes(orgID) ? patient.name : patient.spirometer[0];
          patient.organization = orgName;
          patient.program = orgProgram;
          return patient;
        }
        catch (e) {
          console.log(patient, e);
          return null;
        }
      }
    ))
  ).filter(patient => !!patient)
};

export const getPatientsFromIDs = async (patientIDs, orgName, orgProgram, orgID, providerOrganizationList, studyIDSettings) => {
  return (await
    Promise.all(patientIDs.map(
      async patientID => {
        try {
          let results = await Promise.all([getPatient(patientID), getIsPatientSharingWithOrg(patientID, orgID), getActivity(patientID), getSpirometerFromPatientID(patientID)]);
          const patient = results[0];
          const patientDemo = await getPatientDemographic(patientID, patient.demographicID);
          patient.consent = results[1];
          patient.consentRange = [results[1].consentTimestamp, results[1].expirationTimestamp];
          patient.activity = results[2];
          patient.spirometer = results[3].spirometerIDs;
          patient.name =
            patientDemo.name ?
              patientDemo.name
              : patient.spirometer[0] ?
                patient.spirometer[0]
                : "No Name Entered";
          let studyIDObject;
          if (studyIDSettings.useStudyID) studyIDObject = await getStudyID(orgProgram.toLowerCase(), patient.patientID);
          patient.display = studyIDObject?.studyID ? studyIDSettings.nameStudyID ? studyIDObject.studyID : `${providerOrganizationList.includes(orgID) ? patient.name : patient.spirometer[0]} (${studyIDObject.studyID})` : providerOrganizationList.includes(orgID) ? patient.name : patient.spirometer[0];
          patient.organization = orgName;
          patient.program = orgProgram;
          return patient;
        }
        catch (e) {
          console.log(patientID, e);
          return null;
        }
      }
    ))
  ).filter(patient => !!patient)
};

export const getBatchedPatientDetails = async (organizationID, limit = 'all=true') => {
  return await API.get(API_PATH, `/batch/${organizationID}?${limit}`);
};

export const generatePatientMap = (patients) => {
  patients.sort((a, b) => (a.name.replace(/\s/g, '').toUpperCase() < b.name.replace(/\s/g, '').toUpperCase()) ? -1 : (a.name.replace(/\s/g, '').toUpperCase() > b.name.replace(/\s/g, '').toUpperCase()) ? 1 : 0);
  return patients.reduce((allPatients, patient) => ({ ...allPatients, [patient.patientID]: patient }), {});
};

export const sortPatientsByOption = (patients, sortBy) => {
  switch (sortBy) {
    case 'lastUsed':
      return [...patients].sort((a, b) => b.activity.mostRecent.timestamp - a.activity.mostRecent.timestamp);
    case 'numUses':
      return [...patients].sort((a, b) => b.activity.numUses - a.activity.numUses);
    case 'display':
      return [...patients].sort((a, b) => b.display.toLowerCase() > a.display.toLowerCase() ? -1 : b.display.toLowerCase() === a.display.toLowerCase() ? 0 : 1);
    case 'trialStatus':
      return [...patients].sort((a, b) => {
        // Patients without trialStatus should be addressed first...
        if (!a?.trialStatus && !b?.trialStatus) return 0;
        if (!a?.trialStatus) return -1;
        if (!b?.trialStatus) return 1;

        // Then move enrolled patients to the top
        if (a?.trialStatus === 'enrolled') return -1;

        // Always move withdrawn to the end
        if (a?.trialStatus === 'withdrawn') return 1;
        if (b?.trialStatus === 'withdrawn') return -1

        if (a?.trialStatus === 'completed') return 0;
        return -1;
      });
    default:
      return patients;
  }
};

export const PATIENTS_SORT_MAP = {
  'lastUsed': 'Last Used',
  'numUses': 'Total Uses',
  'display': 'Name',
  'trialStatus': 'Trial Status',
};

export const getInitialPftType = (params, chart, pftType) => {
  const shouldInitiallyBeSvc = params.pftType === 'svc'
    || (Object.keys(chart.fvc.chart).length === 0
      && Object.keys(chart.svc.chart).length > 0
      && pftType !== 'svc');

  const shouldInitiallyBePef = params.pftType === 'pef'
    || (Object.keys(chart.fvc.chart).length === 0
      && Object.keys(chart.pef.chart).length > 0
      && pftType !== 'pef');

  return shouldInitiallyBeSvc ? 'svc' : shouldInitiallyBePef ? 'pef' : 'fvc';
};