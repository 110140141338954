import * as React from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { useToasts } from 'react-toast-notifications'
import { Auth } from "aws-amplify";
import { batch, shallowEqual, useDispatch, } from 'react-redux';

import { Routes } from "./Routes";
import { AnimatedLogo } from "./components/AnimatedLogo";
import { ZephyrxNavbar } from "./components/Navbars/ZephyrxNavbar";

import { updateProvider, getProviderFromEmail, ProviderStatus, logProviderSignin, getProvider, postSamlAuth } from "./libs/providerLib";
import { recoverSeriesNotes } from "./libs/seriesLib";
import { getCookie, ONE_MINUTE_IN_MILLISECONDS, removeCookie } from "./libs/dataLib";
import { availableLanguages } from "./libs/locationLib";
import { clearPrograms, fetchPrograms } from "./redux/programs/actionCreators";
import { isEmptyObjectOrArray, logObjectIfFullAdmin, parseVuplexEvent } from "./libs/utilsLib";
import { setLanguage } from "./redux/language/actionCreators";
import { removeProvider, setProvider, updateViewingAs } from "./redux/provider/actionCreators";
import { removeProviders } from "./redux/providers/actionCreators";
import { clearOrganizationsForProvider, fetchActiveOrganizationsForProvider, setSelectedOrg } from "./redux/organizationProvider/actionCreators";
import { clearOrganizations, fetchAllOrganizations } from "./redux/organization/actionCreators";
import { clearSSOPools } from "./redux/sso/actionCreators";
import { clearBilling } from "./redux/billing/actionCreators";
import { clearPatients } from './redux/patients/actionCreators';
import { getProviderFromOrganization } from "./libs/organizationProviderLib";
import { useEffectOnce } from "./libs/hooksLib";
import { Provider } from "./Types/Common/Types";
import { setTranslations } from "./redux/translations/actionCreators";
import { useAppSelector } from "./redux/store";
import { sendMessageToCSharp } from "./libs/kioskLib";
import { setIsKioskMode, } from "./redux/kiosk/actionCreators";
import { Kiosk } from "./Types/Common/KioskTypes";
import { OrgSelectOption } from "./Types/MiscTypes";
// import { demoKioskData, } from "./libs/kioskLib";
// import { setMobileDevice } from './redux/kiosk/actionCreators';

import "./App.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

const { useState, useEffect, useRef, useCallback } = React;

interface AppProps { };

const App: React.FC<AppProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const programs = useAppSelector(state => state.programs, shallowEqual);
  const { isKioskMode, kiosk, mobileDevice } = useAppSelector(state => state.kiosk);
  const language = useAppSelector(state => state.language, shallowEqual);
  const translationText = useAppSelector(state => state.translations, shallowEqual);
  const organizations = useAppSelector(state => state.organizations, shallowEqual);
  const authenticatedProvider = useAppSelector(state => state.provider, shallowEqual);
  const { selectedOrg, organizationsForProviderIDs, detailedOrganizationsForProvider, organizationsForProviderDropdown } = useAppSelector(state => state.organizationsForProvider, shallowEqual);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [samlError, setSamlError] = useState('');
  const logoutIntervalMinutes = useRef(15);
  const inactiveLogoutIntervalID = useRef<NodeJS.Timeout | null>(null);
  const hasRunInLast5Seconds = useRef(false);
  const isProviderActive = useRef(!!getCookie('loadedFromLogin'));
  const { removeAllToasts } = useToasts();
  const handleProviderIsActive = useCallback(() => {
    if (!isProviderActive.current) isProviderActive.current = true;
  }, []);

  // ************* INFO: FOR KIOSK MODE DEV ONLY *******************
  // console.log({ mobileDevice });
  // useEffect(() => {
  //   if (!mobileDevice) dispatch(setMobileDevice(demoKioskData.mobileDevice));
  //   dispatch(setIsKioskMode(true));
  // }, [mobileDevice, dispatch]);
  // ************* END FOR DEV ONLY ***************

  const logIfCurrentUser = (e: any) => {
    if (e !== 'No current user') console.log(e);
  };

  const displaySamlError = () => {
    const samlErrorDescriptionFromUrl = new URLSearchParams(location.search).get('error_description') ?? '';
    if (samlErrorDescriptionFromUrl) setSamlError(samlErrorDescriptionFromUrl);
  };

  const getProviderEmailFromIdToken = (idToken: any) => {
    return idToken?.payload?.email ? idToken.payload.email : idToken.payload.identities[0]?.userId?.toLowerCase();
  };

  // Check if the user is already logged in, if so load their provider info
  const onLoad = async () => {
    displaySamlError();
    setIsAuthenticating(true);

    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const providerEmail = getProviderEmailFromIdToken(idToken);
      const provider: Provider = await getProviderFromEmail(providerEmail);
      const loadedFromLogin = getCookie('loadedFromLogin');
      const loadedFromSamlLogin = getCookie('loadedFromSamlLogin');
      const samlKioskLogin = getCookie('samlKioskLogin');

      if (provider.cognitoID !== idToken.payload.sub) provider.cognitoID = idToken.payload.sub;

      if (loadedFromLogin) {
        provider.lastActive = Date.now();
        removeCookie('loadedFromLogin');
      } else if (provider.lastActive < Date.now() - (logoutIntervalMinutes.current * ONE_MINUTE_IN_MILLISECONDS)) return handleLogout();

      if (loadedFromSamlLogin) {
        postSamlAuth(provider.email, provider.providerID, provider.viewingAs.organizationID).catch(err => console.log(err?.message ?? err));
        removeCookie('loadedFromSamlLogin');
      };

      await updateProvider({
        ...provider,
        lastLogin: Date.now(),
        status: ProviderStatus.active
      });

      logProviderSignin(provider.providerID, navigator.userAgent).catch(err => console.log(err));
      // NOTE: This is intentionally commented out! See ZDP-341 for details.
      // const continueLogin = (await checkActiveLogin(provider.providerID));
      // if (!continueLogin) return handleLogout();

      if (provider && isEmptyObjectOrArray(authenticatedProvider)) dispatch(setProvider(provider));
      if (samlKioskLogin) {
        removeCookie('samlKioskLogin');
        navigate('/kiosk');
      };
    } catch (e: any) {
      logIfCurrentUser(e);
    } finally {
      setIsAuthenticating(false);
    };
  };

  useEffectOnce(() => {
    // ADD PROVIDER ACTIVITY EVENT LISTENERS
    document.addEventListener('mousemove', handleProviderIsActive);
    document.addEventListener('keypress', handleProviderIsActive);
    document.addEventListener('pointerup', handleProviderIsActive);

    // LISTEN FOR PROVIDER ACTIVITY MESSAGE FROM MOBILE APP FOR KIOSK MODE
    const vuplexProviderActivityMessageListener = (event: any) => {
      const { type } = parseVuplexEvent(event);
      if (type === 'providerActive') handleProviderIsActive();
    };

    const addVuplexMessageListener = () => window?.vuplex?.addEventListener('message', vuplexProviderActivityMessageListener);

    if (window?.vuplex) {
      addVuplexMessageListener();
    } else {
      window.addEventListener('vuplexready', addVuplexMessageListener);
    };

    // HANDLE INITIAL AUTHENTICATION ON MOUNT
    onLoad();

    return () => {
      console.log("Removing listeners");
      document.removeEventListener('mousemove', handleProviderIsActive);
      document.removeEventListener('keypress', handleProviderIsActive);
      document.removeEventListener('pointerup', handleProviderIsActive);
      window?.vuplex?.removeEventListener('message', vuplexProviderActivityMessageListener);
    }
  });

  // HANDLE SOMEONE SHOWING UP WITH A NON-ENGLISH LANGUAGE PREFERENCE
  useEffectOnce(() => {
    const formattedLanguage = language.slice(0, 2).toLowerCase();
    if (formattedLanguage === 'en') return;

    import(`./translations/${formattedLanguage}.json`)
      .then(results => dispatch(setTranslations(results)))
      .catch(e => console.log(e));
  });

  useEffect(() => {
    // CHECKING FOR KIOSK MODE SYNCED UP DATA STUFF
    if (hasRunInLast5Seconds.current) return;
    hasRunInLast5Seconds.current = true;

    if (!mobileDevice) {
      sendMessageToCSharp('requestMobileDevice', true);
    } else if (mobileDevice && !isKioskMode) {
      dispatch(setIsKioskMode(true));
    } else if (!location.pathname.includes('/kiosk') && isKioskMode) {
      navigate('/kiosk');
    }

    setTimeout(() => {
      hasRunInLast5Seconds.current = false;
    }, 5000);
  }, [isKioskMode, navigate, location, mobileDevice, dispatch]);

  useEffect(() => {
    if (isEmptyObjectOrArray(authenticatedProvider) || isEmptyObjectOrArray(organizationsForProviderDropdown) || (!!selectedOrg && !isEmptyObjectOrArray(selectedOrg)) || (authenticatedProvider.fullAdmin && isEmptyObjectOrArray(organizations.organizationsDropdown))) return;

    const populatedOrg: OrgSelectOption = authenticatedProvider.fullAdmin ? organizations?.organizationsDropdown?.find((org: OrgSelectOption) => org.id === authenticatedProvider.viewingAs.organizationID) : organizationsForProviderDropdown?.find((org: OrgSelectOption) => org.id === authenticatedProvider.viewingAs.organizationID.substring(0, 36));
    dispatch(setSelectedOrg(populatedOrg));
  }, [selectedOrg, authenticatedProvider, dispatch, organizations, organizationsForProviderDropdown]);

  // Triggered either after Login or when the page is reloaded while already logged in
  useEffect(() => {
    const reloadPage = async () => {
      if (isEmptyObjectOrArray(authenticatedProvider)) return;
      logObjectIfFullAdmin(authenticatedProvider, authenticatedProvider, 'Authenticated Provider: ');

      recoverSeriesNotes();
      clearInterval(logoutIntervalMinutes.current);
      inactivePoll();

      // SETTING UP ALL OTHER REDUX STORE STATE
      batch(async () => {
        if (authenticatedProvider.fullAdmin && isEmptyObjectOrArray(organizations.detailedOrganizations)) await dispatch(fetchAllOrganizations());
        if (isEmptyObjectOrArray(programs)) dispatch(fetchPrograms());
        if (isEmptyObjectOrArray(organizationsForProviderIDs)) await dispatch(fetchActiveOrganizationsForProvider());
      });

      const needsViewingAsReset = !authenticatedProvider.fullAdmin && !isEmptyObjectOrArray(detailedOrganizationsForProvider) && !detailedOrganizationsForProvider[authenticatedProvider?.viewingAs?.organizationID.replace('_training', '')];
      if (!needsViewingAsReset) return;

      console.log("RESETTING PROVIDER VIEWING AS ORG");
      const newViewingAsOrganization = detailedOrganizationsForProvider[organizationsForProviderIDs[0]];
      const providerPermissionForOrg = await getProviderFromOrganization(newViewingAsOrganization.organizationID, authenticatedProvider.providerID);
      const updatedViewingAs = {
        organizationID: newViewingAsOrganization.organizationID,
        name: newViewingAsOrganization.name,
        program: newViewingAsOrganization.program,
        orgAdmin: providerPermissionForOrg?.orgAdmin,
        readOnly: providerPermissionForOrg?.readOnly
      };
      await dispatch(updateViewingAs(authenticatedProvider.providerID, updatedViewingAs));
    };
    reloadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedProvider]);

  useEffect(() => {
    const updateTranslations = () => {
      if (isEmptyObjectOrArray(authenticatedProvider) || isEmptyObjectOrArray(programs)) return;
      // HANDLE PROGRAMS THAT WANT TO FORCE THE DASHBOARD LANGUAGE FOR THEIR PROVIDERS
      const program = programs[authenticatedProvider.viewingAs.program?.toUpperCase() ?? ''];
      if (!program) return;

      const shouldForceChangeLanguage = program?.featureSet?.forceLanguage && program?.featureSet?.language && program.featureSet.language !== language;

      if (shouldForceChangeLanguage) {
        dispatch(setLanguage(program.featureSet.language ?? 'en'))
        import(`./translations/${program.featureSet.language}.json`)
          .then(translationText => {
            dispatch(setTranslations(translationText));
          });
        return;
      };
      // OTHERWISE WE GO WITH THE PROVIDER'S PREFERRED LANGUAGE
      if (!authenticatedProvider.language && availableLanguages.languageCodes.includes(language.slice(0, 2).toLowerCase())) {
        dispatch(setLanguage(language.slice(0, 2)));
        import(`./translations/${language.slice(0, 2).toLowerCase()}.json`)
          .then(translationText => {
            dispatch(setTranslations(translationText));
          });
      } else if (authenticatedProvider?.language && authenticatedProvider.language !== language) {
        dispatch(setLanguage(authenticatedProvider.language?.slice(0, 2)));
        import(`./translations/${authenticatedProvider.language.slice(0, 2).toLowerCase()}.json`)
          .then(translationText => {
            dispatch(setTranslations(translationText));
          });
      }
    };

    updateTranslations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedProvider, dispatch, programs]);

  const generatePathForLogout = (isKioskMode: boolean, kiosk: Kiosk | null) => {
    if (!isKioskMode) return '';
    let path = `/login?redirect=/kiosk`;
    if (kiosk?.kioskID) path += `?kioskID=${kiosk.kioskID}`;
    return path;
  };

  const handleLogout = useCallback(async (redirect?: string) => {
    batch(() => {
      dispatch(removeProvider());
      dispatch(clearOrganizationsForProvider());
      dispatch(clearPrograms());
      dispatch(removeProviders());
      dispatch(clearOrganizations());
      dispatch(clearBilling());
      dispatch(clearSSOPools());
      dispatch(clearPatients());
    });

    const path = redirect
      ? `/login?redirect=${redirect}`
      : generatePathForLogout(isKioskMode, kiosk);

    navigate(path);
    localStorage.removeItem(`${process.env.REACT_APP_STAGE?.toUpperCase()}-apiConfig`);
    removeAllToasts();

    await Auth.signOut();
    window.onload = null;
    document.onmousemove = null;
    document.onkeydown = null;
    document.onmousemove = null;
    window.onunload = null;

    sessionStorage.clear();
    sendMessageToCSharp('logout', true);
  }, [dispatch, removeAllToasts, isKioskMode, kiosk, navigate]);

  const inactivePoll = useCallback(() => {
    clearInterval(inactiveLogoutIntervalID.current as NodeJS.Timeout);

    inactiveLogoutIntervalID.current = setInterval(async () => {
      if (isProviderActive.current) {
        try {
          updateProvider({ providerID: authenticatedProvider.providerID, lastActive: Date.now() });
          if (authenticatedProvider.fullAdmin) console.log('updating lastActive to', new Date(Date.now()).toString(), 'timeout in', logoutIntervalMinutes.current, 'min.');
          isProviderActive.current = false;
        } catch (e) {
          clearInterval(inactiveLogoutIntervalID.current as NodeJS.Timeout);
        }
      } else {
        try {
          const { lastActive } = await getProvider(authenticatedProvider.providerID);
          const laterActive = Math.max(authenticatedProvider.lastActive, lastActive);
          if (authenticatedProvider.fullAdmin) console.log('keeping lastActive at', new Date(lastActive).toString(), 'timeout at', `${new Date(laterActive).getHours()}:${new Date(laterActive).getMinutes() + logoutIntervalMinutes.current}`);
          if (laterActive < Date.now() - (logoutIntervalMinutes.current * ONE_MINUTE_IN_MILLISECONDS)) {
            clearInterval(inactiveLogoutIntervalID.current as NodeJS.Timeout);
            handleLogout();
          };
        } catch (e: any) {
          console.log(e);
          if (e?.response?.status === 403) {
            clearInterval(inactiveLogoutIntervalID.current as NodeJS.Timeout);
            handleLogout();
          }
        }
      }
    }, ONE_MINUTE_IN_MILLISECONDS);

    document.addEventListener('unload', () => {
      console.log('UNLOAD TRIGGERED');
      clearInterval(inactiveLogoutIntervalID.current as NodeJS.Timeout);
      inactivePoll();
    });
  }, [isProviderActive, authenticatedProvider.lastActive, authenticatedProvider.fullAdmin, authenticatedProvider.providerID, handleLogout]);

  return (
    <Container fluid id='app-container' className="px-0 h-100">
      {isAuthenticating
        ? <Container fluid className="d-flex justify-content-center align-items-center mt-5">
          <AnimatedLogo />
        </Container>
        : (language && !!translationText.male) && (
          <Container fluid id="main-container" className="App px-0">
            <ZephyrxNavbar handleLogout={handleLogout} />
            <Routes appProps={{ handleLogout, logoutIntervalMinutes, samlError }} />
          </Container>
        )}
    </Container >
  );
};

export default App;