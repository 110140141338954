import { BatchedPatientDetails, NonAcceptedPatient, } from '../../Types/Common/Types';
import { SET_PATIENTS, SET_NON_OVERREAD_PATIENTS, SET_NON_ACCEPTED_PATIENTS, CLEAR_PATIENTS } from './actionTypes';

type PatientsState = {
  patients: { [key: string]: BatchedPatientDetails };
  nonOverreadPatients: NonAcceptedPatient[];
  nonAcceptedPatients: NonAcceptedPatient[];
};

type Action = {
  payload: NonAcceptedPatient[] | { [key: string]: BatchedPatientDetails };
  type: string;
};

const INITIAL_STATE: PatientsState = { patients: {}, nonOverreadPatients: [], nonAcceptedPatients: [] };

// THIS REDUCER IS NOT FULLY UTILIZED OR FINISHED
export const patientsReducer = (state = INITIAL_STATE, action: Action): PatientsState => {
  switch (action.type) {
    case SET_PATIENTS:
      if (Array.isArray(action.payload)) throw new Error('Payload must NOT be of type: Array');
      return { ...state, patients: { ...action.payload } };
    case SET_NON_OVERREAD_PATIENTS:
      if (!Array.isArray(action.payload)) throw new Error('Payload must be of type: Array');
      return { ...state, nonOverreadPatients: [...action.payload] };
    case SET_NON_ACCEPTED_PATIENTS:
      if (!Array.isArray(action.payload)) throw new Error('Payload must be of type: Array')
      return { ...state, nonAcceptedPatients: [...action.payload] };
    case CLEAR_PATIENTS:
      return INITIAL_STATE;
    default:
      return state;
  };
};