import { Reducer } from 'redux';
import { PatientGraph } from '../../Types/ChartTypes';
import { EmrID } from '../../Types/Common/BrandedTypes';
import { OrganizationConsent, Patient, PatientDemographics, PatientNameType } from '../../Types/Common/Types';
import { CLEAR_PATIENT, SET_ORGANIZATION_CONSENT, SET_PATIENT, SET_PATIENT_GRAPH, SET_PATIENT_DEMOGRAPHICS, SET_PATIENT_NAME, SET_SPIROMETER_ID, SET_CFF_ID, SET_EMR_ID } from './actionTypes';

interface PatientReducerAction {
  type: string;
  payload: Patient | PatientDemographics | PatientGraph | PatientNameType | OrganizationConsent | string | EmrID;
};

const DEFAULT_STATE = {
  patient: {} as Patient,
  patientGraph: {} as PatientGraph,
  patientDemographics: {} as PatientDemographics,
  organizationConsent: {} as OrganizationConsent,
  patientName: {} as PatientNameType,
  spirometerID: '',
  cffID: '',
  emrID: '' as EmrID
};
const returnDefaultState = () => DEFAULT_STATE;
type StateType = ReturnType<typeof returnDefaultState>;

export const patientReducer: Reducer<StateType, PatientReducerAction> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_PATIENT:
      return { ...state, patient: action.payload };
    case SET_PATIENT_GRAPH:
      return { ...state, patientGraph: action.payload };
    case SET_PATIENT_DEMOGRAPHICS:
      return { ...state, patientDemographics: action.payload };
    case SET_ORGANIZATION_CONSENT:
      return { ...state, organizationConsent: action.payload };
    case SET_PATIENT_NAME:
      return { ...state, patientName: action.payload };
    case SET_EMR_ID:
      return { ...state, emrID: action.payload };
    case SET_SPIROMETER_ID:
      return { ...state, spirometerID: action.payload };
    case SET_CFF_ID:
      return { ...state, cffID: action.payload };
    case CLEAR_PATIENT:
      return DEFAULT_STATE;
    default:
      return state;
  }
};