import { Reducer } from 'redux';
import { Provider } from '../../Types/Common/Types';
import { SET_PROVIDER, REMOVE_PROVIDER } from './actionTypes';

const INITIAL_STATE = {} as Provider;

interface ProviderReducerAction {
  type: typeof SET_PROVIDER | typeof REMOVE_PROVIDER;
  payload: Provider;
};

export const providerReducer: Reducer<typeof INITIAL_STATE, ProviderReducerAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROVIDER:
      return { ...action.payload };
    case REMOVE_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  };
};