import React, { useEffect, useRef, useState, useCallback } from "react";

const TruncateMiddle = ({ value }) => {
  const [shorten, setShorten] = useState(value);
  const containerRef = useRef(null);

  const tempContainer = document.createElement('div');
  tempContainer.style.display = "inline-block";
  tempContainer.style.whiteSpace = "nowrap";
  tempContainer.style.visibility = "hidden";
  tempContainer.style.height = "1px";
  tempContainer.style.position = "fixed";

  const onContainerResize = useCallback((width, string, fontSize) => {
    tempContainer.innerText = string;
    tempContainer.style.fontSize = fontSize;
    const middle = Math.floor(string.length / 2);
    if (tempContainer.offsetWidth > width) {
      for (let i = 0; i < middle; i++) {
        const shortString = string.slice(0, middle - i).trim() + "..." + string.slice(middle + 1 + i).trim();
        tempContainer.innerText = shortString;
        if (tempContainer.offsetWidth <= width) {
          return shortString;
        }
      }
    } else {
      return string;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempContainer.innerText, tempContainer.offsetWidth, tempContainer.style.fontSize]);

  useEffect(() => {
    let resize = {};
    if (window.ResizeObserver) {
      const fontSize = getComputedStyle(containerRef.current).fontSize ? getComputedStyle(containerRef.current).fontSize : "16px";
      resize = new ResizeObserver(entries => {
        document.body.appendChild(tempContainer);
        for (let entry of entries) {
          const newString = onContainerResize(entry.contentRect.width, value, fontSize);
          setShorten(newString);
        }
      });
      resize.observe(containerRef.current);
    }
    return () => (resize
      && resize.disconnect
      && typeof resize.disconnect === "function"
      && resize.disconnect());
  }, [onContainerResize, tempContainer, value]);

  return (
    <div className="truncate-container" ref={containerRef}>
      <div>{shorten}</div>
    </div>
  )
};

export default TruncateMiddle;