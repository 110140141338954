export const availableLanguages = Object.freeze({
  languageCodes: [
    "da-DK", "da",
    "de-BE", "de-CH", "de-DE", "de",
    "en-US", "en-CA", "en-IE", "en-GB", "en-AU", "en",
    "es-US", "es",
    "fr-CA", "fr-BE", "fr-CH", "fr",
    "it-CH", "it-IT", "it",
    "jp-JP", "jp",
    "nl-BE", "nl-NL", "nl",
    "pl",
    "ru-IL", "ru",
    "sv-SE", "sv",
  ],
  languageMap: {
    "da": "Dansk (Danish)",
    "de": "Deutsche (German)",
    "en": "English",
    // "en-US": "English (United States)",
    // "en-CA": "English (Canadian)",
    // "en-IE": "English (Ireland)",
    // "en-GB": "English (Great Britain)",
    // "en-AU": "English (Australia)",
    "es": "Español",
    // "es-US": "Español (Americano)",
    "fr": "Français",
    // "fr-CA": "Français (Canadien)",
    "it": "Italiano (Italian)",
    "nl": "Nederlands (Dutch)",
    "jp": "日本語 (Japanese)",
    "pl": "Polskie (Polish)",
    "ru": "русский (Russian)",
    "sv": "Sverige (Swedish)"
  }
});

/*
langs: {
  ar: "",
  da: "",
  dk: "",
  en: "",
  es: "",
  fr: "",
  he: "",
  it: "",
  jp: "",
  nl: "",
  pl: "",
  ru: "",
  sv: "",
}
*/

export const countryMap = Object.freeze({
  US: {
    countryName: {
      ar: "الولايات المتحدة", // Arabic
      da: "Amerikas Forenede Stater", // Danish
      de: "vereinigte Staaten von Amerika", // German
      en: "United States", // English
      es: "Estados Unidos de América", //Spanish
      fr: "les états-unis d'Amérique", // French
      he: "ארצות הברית", // hebrew
      it: "Stati Uniti d'America", // italian
      jp: "アメリカ合衆国", // Japanese
      nl: "de Verenigde Staten van Amerika", // Dutch
      pl: "Stany Zjednoczone Ameryki", // Polish
      ru: "Соединенные Штаты Америки", // Russian
      sv: "Amerikas förenta stater"// Swedish
      // LANGUAGES NOT CURRENTLY SUPPORTED
      // bg: "", // Bulgarian
      // tr: "", // Turkish
      // za: "", // South African
    },
    divisionName: "State",
    divisions: {
      AL: "Alabama",
      AK: "Alaska",
      AS: "American Samoa",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District Of Columbia",
      FM: "Federated States Of Micronesia",
      FL: "Florida",
      GA: "Georgia",
      GU: "Guam",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MH: "Marshall Islands",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      MP: "Northern Mariana Islands",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PW: "Palau",
      PA: "Pennsylvania",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VI: "Virgin Islands",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming"
    },
    postalName: "Postal Code"
  },
  AU: {
    countryName: {
      en: "Australia", // English
      ar: "أستراليا", // Arabic
      da: "Australien", // Danish
      de: "Australien", // German
      es: "Australia", // Spanish
      fr: "Australie", // French
      he: "אוֹסטְרַלִיָה", // Hebrew
      it: "Australia", // Italian
      jp: "オーストラリア", // Japanese
      nl: "Australië", // Dutch
      pl: "Australia", // Polish
      ru: "Австралия", // Russian
      sv: "Australien"// Swedish
    },
    divisionName: "State/Territory",
    divisions: {
      "AU-NSW": "New South Wales",
      "AU-QLD": "Queensland",
      "AU-SA": "South Australia",
      "AU-TAS": "Tasmania",
      "AU-VIC": "Victoria",
      "AU-WA": "Western Australia",
      "AU-ACT": "Australian Capital Territory",
      "AU-NT": "Northern Territory"
    }
  },
  CA: {
    countryName: {
      en: "Canada",
      ar: "كندا",
      da: "Canada",
      de: "Kanada",
      es: "Canadá",
      fr: "Canada",
      he: "קנדה",
      it: "Canada",
      jp: "カナダ",
      nl: "Canada",
      pl: "Kanada",
      ru: "Канада",
      sv: "kanada"
    },
    divisionName: 'Province / Territory',
    divisions: {
      AB: "Alberta",
      BC: "British Columbia",
      MB: "Manitoba",
      NB: "New Brunswick",
      NL: "Newfoundland and Labrador",
      NS: "Nova Scotia",
      ON: "Ontario",
      PE: "Prince Edward Island",
      QC: "Quebec",
      SK: "Saskatchewan",
      NT: "Northwest Territories",
      NU: "Nunavut",
      YT: "Yukon"
    },
    postalName: "Postal Code"
  },
  BE: {
    countryName: {
      en: "Belgium", // English
      ar: "بلجيكا", // Arabic
      da: "Belgien", // Danish
      de: "Belgien", // German
      es: "Bélgica", // Spanish
      fr: "Belgique", // French
      he: "בלגיה", // Hebrew
      it: "Belgio", // Italian
      jp: "ベルギー", // Japanese
      nl: "Belgie", // Dutch
      pl: "Belgia", // Polish
      ru: "Бельгия", // Russian
      sv: "belgien"// Swedish
    },
    divisionName: "Province",
    divisions: {
      "BE-BRU": "Bruxelles-Capitale, Region de (fr), Brussels Hoofdstedelijk Gewest (nl)",
      "BE-VLG": "Vlaamse Gewest (nl)",
      "BE-VAN": "Antwerpen (nl)",
      "BE-VLI": "Limburg (nl)",
      "BE-VOV": "Oost-Vlaanderen (nl)",
      "BE-VBR": "Vlaams Brabant (nl)",
      "BE-VWV": "West-Vlaanderen (nl)",
      "BE-WAL": "Wallonne, Region (fr)",
      "BE-WBR": "Brabant Wallon (fr)",
      "BE-WHT": "Hainaut (fr)",
      "BE-WLG": "Liège (fr)",
      "BE-WLX": "Luxembourg (fr)",
      "BE-WNA": "Namur (fr)"
    }
  },
  DE: {
    countryName: {
      en: "Germany", // English
      ar: "ألمانيا", // Arabic
      da: "Tyskland", // Danish
      de: "Deutschland", // German
      es: "Alemania", // Spanish
      fr: "Allemagne", // French
      he: "גֶרמָנִיָה", // Hebrew
      it: "Germania", // Italian
      jp: "ドイツ", // Japanese
      nl: "Duitsland", // Dutch
      pl: "Niemcy", // Polish
      ru: "Германия", // Russian
      sv: "Tyskland"// Swedish
    },
    divisionName: "Land",
    divisions: {
      "DE-BW": "Baden-Württemberg",
      "DE-BY": "Bayern",
      "DE-BE": "Berlin",
      "DE-BB": "Brandenburg",
      "DE-HB": "Bremen",
      "DE-HH": "Hamburg",
      "DE-HE": "Hessen",
      "DE-MV": "Mecklenburg-Vorpommern",
      "DE-NI": "Niedersachsen",
      "DE-NW": "Nordrhein-Westfalen",
      "DE-RP": "Rheinland-Pfalz",
      "DE-SL": "Saarland",
      "DE-SN": "Sachsen",
      "DE-ST": "Sachsen-Anhalt",
      "DE-SH": "Schleswig-Holstein",
      "DE-TH": "Thüringen"
    }
  },
  DK: {
    countryName: {
      en: "Denmark",
      ar: "الدنمارك", // Arabic
      da: "Danmark", // Danish
      de: "Dänemark", // German
      es: "Dinamarca", // Spanish
      fr: "Danemark", // French
      he: "דנמרק", // Hebrew
      it: "Danimarca", // Italian
      jp: "デンマーク", // Japanese
      nl: "Denemarken", // Dutch
      pl: "Dania", // Polish
      ru: "Дания", // Russian
      sv: "Danmark"// Swedish
    },
    divisionName: "Regioner",
    divisions: {
      "DK-147": "Frederiksberg",
      "DK-101": "Kǿbenhavn",
      "DK-040": "Bornholm",
      "DK-020": "Frederiksborg",
      "DK-042": "Fyn",
      "DK-015": "Kǿbenhavn",
      "DK-080": "Nordjylland",
      "DK-055": "Ribe",
      "DK-065": "Ringkǿbing",
      "DK-025": "Roskilde",
      "DK-035": "Storstrǿm",
      "DK-050": "Sǿnderjylland",
      "DK-060": "Vejle",
      "DK-030": "Vestsjælland",
      "DK-076": "Viborg",
      "DK-070": "Århus"
    }
  },
  ES: {
    countryName: {
      en: "Spain",
      es: "España",
      fr: "Espagne",
      pl: "Hiszpania",
      jp: "スペイン",
      ar: "إسبانيا", // Arabic
      da: "Spanien", // Danish
      de: "Spanien", // German
      he: "סְפָרַד", // Hebrew
      it: "Spagna", // Italian
      nl: "Spanje", // Dutch
      ru: "Испания", // Russian
      sv: "Spanien"// Swedish
    },
    divisionName: 'Province',
    divisions: {
      "C": "A Coruña",
      "VI": "Álava",
      "AB": "Albacete",
      "A": "Alicante",
      "AL": "Almería",
      "O": "Asturias",
      "AV": "Ávila",
      "BA": "Badajoz",
      "B": "Barcelona",
      "BI": "Bizkaia",
      "BU": "Burgos",
      "CC": "Cáceres",
      "CA": "Cádiz",
      "S": "Cantabria",
      "CS": "Castellón",
      "CR": "Ciudad Real",
      "CO": "Córdoba",
      "CU": "Cuenca",
      "SS": "Gipuzkoa",
      "GI": "Girona",
      "GR": "Granada",
      "GU": "Guadalajara",
      "H": "Huelva",
      "HU": "Huesca",
      "PM": "Illes Balears",
      "J": "Jaén",
      "LO": "La Rioja",
      "GC": "Las Palmas",
      "LE": "León",
      "L": "Lleida",
      "LU": "Lugo",
      "M": "Madrid",
      "MA": "Málaga",
      "MU": "Murcia",
      "NA": "Navarra",
      "OR": "Ourense",
      "P": "Palencia",
      "PO": "Pontevedra",
      "SA": "Salamanca",
      "TF": "Santa Cruz de Tenerife",
      "SG": "Segovia",
      "SE": "Sevilla",
      "SO": "Soria",
      "T": "Tarragona",
      "TE": "Teruel",
      "TO": "Toledo",
      "V": "Valencia",
      "VA": "Valladolid",
      "ZA": "Zamora",
      "Z": "Zaragoza"
    },
    postalName: "Código postal"
  },
  FR: {
    countryName: {
      en: "France",
      ar: "فرنسا",
      da: "Frankrig",
      de: "Frankreich",
      es: "Francia",
      fr: "France",
      he: "צָרְפַת",
      it: "Francia",
      jp: "フランス",
      nl: "Francia",
      pl: "Francja",
      ru: "Франция",
      sv: "Frankrike"
    },
    divisionName: 'Department',
    divisions: {
      "01": "Ain",
      "02": "Aisne",
      "03": "Allier",
      "04": "Alpes-de-Haute-Provence",
      "05": "Hautes-Alpes",
      "06": "Alpes-Maritimes",
      "07": "Ardèche",
      "08": "Ardennes",
      "09": "Ariège",
      "10": "Aube",
      "11": "Aude",
      "12": "Aveyron",
      "13": "Bouches-du-Rhône",
      "14": "Calvados",
      "15": "Cantal",
      "16": "Charente",
      "17": "Charente-Maritime",
      "18": "Cher",
      "19": "Corrèze",
      "2A": "Corse-du-Sud",
      "2B": "Haute-Corse",
      "21": "Côte-d'Or",
      "22": "Côtes-d'Armor",
      "23": "Creuse",
      "24": "Dordogne",
      "25": "Doubs",
      "26": "Drôme",
      "27": "Eure",
      "28": "Eure-et-Loir",
      "29": "Finistère",
      "30": "Gard",
      "31": "Haute-Garonne",
      "32": "Gers",
      "33": "Gironde",
      "34": "Hérault",
      "35": "Ille-et-Vilaine",
      "36": "Indre",
      "37": "Indre-et-Loire",
      "38": "Isère",
      "39": "Jura",
      "40": "Landes",
      "41": "Loir-et-Cher",
      "42": "Loire",
      "43": "Haute-Loire",
      "44": "Loire-Atlantique",
      "45": "Loiret",
      "46": "Lot",
      "47": "Lot-et-Garonne",
      "48": "Lozère",
      "49": "Maine-et-Loire",
      "50": "Manche",
      "51": "Marne",
      "52": "Haute-Marne",
      "53": "Mayenne",
      "54": "Meurthe-et-Moselle",
      "55": "Meuse",
      "56": "Morbihan",
      "57": "Moselle",
      "58": "Nièvre",
      "59": "Nord",
      "60": "Oise",
      "61": "Orne",
      "62": "Pas-de-Calais",
      "63": "Puy-de-Dôme",
      "64": "Pyrénées-Atlantiques",
      "65": "Hautes-Pyrénées",
      "66": "Pyrénées-Orientales",
      "67": "Bas-Rhin",
      "68": "Haut-Rhin",
      "69": "Rhône[3]",
      "70": "Haute-Saône",
      "71": "Saône-et-Loire",
      "72": "Sarthe",
      "73": "Savoie",
      "74": "Haute-Savoie",
      "75": "Paris",
      "76": "Seine-Maritime",
      "77": "Seine-et-Marne",
      "78": "Yvelines",
      "79": "Deux-Sèvres",
      "80": "Somme",
      "81": "Tarn",
      "82": "Tarn-et-Garonne",
      "83": "Var",
      "84": "Vaucluse",
      "85": "Vendée",
      "86": "Vienne",
      "87": "Haute-Vienne",
      "88": "Vosges",
      "89": "Yonne",
      "90": "Territoire de Belfort",
      "91": "Essonne",
      "92": "Hauts-de-Seine",
      "93": "Seine-Saint-Denis",
      "94": "Val-de-Marne",
      "95": "Val-d'Oise",
      "GP": "Guadeloupe",
      "RE": "La Réunion",
      "YT": "Mayotte",
    },
    postalName: "Code postal"
  },
  GB: {
    countryName: {
      en: "United Kingdom",
      ar: "المملكة المتحدة", // Arabic
      da: "Det Forenede Kongerige", // Danish
      de: "Großbritannien", // German
      es: "Reino Unido", // Spanish
      fr: "Royaume-Uni", // French
      he: "הממלכה המאוחדת", // Hebrew
      it: "Regno Unito", // Italian
      jp: "イギリス", // Japanese
      nl: "Verenigd Koninkrijk", // Dutch
      pl: "Zjednoczone Królestwo", // Polish
      ru: "объединенное Королевство", // Russian
      sv: "Storbritannien"// Swedish
    },
    divisionName: "County/Province",
    divisions: {
      "GB-CHA": "Channel Islands",
      "GB-GSY": "Guernsey [Guernesey]",
      "GB-JSY": "Jersey",
      "GB-ENG": "England",
      "GB-BDG": "Barking and Dagenham",
      "GB-BNE": "Barnet",
      "GB-BNS": "Barnsley",
      "GB-BAS": "Bath and North East Somerset",
      "GB-BDF": "Bedfordshire",
      "GB-BEX": "Bexley",
      "GB-BIR": "Birmingham (City of)",
      "GB-BBD": "Blackburn with Darwen",
      "GB-BPL": "Blackpool",
      "GB-BOL": "Bolton",
      "GB-BMH": "Bournemouth",
      "GB-BRC": "Bracknell Forest",
      "GB-BRD": "Bradford (City of)",
      "GB-BEN": "Brent",
      "GB-BNH": "Brighton and Hove",
      "GB-BST": "Bristol, City of",
      "GB-BRY": "Bromley",
      "GB-BKM": "Buckinghamshire",
      "GB-BUR": "Bury",
      "GB-CLD": "Calderdale",
      "GB-CAM": "Cambridgeshire",
      "GB-CMD": "Camden",
      "GB-CHS": "Cheshire",
      "GB-CON": "Cornwall",
      "GB-COV": "Coventry (City of)",
      "GB-CRY": "Croydon",
      "GB-CMA": "Cumbria",
      "GB-DAL": "Darlington",
      "GB-DER": "Derby (City of)",
      "GB-DBY": "Derbyshire",
      "GB-DEV": "Devon",
      "GB-DNC": "Doncaster",
      "GB-DOR": "Dorset",
      "GB-DUD": "Dudley",
      "GB-DUR": "Durharn",
      "GB-EAL": "Ealing",
      "GB-ERY": "East Riding of Yorkshire",
      "GB-ESX": "East Sussex",
      "GB-ENF": "Enfield",
      "GB-ESS": "Essex",
      "GB-GAT": "Gateshead",
      "GB-GLS": "Gloucestershire",
      "GB-GRE": "Greenwich",
      "GB-HCK": "Hackney",
      "GB-HAL": "Haiton",
      "GB-HMF": "Hammersmith and Fulham",
      "GB-HAM": "Hampshire",
      "GB-HRY": "Haringey",
      "GB-HRW": "Harrow",
      "GB-HPL": "Hartlepool",
      "GB-HAV": "Havering",
      "GB-HEF": "Herefordshire, County of",
      "GB-HRT": "Hertfordshire",
      "GB-HIL": "Hillingdon",
      "GB-HNS": "Hounslow",
      "GB-IOW": "Isle of Wight",
      "GB-IOS": "Isles of Scilly",
      "GB-ISL": "Islington",
      "GB-KEC": "Kensington and Chelsea",
      "GB-KEN": "Kent",
      "GB-KHL": "Kingston upon Hull, City of",
      "GB-KTT": "Kingston upon Thames",
      "GB-KIR": "Kirklees",
      "GB-KWL": "Knowsley",
      "GB-LBH": "Lambeth",
      "GB-LAN": "Lancashire",
      "GB-LDS": "Leeds (City of)",
      "GB-LCE": "Leitester (City of)",
      "GB-LEC": "Leicestershire",
      "GB-LEW": "Lewisham",
      "GB-LIN": "Lincolnshire",
      "GB-LIV": "Liverpool (City of)",
      "GB-LND": "London, City of",
      "GB-LUT": "Luton",
      "GB-MAN": "Manchester (City of)",
      "GB-MDW": "Medway",
      "GB-MRT": "Merton",
      "GB-MDB": "Middlesbrough",
      "GB-MIK": "Milton Keynes",
      "GB-NET": "Newcastle upon Tyne (City of)",
      "GB-NWM": "Newham",
      "GB-NFK": "Norfolk",
      "GB-NEL": "North East Lincolnshire",
      "GB-NLN": "North Lincolnshire",
      "GB-NSM": "North Somerset",
      "GB-NTY": "North Tyneside",
      "GB-NYK": "North Yorkshire",
      "GB-NTH": "Northamptonshire",
      "GB-NBL": "Northumberland",
      "GB-NGM": "Nottingham (City of)",
      "GB-NTT": "Nottinghamshire",
      "GB-OLD": "Oldham",
      "GB-OXF": "Oxfordshire",
      "GB-PTE": "Peterborough",
      "GB-PLY": "Plymouth (City of)",
      "GB-POL": "Poole",
      "GB-POR": "Portsmouth (City of)",
      "GB-RDG": "Reading",
      "GB-RDB": "Redbridge",
      "GB-RCC": "Redcar and Cleveland",
      "GB-RIC": "Richmond upon Thames",
      "GB-RCH": "Rochdale",
      "GB-ROT": "Rotherharn",
      "GB-RUT": "Rutland",
      "GB-SHN": "St. Helens",
      "GB-SLF": "Salford (City of)",
      "GB-SAW": "Sandweil",
      "GB-SFT": "Sefton",
      "GB-SHF": "Sheffield (City of)",
      "GB-SHR": "Shropshire",
      "GB-SLG": "Slough",
      "GB-SOL": "Solihull",
      "GB-SOM": "Somerset",
      "GB-SGC": "South Gloucestershire",
      "GB-STY": "South Tyneside",
      "GB-STH": "Southampton (City of)",
      "GB-SOS": "Southend-on-Sea",
      "GB-SWK": "Southwark",
      "GB-STS": "Staffordshire",
      "GB-SKP": "Stockport",
      "GB-STT": "Stockton-On-Tees",
      "GB-STE": "Stoke-on-Trent (City of)",
      "GB-SFK": "Suffolk",
      "GB-SND": "Sunderland (City of)",
      "GB-SRY": "Surrey",
      "GB-STN": "Sutton",
      "GB-SWD": "Swindon",
      "GB-TAM": "Tameside",
      "GB-TFW": "Telford and Wrekin",
      "GB-THR": "Thurrock",
      "GB-TOB": "Torbay",
      "GB-TWH": "Tower Hamlets",
      "GB-TRF": "Trafford",
      "GB-WKF": "Wakefield (City of)",
      "GB-WLL": "Walsall",
      "GB-WFT": "Waltham Forest",
      "GB-WND": "Wandsworth",
      "GB-WRT": "Warrington",
      "GB-WAR": "Warwickshire",
      "GB-WBK": "West Berkshire",
      "GB-WSX": "West Sussex",
      "GB-WSM": "Westminster (City of)",
      "GB-WGN": "Wigan",
      "GB-WIL": "Wiltshire",
      "GB-WNM": "Windsor and Maidenhead",
      "GB-WRL": "Wirral",
      "GB-WOK": "Wokingham",
      "GB-WLV": "Wolverhampton",
      "GB-WOR": "Worcestershire",
      "GB-YOR": "York (City of)",
      "GB-IOM": "Isle of Man",
      "GB-NIR": "Northern Ireland",
      "GB-ANT": "Antrim",
      "GB-ARD": "Ards",
      "GB-ARM": "Armagh",
      "GB-BLA": "Ballymena",
      "GB-BLY": "Ballymoney",
      "GB-BNB": "Banbridge",
      "GB-BFS": "Belfast (City of)",
      "GB-CKF": "Carrickfergus",
      "GB-CSR": "Castlereagh",
      "GB-CLR": "Coleraine",
      "GB-CKT": "Cookstown",
      "GB-CGV": "Craigavon",
      "GB-DRY": "Derry (City of)",
      "GB-DOW": "Down",
      "GB-DGN": "Dungannon",
      "GB-FER": "Fermanagh",
      "GB-LRN": "Larne",
      "GB-LMV": "Limavady",
      "GB-LSB": "Lisburn",
      "GB-MFT": "Magherafelt",
      "GB-MYL": "Moyle",
      "GB-NYM": "Newry and Mourne",
      "GB-NTA": "Newtownabbey",
      "GB-NDN": "North Down",
      "GB-OMH": "Omagh",
      "GB-STB": "Strabane",
      "GB-SCT": "Scotland",
      "GB-ABE": "Aberdeen City",
      "GB-ABD": "Aberdeenshire",
      "GB-ANS": "Angus",
      "GB-AGB": "Argyll and Bute",
      "GB-CLK": "Clackmannanshire",
      "GB-DGY": "Dumfries and Galloway",
      "GB-DND": "Dundee City",
      "GB-EAY": "East Ayrshire",
      "GB-EDU": "East Dunbartonshire",
      "GB-ELN": "East Lothian",
      "GB-ERW": "East Renfrewshire",
      "GB-EDH": "Edinburgh, City of",
      "GB-ELS": "Eilean Siar",
      "GB-FAL": "Falkirk",
      "GB-FIF": "Fife",
      "GB-GLG": "Glasgow City",
      "GB-HLD": "Highland",
      "GB-IVC": "Inverclyde",
      "GB-MLN": "Midlothian",
      "GB-MRY": "Moray",
      "GB-NAY": "North Ayrshire",
      "GB-NLK": "North Lanarkshire",
      "GB-ORK": "Orkney Islands",
      "GB-PKN": "Perth and Kinross",
      "GB-RFW": "Renfrewshire",
      "GB-SCB": "Scottish Borders, The",
      "GB-ZET": "Shetland Islands",
      "GB-SAY": "South Ayrshire",
      "GB-SLK": "South Lanarkshire",
      "GB-STG": "Stirling",
      "GB-WDU": "West Dunbartonshire",
      "GB-WLN": "West Lothian",
      "GB-WLS": "Wales [Cymru]",
      "GB-BGW": "Blaenau Gwent",
      "GB-BGE": "Bridgend [Pen-y-bont ar Ogwr GB-POG]",
      "GB-CAY": "Caerphilly [Caerffili GB-CAF]",
      "GB-CRF": "Cardiff (City of) [Caerdydd GB-CRD]",
      "GB-CMN": "Carmarthenshire [Sir Gaerfyrddin GB-GFY]",
      "GB-CGN": "Ceredigion [Sir Ceredigion]",
      "GB-CWY": "Conwy",
      "GB-DEN": "Denbighshire [Sir Ddinbych GB-DDB]",
      "GB-FLN": "Flintshire [Sir y Fflint GB-FFL]",
      "GB-GWN": "Gwynedd",
      "GB-AGY": "Isle of Anglesey [Sir Ynys Man GB-YNM]",
      "GB-MTY": "Merthyr Tydfil [Merthyr Tudful GB-MTU]",
      "GB-MON": "Monmouthshire [Sir Fynwy GB-FYN]",
      "GB-NTL": "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
      "GB-NWP": "Newport [Casnewydd GB-CNW]",
      "GB-PEM": "Pembrokeshire [Sir Benfro CB-BNF]",
      "GB-POW": "Powys",
      "GB-RCT": "Rhondda, Cynon, Taff [Rhondda, Cynon, Taf]",
      "GB-SWA": "Swansea (City of) [Abertawe GB-ATA]",
      "GB-TOF": "Torfaen [Tor-faen]",
      "GB-VGL": "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]",
      "GB-WRX": "Wrexham [Wrecsam GB-WRC]"
    }
  },
  PL: {
    countryName: {
      en: "Poland",
      ar: "بولندا",
      da: "Polen",
      de: "Polen",
      es: "Polonia",
      fr: "Pologne",
      he: "פּוֹלִין",
      it: "Polonia",
      jp: "ポーランド",
      nl: "Polen",
      pl: "Polska",
      ru: "Польша",
      sv: "polen"
    },
    divisionName: 'Voivodeship',
    divisions: {
      "02": 'Dolnośląskie',
      "04": 'Kujawsko-pomorskie',
      "06": 'Lubelskie',
      "08": 'Lubuskie',
      "10": 'Łódzkie',
      "12": 'Małopolskie',
      "14": 'Mazowieckie',
      "16": 'Opolskie Opole',
      "18": 'Podkarpackie',
      "20": 'Podlaskie',
      "22": 'Pomorskie',
      "24": 'Śląskie',
      "26": 'Świętokrzyskie',
      "28": 'Warmińsko-mazurskie',
      "30": 'Wielkopolskie',
      "32": 'Zachodniopomorskie',
    },
    postalName: "Kod pocztowy"
  },
  IE: {
    countryName: {
      en: "Ireland",
      ar: "أيرلندا", // Arabic
      da: "Irland", // Danish
      de: "Irland", // German
      es: "Irlanda", // Spanish
      fr: "Irlande", // French
      he: "אירלנד", // Hebrew
      it: "Irlanda", // Italian
      jp: "アイルランド", // Japanese
      nl: "Ierland", // Dutch
      pl: "Irlandia", // Polish
      ru: "Ирландия", // Russian
      sv: "irland"// Swedish
    },
    divisionName: "County/Province",
    divisions: {
      "IE-CP": "Connaught (Connacht)",
      "IE-G": "Galway (Gaillimh)",
      "IE-LM": "Leitrim (Liatroim)",
      "IE-MO": "Mayo (Maigh Eo)",
      "IE-RN": "Roscommon (Ros Comáin)",
      "IE-SO": "Sligo (Sligeach)",
      "IE-LP": "Leinster (Laighin)",
      "IE-CW": "Carlow (Ceatharlach)",
      "IE-D": "Dublin (Baile Átha Cliath)",
      "IE-KE": "Kildare (Cill Dara)",
      "IE-KK": "Kilkenny (Cill Chainnigh)",
      "IE-LS": "Laois (Laois)",
      "IE-LD": "Longford (An Longfort)",
      "IE-LH": "Louth (Lú)",
      "IE-MH": "Meath (An Mhí)",
      "IE-OY": "Offaly (Uíbh Fhailí)",
      "IE-WH": "Westmeath (An Iarmhí)",
      "IE-WX": "Wexford (Loch Garman)",
      "IE-WW": "Wicklow (Cill Mhantáin)",
      "IE-M": "Munster (An Mhumhain)",
      "IE-UP": "Ulster (Ulaidh)",
      "IE-CN": "Cavan (An Cabhán)",
      "IE-DL": "Donegal (Dún na nGall)",
      "IE-MN": "Monaghan (Muineachán)"
    }
  },
  IL: {
    countryName: {
      en: "Israel",
      ar: "إسرائيل", // Arabic
      da: "Israel", // Danish
      de: "Israel", // German
      es: "Israel", // Spanish
      fr: "Israël", // French
      he: "ישראל", // Hebrew
      it: "Israele", // Italian
      jp: "イスラエル", // Japanese
      nl: "Israël", // Dutch
      pl: "Izrael", // Polish
      ru: "Израиль", // Russian
      sv: "Israel"// Swedish
    },
    divisionName: "מָחוֹז‎ / محافظات إسرائيل",
    divisions: {
      "IL-D": "מחוז הדרום	(HaDarom) / لواء الجنوب (El Janūbī)",
      "IL-M": "מָחוֹז הַמֶרְכָּז (HaMerkaz) / المنطقة الوسطى (El Awsat)",
      "IL-2": "מחוז הצפון (HaZafon) /	منطقة الشمال (Esh Shamālī)",
      "IL-HA": "מחוז חיפה (Hefa) / منطقة حيفا (Heifā)",
      "IL-TA": "מָחוֹז תֵּל אָבִיב (Tel-Aviv) /	منطقة تل أبيب (Tell Abīb)",
      "IL-JM": "מחוז ירושלים (Yerushalayim) / منطقة القدس (Al Quds)"
    }
  },
  IT: {
    countryName: {
      en: "Italy",
      ar: "إيطاليا", // Arabic
      da: "Italien", // Danish
      de: "Italien", // German
      es: "Italia", // Spanish
      fr: "Italie", // French
      he: "אִיטַלִיָה", // Hebrew
      it: "Italia", // Italian
      jp: "イタリア", // Japanese
      nl: "Italië", // Dutch
      pl: "Włochy", // Polish
      ru: "Италия", // Russian
      sv: "Italien"// Swedish
    },
    divisionName: "Province",
    divisions: {
      "IT-65": "Abruzzo",
      "IT-CH": "Chieti",
      "IT-AQ": "L'Aquila",
      "IT-PE": "Pescara",
      "IT-TE": "Teramo",
      "IT-77": "Basilicata",
      "IT-MT": "Matera",
      "IT-PZ": "Potenza",
      "IT-78": "Calabria",
      "IT-CZ": "Catanzaro",
      "IT-CS": "Cosenza",
      "IT-KR": "Crotone",
      "IT-RC": "Reggio Calabria",
      "IT-W": "Vibo Valentia",
      "IT-72": "Campania",
      "IT-AV": "Avellino",
      "IT-BN": "Benevento",
      "IT-CE": "Caserta",
      "IT-NA": "Napoli",
      "IT-SA": "Salerno",
      "IT-45": "Emilia-Romagna",
      "IT-BO": "Bologna",
      "IT-FE": "Ferrara",
      "IT-FO": "Forlì",
      "IT-MO": "Modena",
      "IT-PR": "Parma",
      "IT-PC": "Piacenza",
      "IT-RA": "Ravenna",
      "IT-RE": "Reggio Emilia",
      "IT-RN": "Rimini",
      "IT-36": "Friuli-Venezia Giulia",
      "IT-GO": "Gorizia",
      "IT-PN": "Pordenone",
      "IT-TS": "Trieste",
      "IT-UD": "Udine",
      "IT-62": "Lazio",
      "IT-FR": "Frosinone",
      "IT-LT": "Latina",
      "IT-RI": "Rieti",
      "IT-RM": "Roma",
      "IT-VT": "Viterbo",
      "IT-42": "Liguria",
      "IT-GE": "Genova",
      "IT-IM": "Imperia",
      "IT-SP": "La Spezia",
      "IT-SV": "Savona",
      "IT-25": "Lombardia",
      "IT-BG": "Bergamo",
      "IT-BS": "Brescia",
      "IT-CO": "Como",
      "IT-CR": "Cremona",
      "IT-LC": "Lecco",
      "IT-LO": "Lodi",
      "IT-MN": "Mantova",
      "IT-MI": "Milano",
      "IT-PV": "Pavia",
      "IT-SO": "Sondrio",
      "IT-VA": "Varese",
      "IT-57": "Marche",
      "IT-AN": "Ancona",
      "IT-AP": "Ascoli Piceno",
      "IT-MC": "Macerata",
      "IT-PS": "Pesaro",
      "IT-67": "Molise",
      "IT-CB": "Campobasso",
      "IT-IS": "Isernia",
      "IT-21": "Piemonte",
      "IT-AL": "Alessandria",
      "IT-AT": "Asti",
      "IT-BI": "Biella",
      "IT-CN": "Cuneo",
      "IT-NO": "Novara",
      "IT-TO": "Torino",
      "IT-VB": "Verbano-Cusio-Ossola",
      "IT-VC": "Vercelli",
      "IT-75": "Puglia",
      "IT-BA": "Bari",
      "IT-BR": "Brindisi",
      "IT-FG": "Foggia",
      "IT-LE": "Lecce",
      "IT-TA": "Taranto",
      "IT-88": "Sardegna",
      "IT-CA": "Cagliari",
      "IT-NU": "Nuoro",
      "IT-OR": "Oristano",
      "IT-SS": "Sassari",
      "IT-82": "Sicilia",
      "IT-AG": "Agrigento",
      "IT-CL": "Caltanissetta",
      "IT-CT": "Catania",
      "IT-EN": "Enna",
      "IT-ME": "Mesaina",
      "IT-PA": "Palermo",
      "IT-RG": "Ragusa",
      "IT-SR": "Siracusa",
      "IT-TP": "Trapani",
      "IT-52": "Toscana",
      "IT-AR": "Arezzo",
      "IT-FI": "Firenze",
      "IT-GR": "Grosseto",
      "IT-LI": "Livorno",
      "IT-LU": "Lucca",
      "IT-MS": "Massa",
      "IT-PI": "Pisa",
      "IT-PT": "Pistoia",
      "IT-PO": "Prato",
      "IT-SI": "Siena",
      "IT-32": "Trentino-Alte Adige (Trentino-Südtirol)",
      "IT-BZ": "Bolzano (Bozen)",
      "IT-TN": "Trento",
      "IT-55": "Umbria",
      "IT-PG": "Perugia",
      "IT-TR": "Terni",
      "IT-23": "Valle d'Aosta (Vallée d'Aoste)",
      "IT-AO": "Aosta (Aoste)",
      "IT-34": "Veneto",
      "IT-BL": "Belluno",
      "IT-PD": "Padova",
      "IT-RO": "Rovigo",
      "IT-TV": "Treviso",
      "IT-VE": "Venezia",
      "IT-VR": "Verona",
      "IT-VI": "Vicenza"
    }
  },
  JP: {
    countryName: {
      en: "Japan",
      ar: "اليابان",
      da: "Japan",
      de: "Japan",
      es: "Japón",
      fr: "Japon",
      he: "יפן",
      it: "Giappone",
      jp: "日本",
      nl: "Japan",
      pl: "Japonia",
      ru: "Япония",
      sv: "Japan"
    },
    divisionName: '都道府県',
    divisions: {
      "JP-24": '三重県 (Mie)',
      "JP-26": '京都府 (Kyoto)',
      "JP-41": '佐賀県 (Saga)',
      "JP-28": '兵庫県 (Hyogo)',
      "JP-01": '北海道 (Hokkaido)',
      "JP-12": '千葉県 (Chiba)',
      "JP-30": '和歌山県 (Wakayama)',
      "JP-11": '埼玉県 (Saitama)',
      "JP-44": '大分県 (Oita)',
      "JP-27": '大阪府 (Osaka)',
      "JP-29": '奈良県 (Nara)',
      "JP-04": '宮城県 (Miyagi)',
      "JP-45": '宮崎県 (Miyazaki)',
      "JP-16": '富山県 (Toyama)',
      "JP-35": '山口県 (Yamaguchi)',
      "JP-06": '山形県 (Yamagata)',
      "JP-19": '山梨県 (Yamanashi)',
      "JP-21": '岐阜県 (Gifu)',
      "JP-33": '岡山県 (Okayama)',
      "JP-03": '岩手県 (Iwate)',
      "JP-32": '島根県 (Shimane)',
      "JP-34": '広島県 (Hiroshima)',
      "JP-36": '徳島県 (Tokushima)',
      "JP-38": '愛媛県 (Ehime)',
      "JP-23": '愛知県 (Aichi)',
      "JP-15": '新潟県 (Niigata)',
      "JP-13": '東京都 (Tokyo)',
      "JP-09": '栃木県 (Tochigi)',
      "JP-47": '沖縄県 (Okinawa)',
      "JP-25": '滋賀県 (Shiga)',
      "JP-43": '熊本県 (Kumamoto)',
      "JP-17": '石川県 (Ishikawa)',
      "JP-14": '神奈川県 (Kanagawa)',
      "JP-18": '福井県 (Fukui)',
      "JP-40": '福岡県 (Fukuoka)',
      "JP-07": '福島県 (Fukushima)',
      "JP-05": '秋田県 (Akita)',
      "JP-10": '群馬県 (Gunma)',
      "JP-08": '茨城県 (Ibaraki)',
      "JP-42": '長崎県 (Nagasaki)',
      "JP-20": '長野県 (Nagano)',
      "JP-02": '青森県 (Aomori)',
      "JP-22": '静岡県 (Shizuoka)',
      "JP-37": '香川県 (Kagawa)',
      "JP-39": '高知県 (Kochi)',
      "JP-31": '鳥取県 (Tottori)',
      "JP-46": '鹿児島県 (Kagoshima)',
    }
  },
  SE: {
    countryName: {
      en: "Sweden",
      ar: "السويد", // Arabic
      da: "Sverige", // Danish
      de: "Schweden", // German
      es: "Suecia", // Spanish
      fr: "Suède", // French
      he: "שבדיה", // Hebrew
      it: "Svezia", // Italian
      jp: "スウェーデン", // Japanese
      nl: "Zweden", // Dutch
      pl: "Szwecja", // Polish
      ru: "Швеция", // Russian
      sv: "Sverige"// Swedish
    },
    divisionName: "Län",
    divisions: {
      "SE-K": "Blekinge län",
      "SE-W": "Dalarnas län",
      "SE-I": "Gotlands län",
      "SE-X": "Gävleborgs län",
      "SE-N": "Hallands län",
      "SE-Z": "Jämtlands län",
      "SE-F": "Jönköpings län",
      "SE-H": "Kalmar län",
      "SE-G": "Kronobergs län",
      "SE-BD": "Norrbottens län",
      "SE-M": "Skåne län",
      "SE-AB": "Stockholms län",
      "SE-D": "Södermanlands län",
      "SE-C": "Uppsala län",
      "SE-S": "Värmlands län",
      "SE-AC": "Västerbottens län",
      "SE-Y": "Västernorrlands län",
      "SE-U": "Västmanlands län",
      "SE-O": "Västra Götalands län",
      "SE-T": "Örebro län",
      "SE-E": "Östergötlands län"
    }
  },
  CH: {
    countryName: {
      en: "Switzerland",
      ar: "سويسرا", // Arabic
      da: "Schweiz", // Danish
      de: "Schweiz", // German
      es: "Suiza", // Spanish
      fr: "Suisse", // French
      he: "שוויץ", // Hebrew
      it: "Svizzero", // Italian
      jp: "スイス", // Japanese
      nl: "Zwitserland", // Dutch
      pl: "Szwajcaria", // Polish
      ru: "Швейцария", // Russian
      sv: "schweiz", // Swedish
    },
    divisionName: "Canton/Kanton/Cantone",
    divisions: {
      "CH-AG": "Aargau",
      "CH-AR": "Appenzell Ausser-Rhoden",
      "CH-AI": "Appenzell Inner-Rhoden",
      "CH-BL": "Basel-Landschaft",
      "CH-BS": "Basel-Stadt",
      "CH-BE": "Bern",
      "CH-FR": "Freiburg",
      "CH-GE": "Geneve",
      "CH-GL": "Glarus",
      "CH-GR": "Graubünden",
      "CH-JU": "Jura",
      "CH-LU": "Luzern",
      "CH-NE": "Neuchatel",
      "CH-NW": "Nidwalden",
      "CH-OW": "Obwalden",
      "CH-SG": "Sankt Gallen",
      "CH-SH": "Schaffhausen",
      "CH-SZ": "Schwyz",
      "CH-SO": "Solothurn",
      "CH-TG": "Thurgau",
      "CH-TI": "Ticino",
      "CH-UR": "Uri",
      "CH-VS": "Wallis",
      "CH-VD": "Vaud",
      "CH-ZG": "Zug",
      "CH-ZH": "Zürich"
    }
  },
  BG: {
    countryName: {
      en: "Bulgaria",
      ar: "بلغاريا", // Arabic
      da: "Bulgarien", // Danish
      de: "Bulgarien", // German
      es: "Bulgaria", // Spanish
      fr: "Bulgarie", // French
      he: "בולגריה", // Hebrew
      it: "Bulgaria", // Italian
      jp: "ブルガリア", // Japanese
      nl: "Bulgarije", // Dutch
      pl: "Bułgaria", // Polish
      ru: "Болгария", // Russian
      sv: "Bulgarien", // Swedish
    },
    divisionName: "Province",
    divisions: {
      "BG-01": "Blagoevgrad",
      "BG-02": "Burgas",
      "BG-08": "Dobrich",
      "BG-07": "Gabrovo",
      "BG-26": "Haskovo",
      "BG-09": "Kardzhali",
      "BG-10": "Kyustendil",
      "BG-11": "Lovech",
      "BG-12": "Montana",
      "BG-13": "Pazardzhik",
      "BG-14": "Pernik",
      "BG-15": "Pleven",
      "BG-16": "Plovdiv",
      "BG-17": "Razgrad",
      "BG-18": "Ruse",
      "BG-27": "Shumen",
      "BG-19": "Silistra",
      "BG-20": "Sliven",
      "BG-21": "Smolyan",
      "BG-23": "Sofia",
      "BG-22": "Sofia",
      "BG-24": "Stara",
      "BG-25": "Targovishte",
      "BG-03": "Varna",
      "BG-04": "Veliko",
      "BG-05": "Vidin",
      "BG-06": "Vratsa",
      "BG-28": "Yambol",
    }
  },
  NL: {
    countryName: {
      en: "The Netherlands",
      ar: "هولندا", // Arabic
      da: "Holland", // Danish
      de: "Niederlande", // German
      es: "Países Bajos", // Spanish
      fr: "Pays-Bas", // French
      he: "הולנד", // Hebrew
      it: "Paesi Bassi", // Italian
      jp: "オランダ", // Japanese
      nl: "Nederland", // Dutch
      pl: "Niderlandy", // Polish
      ru: "Нидерланды", // Russian
      sv: "Nederländerna", // Swedish
    },
    divisionName: "Province",
    divisions: {
      "NL-DR": "Drenthe",
      "NL-FL": "Flevoland",
      "NL-FR": "Fryslân (fy)",
      "NL-GE": "Gelderland",
      "NL-GR": "Groningen",
      "NL-LI": "Limburg",
      "NL-NB": "Noord-Brabant",
      "NL-NH": "Noord-Holland",
      "NL-OV": "Overijssel",
      "NL-UT": "Utrecht",
      "NL-ZE": "Zeeland",
      "NL-ZH": "Zuid-Holland"
    }
  },
  TR: {
    countryName: {
      en: "Turkey",
      ar: "تركيا", // Arabic
      da: "Tyrkiet", // Danish
      de: "Türkei", // German
      es: "Turquía", // Spanish
      fr: "Turquie", // French
      he: "טורקיה", // Hebrew
      it: "Turchia", // Italian
      jp: "トルコ", // Japanese
      nl: "Turkije", // Dutch
      pl: "Turcja", // Polish
      ru: "Турция", // Russian
      sv: "Turkiet", // Swedish
    },
    divisionName: "Province",
    divisions: {
      "TR-01": "Adana",
      "TR-02": "Adıyaman",
      "TR-03": "Afyonkarahisar",
      "TR-04": "Ağrı",
      "TR-05": "Amasya",
      "TR-06": "Ankara",
      "TR-07": "Antalya",
      "TR-08": "Artvin",
      "TR-09": "Aydın",
      "TR-10": "Balıkesir",
      "TR-11": "Bilecik",
      "TR-12": "Bingöl",
      "TR-13": "Bitlis",
      "TR-14": "Bolu",
      "TR-15": "Burdur",
      "TR-16": "Bursa",
      "TR-17": "Çanakkale",
      "TR-18": "Çankırı",
      "TR-19": "Çorum",
      "TR-20": "Denizli",
      "TR-21": "Diyarbakır",
      "TR-22": "Edirne",
      "TR-23": "Elazığ",
      "TR-24": "Erzincan",
      "TR-25": "Erzurum",
      "TR-26": "Eskişehir",
      "TR-27": "Gaziantep",
      "TR-28": "Giresun",
      "TR-29": "Gümüşhane",
      "TR-30": "Hakkâri",
      "TR-31": "Hatay",
      "TR-32": "Isparta",
      "TR-33": "Mersin",
      "TR-34": "İstanbul",
      "TR-35": "İzmir",
      "TR-36": "Kars",
      "TR-37": "Kastamonu",
      "TR-38": "Kayseri",
      "TR-39": "Kırklareli",
      "TR-40": "Kırşehir",
      "TR-41": "Kocaeli",
      "TR-42": "Konya",
      "TR-43": "Kütahya",
      "TR-44": "Malatya",
      "TR-45": "Manisa",
      "TR-46": "Kahramanmaraş",
      "TR-47": "Mardin",
      "TR-48": "Muğla",
      "TR-49": "Muş",
      "TR-50": "Nevşehir",
      "TR-51": "Niğde",
      "TR-52": "Ordu",
      "TR-53": "Rize",
      "TR-54": "Sakarya",
      "TR-55": "Samsun",
      "TR-56": "Siirt",
      "TR-57": "Sinop",
      "TR-58": "Sivas",
      "TR-59": "Tekirdağ",
      "TR-60": "Tokat",
      "TR-61": "Trabzon",
      "TR-62": "Tunceli",
      "TR-63": "Şanlıurfa",
      "TR-64": "Uşak",
      "TR-65": "Van",
      "TR-66": "Yozgat",
      "TR-67": "Zonguldak",
      "TR-68": "Aksaray",
      "TR-69": "Bayburt",
      "TR-70": "Karaman",
      "TR-71": "Kırıkkale",
      "TR-72": "Batman",
      "TR-73": "Şırnak",
      "TR-74": "Bartın",
      "TR-75": "Ardahan",
      "TR-76": "Iğdır",
      "TR-77": "Yalova",
      "TR-78": "Karabük",
      "TR-79": "Kilis",
      "TR-80": "Osmaniye",
      "TR-81": "Düzce",
    }
  },
  ZA: {
    countryName: {
      en: "South Africa",
      ar: "جنوب أفريقيا", // Arabic
      da: "Sydafrika", // Danish
      de: "Südafrika", // German
      es: "Sudáfrica", // Spanish
      fr: "Afrique du Sud", // French
      he: "דרום אפריקה", // Hebrew
      it: "Sud Africa", // Italian
      jp: "南アフリカ", // Japanese
      nl: "Zuid-Afrika", // Dutch
      pl: "Afryka Południowa", // Polish
      ru: "Южноафриканская Республика", // Russian
      sv: "Sydafrika", // Swedish
    },
    divisionName: "Province",
    divisions: {
      "ZA-EC": "Eastern Cape",
      "ZA-FS": "Free State",
      "ZA-GP": "Gauteng",
      "ZA-KZN": "Kwazulu-Natal",
      "ZA-LP": "Limpopo",
      "ZA-MP": "Mpumalanga",
      "ZA-NW": "North West",
      "ZA-NC": "Northern Cape",
      "ZA-WC": "Western Cape",
    }
  },
  MX: {
    countryName: {
      en: "Mexico",
      ar: "المكسيك", // Arabic
      da: "Mexico", // Danish
      de: "Mexiko", // German
      es: "México", // Spanish
      fr: "Mexique", // French
      he: "מקסיקו", // Hebrew
      it: "Messico", // Italian
      jp: "メキシコ", // Japanese
      nl: "Mexico", // Dutch
      pl: "Meksyk", // Polish
      ru: "Meкcикa", // Russian
      sv: "Mexiko", // Swedish
    },
    divisionName: "State",
    divisions: {
      "MX-CMX": "Ciudad de México",
      "MX-AGU": "Aguascalientes",
      "MX-BCN": "Baja California",
      "MX-BCS": "Baja California Sur",
      "MX-CAM": "Campeche",
      "MX-COA": "Coahuila de Zaragoza",
      "MX-COL": "Colima",
      "MX-CHP": "Chiapas",
      "MX-CHH": "Chihuahua",
      "MX-DUR": "Durango",
      "MX-GUA": "Guanajuato",
      "MX-GRO": "Guerrero",
      "MX-HID": "Hidalgo",
      "MX-JAL": "Jalisco",
      "MX-MEX": "México",
      "MX-MIC": "Michoacán de Ocampo",
      "MX-MOR": "Morelos",
      "MX-NAY": "Nayarit",
      "MX-NLE": "Nuevo León",
      "MX-OAX": "Oaxaca",
      "MX-PUE": "Puebla",
      "MX-QUE": "Querétaro",
      "MX-ROO": "Quintana Roo",
      "MX-SLP": "San Luis Potosí",
      "MX-SIN": "Sinaloa",
      "MX-SON": "Sonora",
      "MX-TAB": "Tabasco",
      "MX-TAM": "Tamaulipas",
      "MX-TLA": "Tlaxcala",
      "MX-VER": "Veracruz de Ignacio de la Llave",
      "MX-YUC": "Yucatán",
      "MX-ZAC": "Zacatecas",
    }
  },
  AR: {
    countryName: {
      en: "Argentina",
      ar: "الأرجنتين", // Arabic
      da: "Argentina", // Danish
      de: "Argentinien", // German
      es: "Argentina", // Spanish
      fr: "Argentine", // French
      he: "ארגנטינה", // Hebrew
      it: "Argentina", // Italian
      jp: "アルゼンチン", // Japanese
      nl: "Argentinië", // Dutch
      pl: "Argentyna", // Polish
      ru: "Argentina", // Russian
      sv: "Argentina", // Swedish
    },
    divisionName: "Province",
    divisions: {
      "AR-C": "Ciudad Autónoma de Buenos Aires",
      "AR-B": "Buenos Aires",
      "AR-K": "Catamarca",
      "AR-H": "Chaco",
      "AR-U": "Chubut",
      "AR-X": "Córdoba",
      "AR-W": "Corrientes",
      "AR-E": "Entre Ríos",
      "AR-P": "Formosa",
      "AR-Y": "Jujuy",
      "AR-L": "La Pampa",
      "AR-F": "La Rioja",
      "AR-M": "Mendoza",
      "AR-N": "Misiones",
      "AR-Q": "Neuquén",
      "AR-R": "Río Negro",
      "AR-A": "Salta",
      "AR-J": "San Juan",
      "AR-D": "San Luis",
      "AR-Z": "Santa Cruz",
      "AR-S": "Santa Fe",
      "AR-G": "Santiago del Estero",
      "AR-V": "Tierra del Fuego",
      "AR-T": "Tucumán"
    }
  },
  // MORE COUNTRIES STARTING HERE
  BR: {
    countryName: {
      en: "Brazil",
      ar: "البرازيل", // Arabic
      da: "Brasilien", // Danish
      de: "Brasilien", // German
      es: "Brasil", // Spanish
      fr: "Brésil", // French
      he: "בְּרָזִיל", // Hebrew
      it: "Brasile", // Italian
      jp: "ブラジル", // Japanese
      nl: "Brazilië", // Dutch
      pl: "Brazylia", // Polish
      ru: "Бразилия", // Russian
      sv: "Brasilien", // Swedish
    },
    divisionName: "State",
    divisions: {
      "BR-AC": "Acre",
      "BR-AL": "Alagoas",
      "BR-AP": "Amapá",
      "BR-AM": "Amazonas",
      "BR-BA": "Bahia",
      "BR-CE": "Ceará",
      "BR-DF": "Distrito Federal",
      "BR-ES": "Espírito Santo",
      "BR-GO": "Goiás",
      "BR-MA": "Maranhão",
      "BR-MT": "Mato Grosso",
      "BR-MS": "Mato Grosso do Sul",
      "BR-MG": "Minas Gerais",
      "BR-PA": "Pará",
      "BR-PB": "Paraíba",
      "BR-PR": "Paraná",
      "BR-PE": "Pernambuco",
      "BR-PI": "Piauí",
      "BR-RJ": "Rio de Janeiro",
      "BR-RN": "Rio Grande do Norte",
      "BR-RS": "Rio Grande do Sul",
      "BR-RO": "Rondônia",
      "BR-RR": "Roraima",
      "BR-SC": "Santa Catarina",
      "BR-SP": "São Paulo",
      "BR-SE": "Sergipe",
      "BR-TO": "Tocantins"
    }
  },
  IN: {
    countryName: {
      en: "India",
      ar: "الهند", // Arabic
      da: "Indien", // Danish
      de: "Indien", // German
      es: "India", // Spanish
      fr: "Inde", // French
      he: "הוֹדוּ", // Hebrew
      it: "India", // Italian
      jp: "インド", // Japanese
      nl: "Indië", // Dutch
      pl: "Indie", // Polish
      ru: "Индия", // Russian
      sv: "Indien", // Swedish
    },
    divisionName: "State",
    divisions: {
      "IN-AP": "Andhra Pradesh",
      "IN-AR": "Arunachal Pradesh",
      "IN-AS": "Assam",
      "IN-BR": "Bihar",
      "IN-CG": "Chhattisgarh",
      "IN-GA": "Goa",
      "IN-GJ": "Gujarat",
      "IN-HR": "Haryana",
      "IN-HP": "Himachal Pradesh",
      "IN-JH": "Jharkhand",
      "IN-KA": "Karnataka",
      "IN-KL": "Kerala",
      "IN-MP": "Madhya Pradesh",
      "IN-MH": "Maharashtra",
      "IN-MN": "Manipur",
      "IN-ML": "Meghalaya",
      "IN-MZ": "Mizoram",
      "IN-NL": "Nagaland",
      "IN-OD": "Odisha",
      "IN-PB": "Punjab",
      "IN-RJ": "Rajasthan",
      "IN-SK": "Sikkim",
      "IN-TN": "Tamil Nadu",
      "IN-TS": "Telangana",
      "IN-TR": "Tripura",
      "IN-UP": "Uttar Pradesh",
      "IN-UK": "Uttarakhand",
      "IN-WB": "West Bengal",
      "IN-AN": "Andaman and Nicobar Islands",
      "IN-CH": "Chandigarh",
      "IN-DD": "Dadra and Nagar Haveli and Daman and Diu",
      "IN-DL": "Delhi",
      "IN-JK": "Jammu and Kashmir",
      "IN-LA": "Ladakh",
      "IN-LD": "Lakshadweep",
      "IN-PY": "Puducherry",
    }
  },
  MY: {
    countryName: {
      en: "Malaysia",
      ar: "ماليزيا", // Arabic
      da: "Malaysia", // Danish
      de: "Malaysia", // German
      es: "Malasia", // Spanish
      fr: "Malaisie", // French
      he: "מלזיה", // Hebrew
      it: "Malaysia", // Italian
      jp: "マレーシア", // Japanese
      nl: "Maleisië", // Dutch
      pl: "Malezja", // Polish
      ru: "Малайзия", // Russian
      sv: "Malaysia", // Swedish
    },
    divisionName: "State",
    divisions: {
      "MY-01": "Johor",
      "MY-02": "Kedah",
      "MY-03": "Kelantan",
      "MY-04": "Malacca",
      "MY-05": "Negeri Sembilan",
      "MY-06": "Pahang",
      "MY-07": "Penang",
      "MY-08": "Perak",
      "MY-09": "Perlis",
      "MY-10": "Selangor",
      "MY-11": "Terengganu",
      "MY-12": "Sabah",
      "MY-13": "Sarawak",
      "MY-14": "Kuala Lumpur",
      "MY-15": "Labuan",
      "MY-16": "Putrajaya"
    }
  },
  PH: {
    countryName: {
      en: "Philippines",
      ar: "فيلبيني", // Arabic
      da: "Filippinerne", // Danish
      de: "Philippinen", // German
      es: "Filipinas", // Spanish
      fr: "Philippines", // French
      he: "פיליפינים", // Hebrew
      it: "Filippini", // Italian
      jp: "フィリピン", // Japanese
      nl: "Filippijnen", // Dutch
      pl: "Filipiny", // Polish
      ru: "Филиппины", // Russian
      sv: "Filippinerna", // Swedish
    },
    divisionName: "Province",
    divisions: {
      "PH-ABR": "Abra",
      "PH-AGN": "Agusan del Norte",
      "PH-AGS": "Agusan del Sur",
      "PH-AKL": "Aklan",
      "PH-ALB": "Albay",
      "PH-ANT": "Antique",
      "PH-APA": "Apayao",
      "PH-AUR": "Aurora",
      "PH-BAS": "Basilan",
      "PH-BAN": "Bataan",
      "PH-BTN": "Batanes",
      "PH-BTG": "Batangas",
      "PH-BEN": "Benguet",
      "PH-BIL": "Biliran",
      "PH-BOH": "Bohol",
      "PH-BUK": "Bukidnon",
      "PH-BUL": "Bulacan",
      "PH-CAG": "Cagayan",
      "PH-CAN": "Camarines Norte",
      "PH-CAS": "Camarines Sur",
      "PH-CAM": "Camiguin",
      "PH-CAP": "Capiz",
      "PH-CAT": "Catanduanes",
      "PH-CAV": "Cavite",
      "PH-CEB": "Cebu",
      "PH-NCO": "Cotabato",
      "PH-COM": "Davao de Oro",
      "PH-DAV": "Davao del Norte",
      "PH-DAS": "Davao del Sur",
      "PH-DVO": "Davao Occidental",
      "PH-DAO": "Davao Oriental",
      "PH-DIN": "Dinagat Islands",
      "PH-EAS": "Eastern Samar",
      "PH-GUI": "Guimaras",
      "PH-IFU": "Ifugao",
      "PH-ILN": "Ilocos Norte",
      "PH-ILS": "Ilocos Sur",
      "PH-ILI": "Iloilo",
      "PH-ISA": "Isabela",
      "PH-KAL": "Kalinga",
      "PH-LUN": "La Union",
      "PH-LAG": "Laguna",
      "PH-LAN": "Lanao del Norte",
      "PH-LAS": "Lanao del Sur",
      "PH-LEY": "Leyte",
      "PH-MDN": "Maguindanao del Norte",
      "PH-MDS": "Maguindanao del Sur",
      "PH-MAD": "Marinduque",
      "PH-MAS": "Masbate",
      "PH-MSC": "Misamis Occidental",
      "PH-MSR": "Misamis Oriental",
      "PH-MOU": "Mountain Province",
      "PH-NEC": "Negros Occidental",
      "PH-NER": "Negros Oriental",
      "PH-NSA": "Northern Samar",
      "PH-NUE": "Nueva Ecija",
      "PH-NUV": "Nueva Vizcaya",
      "PH-MDC": "Occidental Mindoro",
      "PH-MDR": "Oriental Mindoro",
      "PH-PLW": "Palawan",
      "PH-PAM": "Pampanga",
      "PH-PAN": "Pangasinan",
      "PH-QUE": "Quezon",
      "PH-QUI": "Quirino",
      "PH-RIZ": "Rizal",
      "PH-ROM": "Romblon",
      "PH-WSA": "Samar",
      "PH-SAR": "Sarangani",
      "PH-SIG": "Siquijor",
      "PH-SOR": "Sorsogon",
      "PH-SCO": "South Cotabato",
      "PH-SLE": "Southern Leyte",
      "PH-SUK": "Sultan Kudarat",
      "PH-SLU": "Sulu",
      "PH-SUN": "Surigao del Norte",
      "PH-SUR": "Surigao del Sur",
      "PH-TAR": "Tarlac",
      "PH-TAW": "Tawi-Tawi",
      "PH-ZMB": "Zambales",
      "PH-ZAN": "Zamboanga del Norte",
      "PH-ZAS": "Zamboanga del Sur",
      "PH-ZSI": "Zamboanga Sibugay",
      "PH-00": "Metro Manila"
    }
  },
  KR: {
    countryName: {
      en: "South Korea",
      ar: "كوريا الجنوبية", // Arabic
      da: "Sydkorea", // Danish
      de: "Südkorea", // German
      es: "Corea del Sur", // Spanish
      fr: "Corée du Sud", // French
      he: "דרום קוריאה", // Hebrew
      it: "Corea del Sud", // Italian
      jp: "韓国", // Japanese
      nl: "Zuid-Korea", // Dutch
      pl: "Korea Południowa", // Polish
      ru: "Южная Корея", // Russian
      sv: "Sydkorea", // Swedish
    },
    divisionName: "Provinces",
    divisions: {
      "KR-43": "충청북도 (North Chungcheong)",
      "KR-44": "충청남도 (South Chungcheong)",
      "KR-42": "강원특별자치도 (Gangwon)",
      "KR-41": "경기도 (Gyeonggi)",
      "KR-47": "경상북도 (North Gyeongsang)",
      "KR-48": "경상남도 (South Gyeongsang)",
      "KR-45": "전라북도 (North Jeolla)",
      "KR-46": "전라남도 (South Jeolla)",
      "KR-49": "제주특별자치도 (Jeju)"
    }
  },
  TW: {
    countryName: {
      en: "Taiwan",
      ar: "تايوان", // Arabic
      da: "Taiwan", // Danish
      de: "Taiwan", // German
      es: "Taiwán", // Spanish
      fr: "Taïwan", // French
      he: "טייוואן", // Hebrew
      it: "Taiwan", // Italian
      jp: "台湾", // Japanese
      nl: "Taiwan", // Dutch
      pl: "Tajwan", // Polish
      ru: "Тайвань", // Russian
      sv: "Taiwan", // Swedish
    },
    divisionName: "Subdivisions",
    divisions: {
      "TW-CHA": "彰化縣 (Changhua)",
      "TW-CYI": "嘉義市 (Chiayi City)",
      "TW-CYQ": "嘉義縣 (Chiayi County)",
      "TW-HSZ": "新竹市 (Hsinchu City)",
      "TW-HSQ": "新竹縣 (Hsinchu County)",
      "TW-HUA": "花蓮縣 (Hualien)",
      "TW-KHH": "高雄市 (Kaohsiung)",
      "TW-KEE": "基隆市 (Keelung)",
      "TW-KIN": "金門縣 (Kinmen)",
      "TW-LIE": "連江縣 (Lienchiang)",
      "TW-MIA": "苗栗縣 (Miaoli)",
      "TW-NAN": "南投縣 (Nantou)",
      "TW-NWT": "新北市 (New Taipei)",
      "TW-PEN": "澎湖縣 (Penghu)",
      "TW-PIF": "屏東縣 (Pingtung)",
      "TW-TXG": "臺中市 (Taichung)",
      "TW-TNN": "臺南市 (Tainan)",
      "TW-TPE": "臺北市 (Taipei)",
      "TW-TTT": "臺東縣 (Taitung)",
      "TW-TAO": "桃園市 (Taoyuan)",
      "TW-ILA": "宜蘭縣 (Yilan)",
      "TW-YUN": "雲林縣 (Yunlin)",
    }
  },
  TH: {
    countryName: {
      en: "Thailand",
      ar: "تايلاند", // Arabic
      da: "Thailand", // Danish
      de: "Thailand", // German
      es: "Tailandia", // Spanish
      fr: "Thaïlande", // French
      he: "תאילנד", // Hebrew
      it: "Tailandia", // Italian
      jp: "タイ", // Japanese
      nl: "Thailand", // Dutch
      pl: "Tajlandia", // Polish
      ru: "Таиланд", // Russian
      sv: "Thailand", // Swedish
    },
    divisionName: "Province",
    divisions: {
      "TH-10": "กรุงเทพมหานคร (Bangkok)",
      "TH-37": "อำนาจเจริญ (Amnat Charoen)",
      "TH-15": "อ่างทอง (Ang Thong)",
      "TH-38": "บึงกาฬ (Bueng Kan)",
      "TH-31": "บุรีรัมย์ (Buri Ram)",
      "TH-24": "ฉะเชิงเทรา (Chachoengsao)",
      "TH-18": "ชัยนาท (Chai Nat)",
      "TH-36": "ชัยภูมิ (Chaiyaphum)",
      "TH-22": "จันทบุรี (Chanthaburi)",
      "TH-50": "เชียงใหม่ (Chiang Mai)",
      "TH-57": "เชียงราย (Chiang Rai)",
      "TH-20": "ชลบุรี (Chon Buri)",
      "TH-86": "ชุมพร (Chumphon)",
      "TH-46": "กาฬสินธุ์ (Kalasin)",
      "TH-62": "กำแพงเพชร (Kamphaeng Phet)",
      "TH-71": "กาญจนบุรี (Kanchanaburi)",
      "TH-40": "ขอนแก่น (Khon Kaen)",
      "TH-81": "กระบี่ (Krabi)",
      "TH-52": "ลำปาง (Lampang)",
      "TH-51": "ลำพูน (Lamphun)",
      "TH-42": "เลย (Loei)",
      "TH-16": "ลพบุรี (Lop Buri)",
      "TH-58": "แม่ฮ่องสอน (Mae Hong Son)",
      "TH-44": "มหาสารคาม (Maha Sarakham)",
      "TH-49": "มุกดาหาร (Mukdahan)",
      "TH-26": "นครนายก (Nakhon Nayok)",
      "TH-73": "นครปฐม (Nakhon Pathom)",
      "TH-48": "นครพนม (Nakhon Phanom)",
      "TH-30": "นครราชสีมา (Nakhon Ratchasima)",
      "TH-60": "นครสวรรค์ (Nakhon Sawan)",
      "TH-80": "นครศรีธรรมราช (Nakhon Si Thammarat)",
      "TH-55": "น่าน (Nan)",
      "TH-96": "นราธิวาส (Narathiwat)",
      "TH-39": "หนองบัวลำภู (Nong Bua Lam Phu)",
      "TH-43": "หนองคาย (Nong Khai)",
      "TH-12": "นนทบุรี (Nonthaburi)",
      "TH-13": "ปทุมธานี (Pathum Thani)",
      "TH-94": "ปัตตานี (Pattani)",
      "TH-82": "พังงา (Phangnga)",
      "TH-93": "พัทลุง (Phatthalung)",
      "TH-56": "พะเยา (Phayao)",
      "TH-67": "เพชรบูรณ์ (Phetchabun)",
      "TH-76": "เพชรบุรี (Phetchaburi)",
      "TH-66": "พิจิตร (Phichit)",
      "TH-65": "พิษณุโลก (Phitsanulok)",
      "TH-14": "พระนครศรีอยุธยา (Phra Nakhon Si Ayutthaya)",
      "TH-54": "แพร่ (Phrae)",
      "TH-83": "ภูเก็ต (Phuket)",
      "TH-25": "ปราจีนบุรี (Prachin Buri)",
      "TH-77": "ประจวบคีรีขันธ์ (Prachuap Khiri Khan)",
      "TH-85": "ระนอง (Ranong)",
      "TH-70": "ราชบุรี (Ratchaburi)",
      "TH-21": "ระยอง (Rayong)",
      "TH-45": "ร้อยเอ็ด (Roi Et)",
      "TH-27": "สระแก้ว (Sa Kaeo)",
      "TH-47": "สกลนคร (Sakon Nakhon)",
      "TH-11": "สมุทรปราการ (Samut Prakan)",
      "TH-74": "สมุทรสาคร (Samut Sakhon)",
      "TH-75": "สมุทรสงคราม (Samut Songkhram)",
      "TH-19": "สระบุรี (Saraburi)",
      "TH-91": "สตูล (Satun)",
      "TH-17": "สิงห์บุรี (Sing Buri)",
      "TH-33": "ศรีสะเกษ (Si Sa Ket)",
      "TH-90": "สงขลา (Songkhla)",
      "TH-64": "สุโขทัย (Sukhothai)",
      "TH-72": "สุพรรณบุรี (Suphan Buri)",
      "TH-84": "สุราษฎร์ธานี (Surat Thani)",
      "TH-32": "สุรินทร์ (Surin)",
      "TH-63": "ตาก (Tak)",
      "TH-92": "ตรัง (Trang)",
      "TH-23": "ตราด (Trat)",
      "TH-34": "อุบลราชธานี (Ubon Ratchathani)",
      "TH-41": "อุดรธานี (Udon Thani)",
      "TH-61": "อุทัยธานี (Uthai Thani)",
      "TH-53": "อุตรดิตถ์ (Uttaradit)",
      "TH-95": "ยะลา (Yala)",
      "TH-35": "ยโสธร (Yasothon)"
    }
  },
});