import { API } from "aws-amplify";
import { getSignedUrlFromS3 } from "./zephyrxLib";

const ORGANIZATION_API = "organization";

export function checkOrganizationAPI() {
  return API.get(ORGANIZATION_API, "/check");
}

export function createOrganization(organization) {
  return API.post(ORGANIZATION_API, "/create", {
    body: organization
  });
}

export function getOrganization(organizationID) {
  return API.get(ORGANIZATION_API, `/${organizationID}`);
}

export function loadOrganizations() {
  return API.get(ORGANIZATION_API, "/list");
}

export function getAllOrganizationsDetailed() {
  return API.get(ORGANIZATION_API, "/all");
}

export async function getSpecificFieldsForAllOrganizations(fields = []) {
  const response = await API.post(ORGANIZATION_API, "/all", {
    body: { projectionExpression: fields }
  });
  return response;
}

export async function updateOrganization(organization) {
  const { organizationID, ...restOrg } = organization;
  const response = await API.put(ORGANIZATION_API, `/${organizationID}`, {
    body: restOrg
  })
  return response;
}

export function removeOrganization(organization) {
  let organizationID = typeof (organization) === 'object' ? organization.organizationID : organization;
  return API.get(ORGANIZATION_API, `/remove/${organizationID}`);
}

export async function loadOrganizationDetails() {
  let allOrgs = await getAllOrganizationsDetailed();
  return allOrgs.organizations;
}

export const getOrganizationsFromIDs = async organizationIDs =>
  (await
    Promise.all(organizationIDs.map(
      async organizationID => {
        try {
          const organization = await getOrganization(organizationID);
          return organization;
        } catch (e) {
          console.log(organizationID, e);
          return null;
        }
      }))).filter(org => !!org);

export const getBillingEntities = () => {
  return API.get(ORGANIZATION_API, '/billing');
};

/**
 * @param {{displayTag: string, externalBillingID: string, accountOwner:string, region?: string, notes? : string}} body
 */
export const createBillingEntity = (body) => {
  return API.post(ORGANIZATION_API, '/billing', { body })
};

/**
 * @param {{billingID: string, displayTag?: string, externalBillingID?: string, accountOwner?: string, region?: string, notes? : string}} body
 */
export const updateBillingEntity = (body) => {
  return API.put(ORGANIZATION_API, '/billing', { body });
};

/**
 * @param {string} billingID
 */
export const deleteBillingEntity = (billingID) => {
  return API.del(ORGANIZATION_API, `/billing?billingID=${billingID}`);
};

/**
 * @param {*} organization
 * @returns {Promise<string>} image data
 */
export const getOrganizationImageFromS3 = async (organization) => {
  try {
    let imageData = await getSignedUrlFromS3(`logo/${organization.organizationID}/${organization.logo}`, 'getObject');
    return imageData;
  } catch {
    return '';
  };
}