export const GET_ORGANIZATIONS_FOR_PROVIDER = 'GET_ORGANIZATIONS_FOR_PROVIDER';

export const SET_ORGANIZATIONS_FOR_PROVIDER = 'SET_ORGANIZATIONS_FOR_PROVIDER';

export const SET_ORGANIZATIONS_FOR_PROVIDER_IDS = 'SET_ORGANIZATIONS_FOR_PROVIDER_IDS';

export const SET_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN = 'SET_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN';

export const UPDATE_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN = 'UPDATE_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN';

export const SET_DETAILED_ORGANIZATIONS_FOR_PROVIDER = 'SET_DETAILED_ORGANIZATIONS_FOR_PROVIDER';

export const UPDATE_DETAILED_ORGANIZATIONS_FOR_PROVIDER = 'UPDATE_DETAILED_ORGANIZATIONS_FOR_PROVIDER';

export const GET_ACTIVE_ORGANIZATIONS_FOR_PROVIDER = 'GET_ACTIVE_ORGANIZATIONS_FOR_PROVIDER';

export const SET_SELECTED_ORGANIZATION_FOR_DROPDOWN = 'SET_SELECTED_ORGANIZATION_FOR_DROPDOWN';

export const SET_PROVIDER_DROPDOWN = 'SET_PROVIDER_DROPDOWN';

export const CLEAR_ORGANIZATIONS_FOR_PROVIDER = 'CLEAR_ORGANIZATIONS_FOR_PROVIDER';