import { Reducer } from 'redux';
import { Provider, OrganizationProvider } from '../../Types/Common/Types';
import { SET_PROVIDERS_FOR_ORGANIZATION, REMOVE_PROVIDERS, SET_ADMINS_FOR_ORGANIZATION, SET_PROVIDER_PERMISSIONS_FOR_ORGANIZATION } from './actionTypes';

interface ProvidersReducerAction {
  type: string;
  payload: Record<string, Provider> | Record<string, OrganizationProvider>;
};

const INITIAL_STATE = {
  providersForOrganization: {} as Record<string, Provider>,
  adminsForOrganization: {} as Record<string, Provider>,
  providerPermissionsForOrganization: {} as Record<string, OrganizationProvider>
};

export const providersReducer: Reducer<typeof INITIAL_STATE, ProvidersReducerAction> = (state = INITIAL_STATE, action: ProvidersReducerAction) => {
  switch (action.type) {
    case SET_PROVIDERS_FOR_ORGANIZATION:
      return { ...state, providersForOrganization: action.payload as Record<string, Provider> };
    case SET_ADMINS_FOR_ORGANIZATION:
      return { ...state, adminsForOrganization: action.payload as Record<string, Provider> };
    case SET_PROVIDER_PERMISSIONS_FOR_ORGANIZATION:
      return { ...state, providerPermissionsForOrganization: action.payload as Record<string, OrganizationProvider> };
    case REMOVE_PROVIDERS:
      return INITIAL_STATE;
    default:
      return state;
  };
};