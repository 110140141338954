// import { getNotConsentedPatients } from './organizationConsentLib';
import { getPatient } from "./patientLib";

export function toTitleCase(phrase) {
  if (phrase) {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  return "";
};

export function generateArtiqUnacceptableMessage(grade, measurement) {
  if (measurement === 'fvc' && grade === 'E') return 'Please consider repeating the session.  If not possible, please contact AstraZeneca to confirm eligibility.';
  return 'Unacceptable. Please add additional efforts to this session or start a new session.';
};

export function generateAjaxArtiqUnacceptableMessage(grade, measurement) {
  if (measurement === 'fvc' && grade === 'D') {
    return 'Please consider repeating the session.  If not possible, please contact AstraZeneca to confirm eligibility.';
  };
  if (measurement === 'fev1' && grade >= 'D') {
    return 'Unacceptable. Please add additional efforts to this session or start a new session.';
  };
  return 'Unacceptable. Please add additional efforts to this session or start a new session.';
};

/**
 * Formatter for common test / measurement types
 * @param {string} test - test or measurement type
 * @returns {string}
 */
export function toTestType(test) {
  if (!test || typeof test !== 'string') return 'UNKNOWN';

  switch (test.toLowerCase()) {
    case 'fev1fvc':
      return 'FEV1/FVC';
    case 'exercise':
      return 'Game';
    case 'demographic':
      return 'Demographic';
    default:
      return test.toUpperCase();
  }
}

export function toTimePeriod(time) {
  switch (time) {
    case 'daily':
    case 'weekly':
    case 'monthly':
    case 'quarterly':
    case 'annually':
    case 'custom':
      return toTitleCase(time);
    case 'biweekly':
      return 'Bi-weekly';
    case 'bimonthly':
      return 'Bi-monthly';
    case 'semiannually':
      return 'Semi-annually';
    default:
      return '';
  }
}

const MILLISECONDS_IN_DAY = 86400000;

const timeIntervalMap = {
  daily: MILLISECONDS_IN_DAY,
  biweekly: MILLISECONDS_IN_DAY * 3.5,
  weekly: MILLISECONDS_IN_DAY * 7,
  bimonthly: MILLISECONDS_IN_DAY * 14,
  monthly: MILLISECONDS_IN_DAY * 30,
  quarterly: MILLISECONDS_IN_DAY * 90,
};

const customTimeInterval = (days) => days === 0 ? MILLISECONDS_IN_DAY : MILLISECONDS_IN_DAY * days;

const createUTCDate = (year, month, date) => new Date(Date.UTC(year, month - 1, date));

export function calculateCustomNextReminder(start, days) {
  let startDate = createUTCDate(start.year, start.month, start.date);
  let startTime = startDate.getTime();
  if (startTime > Date.now()) {
    const startFullDate = new Date(startTime);
    return {
      year: startFullDate.getFullYear(),
      month: startFullDate.getMonth(),
      date: startFullDate.getDate()
    };
  }
  else {
    while (startTime < Date.now()) {
      startTime += customTimeInterval(days + 1);
    }
    const nextFullDate = new Date(startTime);
    return {
      year: nextFullDate.getFullYear(),
      month: nextFullDate.getMonth(),
      date: nextFullDate.getDate()
    };
  }
}

export function calculateNextReminder(start, interval) {
  let startTime = new Date(start).getTime();
  if (startTime > Date.now()) return startTime;
  else {
    while (startTime < Date.now()) {
      startTime += timeIntervalMap[interval];
    }
    return startTime;
  }
}

export function leftPadDayOrMonth(date) {
  if (!date) return null;
  if (date.toString().length === 1) return '0' + date;
  else return date;
}

export const notNullAndHasFields = (item, fields) =>
  !!(item &&
    (!fields ||
      (Array.isArray(fields) &&
        fields.every(field => !!(item[field]))
      )
    )
  );

export const getMidnightOnDate = (date) => {
  const workingDate = new Date(date);
  workingDate.setUTCHours(0);
  workingDate.setUTCMinutes(0);
  return workingDate.getTime();
};

export const getFirstOfRelativeMonth = (monthOffset) => {
  const workingDate = new Date();
  workingDate.setUTCDate(1);
  workingDate.setUTCMonth(workingDate.getUTCMonth() + monthOffset);
  return getMidnightOnDate(workingDate);
};

export const onlyUnique = (value, index, self) =>
  self.indexOf(value) === index;

export const onlyUniqueByProperty = (array, uniqueProperty) => {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item[uniqueProperty])) {
      map.set(item[uniqueProperty], true);    // set any value to Map
      result.push(item);
    }
  }
  return result;
}

export const formatUnicode = message => message.codePointAt(0).toString(16);

// Checks if there's more than 10 numbers in the field
// and that there's a ':' character
export const isProbablyEncrypted = field =>
  field.includes(':') &&
  field
    .trim()
    .split('')
    .filter((char) => /^\d+$/.test(char)).length >= 10;

function binarySearch(array, value, comparer) {
  var low = 0,
    high = array.length;

  while (low < high) {
    var mid = (low + high) >>> 1;
    if (comparer(value, array[mid])) low = mid + 1;
    else high = mid;
  }
  return low;
}

export const formatForDropdown = (organizations) =>
(organizations
  .sort((a, b) =>
    (a.name.trim().toUpperCase() < b.name.trim().toUpperCase())
      ? -1 : (a.name.trim().toUpperCase() > b.name.trim().toUpperCase()) ? 1 : 0)
  .reduce((acc, org, i) => ([...acc, org.id ? org : { // If the org has "id" instead of "organizationID" it's already formatted
    value: i,
    name: org.name,
    label: `${org.name} (${org.displayProgram})`,
    id: org.organizationID,
    program: org.displayProgram
  }]
  ), []));

export function addToSortedArray(sortedArray, newElement, compareFunc) {
  sortedArray.splice(binarySearch(sortedArray, newElement, compareFunc), 0, newElement);
}

export function addToSortedArrayImmutable(sortedArray, newElement, compareFunc) {
  return [...sortedArray].splice(binarySearch(sortedArray, newElement, compareFunc), 0, newElement);
}

export const generateErrorText = (variant, ID, results) => {
  switch (variant) {
    case "spirometer":
      return !ID ? "No patient found with that spirometerID" : !results.length ? "No results found!" : "";
    case "provider-email":
      return !ID ? "No provider found with that email" : !results.length ? "No results found!" : "";
    case "patient-info":
      return !ID ? "No patient found with that ID" : !results.length ? "No results found!" : "";
    default:
      return "Something went wrong!";
  };
};

export const generateTableData = ({ patientData, recentSeries, organizations, provider, providerOrganizations, coachingSession, spirometers }) => {
  return {
    recentSeries,
    organizations,
    patientData,
    provider,
    providerOrganizations,
    coachingSession,
    viewingAs: provider?.viewingAs,
    spirometers
  };
};

export const isSpirometerID = (id) => id.length <= 14;

export const getLastSixInputs = (input) => input.slice(-6);

export const isString = (input) => typeof input === 'string';

export const generateSpirometerID = (input) => `SM-005-Z${getLastSixInputs(input)}`;

export const isEmptyObjectOrArray = (input) => !!input && !Object.keys(input).length && typeof input === 'object';

// export const flagNonAcceptedPatientsForOrganizations = async (organizationsForPatient, patientID) => {
//   const nonAcceptedPatientsForOrgs = await Promise.all(organizationsForPatient.map(org => getNotConsentedPatients(org.organizationID)));
//   const orgsWithNotAcceptedFlags = organizationsForPatient.map(organization => {
//     nonAcceptedPatientsForOrgs.forEach(orgData => {
//       if (orgData.patientIDs.includes(patientID)) organization.notAccepted = true;
//     });
//     return organization;
//   });
//   return orgsWithNotAcceptedFlags;
// };

export const combineOrgAndConsentData = (organization, consent) => {
  const { accepted, created, expirationTimestamp, legalBehalfName, legalBehalfRelationship, preRegistered, revoked, updated } = consent;
  return {
    ...organization,
    accepted,
    created,
    expirationTimestamp,
    legalBehalfName,
    legalBehalfRelationship,
    preRegistered,
    revoked,
    updated,
  };
};

/**
 * @param {Number} errorCode
 * @returns {string}
 */
export const generateAdminErrorTextFromCode = (errorCode) => {
  if (errorCode === 403) return 'Forbidden. Please refresh and try again';
  if (errorCode === 404) return 'Information not found';
  if (errorCode >= 500) return 'Something went wrong on our end - please refresh and try again';
  return 'Something went wrong - please refresh and try again later';
};


/**
 * Filters an array of Promise.allSettled objects and neatly logs any rejected promises to the console.
 *
 * @param {[Object]} settledPromises an array of Promise.allSettled objects
 * @param {String} label (Optional) string to label the group of logs
 */
export const logRejectedAllSettled = (settledPromises, label = "Possible Data Missing: ") => {
  const rejectedPromises = settledPromises.filter(promise => promise.status === 'rejected');
  if (!rejectedPromises.length) return;

  console.group(label);
  rejectedPromises.forEach(promise => console.log(`${promise.reason}: `, { ...promise.reason }));
  console.groupEnd(label);
};

/**
 * Convert camelCase to Title Case,
 * capitalizes instances of FVC and SVC,
 * and trims whitespace
 * @param {string} camelCase a camelCased string
 * @returns a Title Cased string
 */
export const parseCamelToTitle = (camelCase) => (camelCase
  .replace(/([A-Z])/g, (match) => ` ${match}`) // seperate on the caps
  .replace(/^./, (match) => match.toUpperCase()) // uppercase any first letter
  .replace(/(fvc)|(svc)/gi, (match) => match.toUpperCase()) // capitalize instances of FVC or SVC
  .replace(/(\si\sd\s*)/gi, () => ' ID ')
  .trim());

/**
 * Programmatically title and download a file
 *
 * @param {*} fileURL an Object URL created from a Blob
 * @param {String} title the report title
 */
export const downloadFile = (fileURL, title) => {
  const element = document.createElement('a');
  element.setAttribute('href', fileURL);
  element.setAttribute('download', `${title.trim()}`);

  element.style.display = 'none';

  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
};

/**
 * @description decodes a Csv string, either base64 or plain text, titles and downloads the csv file
 * @param {String} csvString
 * @param {String} title
 * @param {Boolean} isBase64 - set to true if csvString is base64 encoded
 */
export const decodeAndDownloadCsv = (csvString, title, isBase64 = false) => {
  if (isBase64) {
    const decodedResponse = atob(csvString);
    const responseAsBlob = new Blob([decodedResponse], { type: "application/csv" });
    const downloadUrl = window.URL.createObjectURL(responseAsBlob);
    downloadFile(downloadUrl, `${title}.csv`);
  } else {
    const responseAsBlob = new Blob([csvString], { type: "application/csv" });
    const downloadUrl = window.URL.createObjectURL(responseAsBlob);
    downloadFile(downloadUrl, `${title}.csv`);
  };
};

/**
 * @description A recursive polling function, runs until response passes validation, exceeds max attempts, or is an object with error property
 * @param {{callbackFn: Function, validateFn: Function, interval: Number, maxAttempts: Number, args: Array}} object params
 * @returns Resolved / Rejected promise or an Error if maxAttempts exceeded
 */
export const poll = async ({ callbackFn, validateFn, interval, maxAttempts, args }) => {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    const result = await callbackFn(...args);
    console.log('Result: ', result);
    console.log('Attempt #', attempts);
    attempts++;

    if (validateFn(result.data) || validateFn(result) || validateFn(result.url)) {
      return resolve(result);
    } else if ((maxAttempts && attempts === maxAttempts)) {
      return reject(new Error('Exceeded max attempts'));
    } else if (result.error) {
      return reject(new Error(result.error));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

/**
 *
 * @param {array} programs
 * @returns map of programs { PROGRAM: {... program data } }
 */
export const generateProgramMap = (programs) => (programs.reduce((map, program) => ({ ...map, [program.program.toUpperCase()]: program.displayProgram }), {}));

export const generateBillingEntityMap = (entities) => (entities.reduce((map, entity) => ({ ...map, [entity.billingID]: entity }), {}));

export const generateSSOPoolsMap = (pools) => (pools.reduce((map, pool) => ({ ...map, [pool.domain]: pool }), {}));

/**
 * @param {any} patient
 * @param {string} minimumVersion
 * @returns {Promise<boolean>}
 */
export const isPatientMobileVersionValid = async (patient, minimumVersion) => {
  if (!patient || !minimumVersion) throw new Error(`Missing argument: ${!patient ? 'patient' : 'minimumVersion'}`);
  if (patient?.configCache?.systemInfo?.appVersion >= minimumVersion) return true;

  const freshPatient = await getPatient(patient.patientID);
  if (freshPatient?.configCache?.systemInfo?.appVersion >= minimumVersion) return true;
  return false;
};

export const getTimezoneOffsetInMilliseconds = () => {
  return new Date().getTimezoneOffset() * 60 * 1000;
};

/**
 * @param {object} authenticatedProvider
 * @param {any} object - data to log to the console
 * @param {string} dataLabel - label for the data being logged
 */
export const logObjectIfFullAdmin = (authenticatedProvider, object, dataLabel = "Logging: ") => {
  if (!!authenticatedProvider?.fullAdmin) console.log(`${dataLabel}: `, object);
};

/**
 *
 * @param {ProgressEvent<FileReader>} reader
 * @param {Function} callback to be called with the string result
 */
export const filereaderDataUrlOnLoadEnd = (reader, callback) => {
  let result = reader.target?.result;
  if (!result) return;
  if (result && !(result instanceof ArrayBuffer)) return callback(result || '');
  if (result && result instanceof ArrayBuffer) {
    const enc = new TextDecoder('utf-8');
    result = enc.decode(result);
    callback(result);
  };
};

/**
 * @param {string | undefined} language - defaults to browser locale is language is undefined
 * @returns number formatter - use it like so: formatter.format(number)
 */
export const createCurrencyFormatter = (language) => {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: 'USD'
  });
};

/**
 * @param {{parameter: string, thresholdPercent: number, baseline?: boolean, time?: boolean, previous?: boolean}} trendNotification expected format from Edit Organization form
 * @returns array of trend notifications formatted for our API
 */
export const generateTrendNotificationsFromInput = (trendNotification) => {
  const generatedNotifications = [];
  const { parameter, thresholdPercent, } = trendNotification;
  const baseline = !!trendNotification?.baseline;
  const time = !!trendNotification?.time;
  const previous = !!trendNotification?.previous;
  if (baseline) generatedNotifications.push({ parameter, thresholdPercent, trendType: 'baseline' });
  if (time) generatedNotifications.push({ parameter, thresholdPercent, trendType: 'time' });
  if (previous) generatedNotifications.push({ parameter, thresholdPercent, trendType: 'previous' });
  return generatedNotifications;
}

export const formatTrendNotifications = (trendNotifications) => {
  const arraysOfFormattedTrendNotifications = trendNotifications.map(notification => generateTrendNotificationsFromInput(notification));
  return arraysOfFormattedTrendNotifications.flat(Infinity);
};

/**
 * @param {{parameter: string, thresholdPercent: number, trendType: string}[]} trendNotifications - in API format
 * @returns {{parameter: string, thresholdPercent: number, baseline?: boolean, time?: boolean, previous?: boolean}[]} Array of trend notifications compressed for Edit Organization form
 */
export const compressTrendNotificationsForInput = (trendNotifications) => {
  if (!trendNotifications.length) return;

  const trendNotificationsForInput = [];

  // ADD & UPDATE TRENDS FOR FORM FORMAT
  trendNotifications.forEach(trendNotification => {
    const trendAlreadyMadeForInput = trendNotificationsForInput.find(trend => trend.parameter === trendNotification.parameter);
    if (trendAlreadyMadeForInput) {
      const { trendType } = trendNotification;
      if (trendType) trendAlreadyMadeForInput[trendType] = true;
    } else {
      const { trendType, parameter, thresholdPercent } = trendNotification;
      const newTrendForInput = { parameter, thresholdPercent, };
      if (trendType) newTrendForInput[trendType] = true;
      trendNotificationsForInput.push(newTrendForInput);
    }
  });

  // ADD EXPLICIT BOOLEAN FIELDS FOR NON-EXISTENT TREND TYPES
  trendNotificationsForInput.forEach(trendNotification => {
    if (!trendNotification?.time) trendNotification.time = false;
    if (!trendNotification?.baseline) trendNotification.baseline = false;
    if (!trendNotification?.previous) trendNotification.previous = false;
  });

  return trendNotificationsForInput;
};

export const convertInchesToCentimeters = (heightInches) => Number(heightInches) * 2.54;

export const convertCentimetersToInches = (heightCentimeters) => Number(heightCentimeters) / 2.54;

export const convertPoundsToKilograms = (weightLbs) => Number(weightLbs) * 0.45359237;

export const convertKilogramsToPounds = (weightKilograms) => Number(weightKilograms) / 0.45359237;

/**
 * @param {any} object object you wish to clone
 * @param {string} key to omit
 * @returns a clone of the object passed in with the 2nd argument key omitted
 */
export const omitObjectProperty = (object, key) => {
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

/**
 * @param {string} source string you want to remove characters from
 * @param {array | string} chars an array with all of the strings you want to remove
 * @param {string} replacement? replacement string
 * @returns a str
 */
export const replaceAllChars = (source, chars, replacement) => {
  let result = source;
  let search = [].concat(chars);
  search.forEach(char => {
    result = result.split(char).join(replacement);
  });
  return result;
};

/**
 * @param {string} source string you want to make camelCase
 * @returns a string
 */
export const toCamelCase = (source) => {
  let matchedSource = source && source
    .match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]+/g
    )
    .map(word => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
    .join('');

  let result = matchedSource.slice(0, 1).toLowerCase() + matchedSource.slice(1);
  return result;
};

/**
 * @param {string} source string you want to make camelCase with numbers included
 * @returns a string
 */
export const toCamelCaseWithNumbers = (source) => {
  let matchedSource = source && source
    .match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+|[A-Z0-9]+/g
    )
    .map(word => {
      if (typeof parseInt(word) == "number" && !isNaN(parseInt(word))) {
        return word;
      } else if (typeof word == "string") {
        return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
      } return word;
    })
    .join('');

  let result = matchedSource.slice(0, 1).toLowerCase() + matchedSource.slice(1);
  return result;
};

/**
 *
 * @param {string} fullString
 * @param {number} maxLength
 * @param {string?} separator
 * @returns {string} truncated version of the string if it's longer than the max length passed in
 */
export const truncateString = function (fullString, maxLength, separator = "...") {
  if (fullString.length <= maxLength) return fullString;

  let separatorLength = separator.length,
    charsToShow = maxLength - separatorLength,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return fullString.slice(0, frontChars) +
    separator +
    fullString.slice(fullString.length - backChars);
};

/**
 * Opens a downloadable PDF in a new window
 * @param {string} data base64 encoded string representing a PDF
 */
export const openPDF = (data) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: 'application/pdf;base64' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, '_blank');
};

/**
 * @param {*} e - ZEPHYRx API error
 * @param {*} translationText -
 * @returns {string} ErrorMessage
 */
export const composeErrorMessage = (e, translationText) => {
  return e?.response?.data?.message ?? e?.message ?? `${translationText.error || 'Something went wrong'} ${translationText.refreshAndTryAgain || 'Refresh your page and try again'}`;
};

/**
 * @param {*} patient
 * @param {*} organization
 * @returns {string}
 */
export const renderPatientName = (patient, organization) => {
  if (organization.featureSet.nameStudyID) return patient.studyID;
  if (!organization.featureSet.useStudyID || !patient.studyID) return patient.name;
  return `${patient.name} (${patient.studyID})`;
};

/**
 * @param {*} event
 * @returns {{type: string; message: *}} Object with type and message properties
 */
export const parseVuplexEvent = (event) => {
  const data = event?.data;
  console.log({ data })
  if (!data) return console.log('vuplex message missing data: ', data);

  const decodedData = atob(data);
  console.log({ decodedData })
  const decodedJSON = JSON.parse(decodedData);
  console.log({ decodedJSON })
  const type = decodedJSON.type
  const message = decodedJSON.message;

  if (!type || (!message && message !== false)) return console.log(`vuplex message missing property: ${!type ? 'type' : 'message'}`);

  return decodedJSON;
}

/**
 * @param {string | undefined} studyID This will be in the "Patient Name" field, but will be treated like a studyID under the hood
 * @returns {bool} returns whether the form input isvalid
 */
export const isValidastraZenecaStudyID = (studyID) => {
  if (!studyID) return false;
  const targetLength = 8;
  const startCharacter = 'e';
  const numNumbers = 7;

  const rules = [
    (studyID) => studyID.length === targetLength,
    (studyID) => studyID.at(0) === startCharacter,
    (studyID) => {
      const numbers = studyID.slice(1, studyID.length);
      return numbers.length === numNumbers && !isNaN(numbers);
    }
  ];

  const validAstraZenecaStudyID = rules.reduce((prev, current) => {
    return prev && current(studyID);
  }, true);

  return validAstraZenecaStudyID;
};

/**
 * @param {string | undefined} studyID This will be in the "Patient Name" field, but will be treated like a studyID under the hood
 * @returns {bool} returns whether the form input isvalid
 */
export const isValidastraZenecaAjaxStudyID = (studyID) => {
  if (!studyID) return false;
  const targetLength = 8;
  const startCharacter = 'E';
  const numNumbers = 7;

  const rules = [
    (studyID) => studyID.length === targetLength,
    (studyID) => studyID.at(0) === startCharacter,
    (studyID) => {
      const numbers = studyID.slice(1, studyID.length);
      return numbers.length === numNumbers && !isNaN(numbers);
    }
  ];

  const validAstraZenecaStudyID = rules.reduce((prev, current) => {
    return prev && current(studyID);
  }, true);

  return validAstraZenecaStudyID;
};

/**
 * Turn a survey filename into just a survey name:
 * "survey.json" --> "survey"
 * @param {string} surveyFilename
 * @returns {string} survey name
 */
export const generateSurveyNameKey = (surveyFilename) => {
  return surveyFilename.split('.').filter(text => text !== 'json').join('.')
}

export function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * @returns {import("../Types/ChartTypes").ChartRangeOptions}
 */
export const getRangeSelectValueForSession = () => {
  return sessionStorage.getItem('rangeSelectValue');
}

/**
 * @returns {string}
 */
export const getEnv = () => process.env.REACT_APP_STAGE === 'preview' ? 'prod' : process.env.REACT_APP_STAGE;

// Extract 'words' from string, join with empty string, then to lowercase
export const joinAndLowercase = (str) => str.match(/\w/g).join("").toLowerCase();

export const joinAndLowercaseWithSpecial = (str) => str.match(/./g).join('').toLowerCase();

/**
 *
 * @param {string | number | undefined} height
 * @param {boolean} useMetric
 * @returns {string}
 */
export const renderHeight = (height, useMetric) => {
  if (!height) return 'None specified';
  return useMetric ? `${(Number(height) * 2.54).toFixed(2)} cm` : `${Number(height).toFixed(2)} in`
};

/**
 * @param {string | number | undefined} weight weight in pounds
 * @param {boolean} useMetric
 * @returns {string}
 */
export const renderWeight = (weight, useMetric) => {
  if (!weight) return 'None specified';
  return useMetric ? `${(convertPoundsToKilograms(Number(weight))).toFixed(2)} kg` : `${Number(weight).toFixed(2)} lbs`
};

export const toOffsetString = ({ offsetHours, offsetMinutes = 0 }) => {
  // Create an offset string of the format "±hh:mm"
  const sign = offsetHours > 0 ? '+' : '-';
  const hoursOffset = `${Math.abs(offsetHours)}`.padStart(2, '0');
  const minutesOffset = `${Math.abs(offsetMinutes)}`.padStart(2, '0');
  const offset = `${sign}${hoursOffset}:${minutesOffset}`;
  return offset;
};

export const getTimezoneOffsetStringFromTimezoneOffsetMs = (timezoneOffsetMs) => {
  const timezoneOffsetMinutes = ((timezoneOffsetMs / 1000) / 60) * -1;
  if (0 < timezoneOffsetMinutes && timezoneOffsetMinutes < 60) {
    // special case: toOffsetString does not support positive timezone offsets less than one hour. 
    // Luckily this +00:30 is not used anywhere (currently), but good to support nonetheless. (https://en.wikipedia.org/wiki/UTC%2B00:30)
    return `+00:${`${timezoneOffsetMinutes}`.padStart(2, '0')}`;
  }
  // EXAMPLE for -09:30: -570 / 60 = -9.5. Therefore, we want the ceiling = -9.
  // EXAMPLE for +09:30:  570 / 60 =  9.5. Therefore, we want the floor   =  9.
  const offsetHoursRounder = timezoneOffsetMinutes < 0 ? Math.ceil : Math.floor;
  const offsetHours = offsetHoursRounder(timezoneOffsetMinutes / 60);
  const offsetMinutes = Math.abs(timezoneOffsetMinutes) % 60;
  const timezoneOffset = toOffsetString({ offsetHours, offsetMinutes });
  return timezoneOffset;
};

/**
 * Logs a message to the console. If stringify is true, the message is JSON-stringified.
 * 
 * @param {boolean} stringify - Whether to stringify the message or not.
 * @param {...*} messages - The messages to log.
 */
export const conditionalLog = (stringify, ...messages) => {
  if (stringify) {
    // Stringify each message and log it
    const stringifiedMessages = messages.map(message => {
      try {
        return JSON.stringify(message);
      } catch (e) {
        // If JSON.stringify fails, just return the original message
        return message;
      }
    });
    console.log(...stringifiedMessages);
  } else {
    // Log messages as they are
    console.log(...messages);
  };
};