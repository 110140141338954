import { Reducer } from 'redux';
import { Program, ProgramMap } from '../../Types/Common/Types';
import { ADD_PROGRAM, CLEAR_PROGRAMS, SET_PROGRAMS } from './actionTypes';


type Action = {
  type: string;
  payload: ProgramMap;
}

const INITIAL_STATE: ProgramMap = {};

export const programReducer: Reducer<ProgramMap, Action> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROGRAMS:
      return { ...action.payload };
    case ADD_PROGRAM:
      const programs: Program[] = [...Object.values(state), action.payload as unknown as Program];
      programs.sort((a, b) => a.program <= b.program ? -1 : 1);
      return programs.reduce((map, program) => ({ ...map, [program.program.toUpperCase()]: program }), {});
    case CLEAR_PROGRAMS:
      return INITIAL_STATE;
    default:
      return state;
  }
};