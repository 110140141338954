export const SET_DETAILED_ORGANIZATIONS = 'SET_DETAILED_ORGANIZATIONS';

export const UPDATE_DETAILED_ORGANIZATIONS = 'UPDATE_DETAILED_ORGANIZATIONS';

export const SET_ORGANIZATION_IDS = 'SET_ORGANIZATION_IDS';

export const SET_ORGANIZATIONS_DROPDOWN = 'SET_ORGANIZATIONS_DROPDOWN';

export const UPDATE_ORGANIZATIONS_FOR_DROPDOWN = 'UPDATE_ORGANIZATIONS_FOR_DROPDOWN';

export const CLEAR_ORGANIZATIONS = 'CLEAR_ORGANIZATIONS';

export const REMOVE_ORGANIZATION = 'REMOVE_ORGANIZATION';