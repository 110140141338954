import { useState, useRef, useEffect, useCallback } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    event => setValues({
      ...fields,
      [event.target.id]: event.target.value
    })
  ];
}

// Returns a stop/start toggle function and the running status
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  const intervalID = useRef(null);
  const [currentDelay, setDelay] = useState(delay);

  const toggleRunning = useCallback(
    () => setDelay(currentDelay => (currentDelay === null ? delay : null)),
    [delay]
  );
  const startRunning = useCallback(
    () => setDelay(delay),
    [delay]
  );
  const stopRunning = useCallback(
    () => setDelay(null), []
  );

  const clear = useCallback(() => clearInterval(intervalID.current), []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (intervalID.current) {
      clear();
    }
    if (currentDelay !== null) {
      intervalID.current = setInterval(tick, currentDelay);
    }
    return clear;
  }, [currentDelay, clear]);

  return [toggleRunning, !!currentDelay, startRunning, stopRunning];
};

/**
 * @param {Function} callbackFn 
 */
export const useEffectOnce = (callbackFn) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callbackFn, []);
};

/**
 * NOTE - not currently used but keeping for reference in the event that we re-introduce pagination
 */
// export const usePatients = (organizationID, { fetchInterval, sorter, batchSize, providerID, adminOptions }) => {
//   // This is set to the 'ExclusiveStartKey' value returned from
//   // DynamoDB. When this variable is null we know that we've done and have received
//   // all the values.
//   const [fetches, setFetches] = useState([]);
//   const [finished, setFinished] = useState(false);
//   const [currentFetchIndex, setCurrentFetchIndex] = useState(0);
//   const currentFetch = useMemo(() => fetches[currentFetchIndex], [fetches, currentFetchIndex]);
//   const options = useMemo(() => adminOptions, [adminOptions]);
//   const [exclusiveStartKey, setExclusiveStartKey] = useState(UserFetchPlaceholder);
//   const [currentFetchKey, setCurrentFetchKey] = useState(null);
//   const loadingIsComplete = useMemo(() => finished, [finished]);

//   const toNextFetch = useCallback(() => {
//     setCurrentFetchKey(null);
//     setExclusiveStartKey(UserFetchPlaceholder);
//     setCurrentFetchIndex(currentFetchIndex + 1);
//   }, [setCurrentFetchIndex, setExclusiveStartKey, currentFetchIndex]);

//   const [patients, batchCount, toggle, clear, status, start, stop] = useAccumulator(async () => {
//     // For each fetch type
//     if (currentFetch) {
//       // Get a batch
//       if (exclusiveStartKey) {
//         // In the case that the timer goes off before the fetch is completed,
//         // this makes sure we wait until it's finished before doing another fetch.
//         if (currentFetchKey !== exclusiveStartKey) {
//           setCurrentFetchKey(exclusiveStartKey);
//           const { results, lastEvaluatedKey } = await getUserBatch(currentFetch.type, currentFetch.identifier, exclusiveStartKey, batchSize || 50);
//           setExclusiveStartKey(lastEvaluatedKey);
//           return results;
//         }
//         return null;
//       }
//       // No more batches
//       else {
//         toNextFetch();
//         return null;
//       }
//     }
//     // No more fetch groups
//     else {
//       stop();
//       setFinished(true);
//       return null;
//     }
//   }, fetchInterval || DefaultFetchInterval,
//     // The reducer decides how to combine the current list with the new element(s).
//     // Here, we're starting with an empty array and always adding new elements in 
//     // sorted order, so the array is automatically always sorted based on the sort
//     // function.
//     (patients, newPatients) => {
//       let sortedPatients = [...patients];
//       newPatients.forEach(newPatient => {
//         addToSortedArray(
//           sortedPatients,
//           newPatient,
//           sorter
//             ? sorter
//             : (a, b) => (a && a.activity) ? a.activity.mostRecent < b.activity.mostRecent : true
//         )
//       });
//       return onlyUniqueByProperty(sortedPatients, 'patientID');
//     });

//   const fullClear = useCallback(() => {
//     setExclusiveStartKey(UserFetchPlaceholder);
//     clear();
//   }, [clear, setExclusiveStartKey]);

//   const orgIDToLoad = useMemo(() => organizationID, [organizationID]);
//   const providerIDToLoad = useMemo(() => providerID, [providerID]);

//   useEffect(() => {
//     const getFetches = async () => {
//       stop();
//       fullClear();
//       setExclusiveStartKey(UserFetchPlaceholder);
//       setCurrentFetchKey(null);
//       setCurrentFetchIndex(0);
//       setFinished(false);
//       let fetches = []
//       if (orgIDToLoad) {
//         fetches = [
//           CreateOrganizationFetch(orgIDToLoad)
//         ];
//       }
//       else if (orgIDToLoad === 0) {
//         if (providerIDToLoad) {
//           const organizationIDs = await getOrganizationsForProvider(providerIDToLoad);
//           fetches = [
//             ...((organizationIDs || []).map(orgID => CreateOrganizationFetch(orgID))),
//             CreateProviderFetch(providerIDToLoad)
//           ];
//         }
//         else {
//           fetches = [
//             CreateAdminFetch(options)
//           ];
//         }
//       }
//       setFetches(fetches);
//       start();
//     }
//     getFetches();
//   }, [orgIDToLoad, providerIDToLoad, fullClear, start, stop, options]);

//   return [patients, status, loadingIsComplete, toggle, fullClear, stop, start, batchCount];
// };