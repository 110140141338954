import { stringify } from 'querystring';
import { API } from "aws-amplify";
import { GetProviderOrganizationsField, GETProviderOrganizationsResponse } from "../../Types/Common/API/provider";

const API_NAME = 'v2Patient';

export const getProviderActiveOrInvitedAllowedOrganizations = <Fields extends GetProviderOrganizationsField>(fields: [Fields, ...Fields[]]): Promise<GETProviderOrganizationsResponse<Fields>> => {
  const queryStringParameters = stringify({ fields });
  return API.get(API_NAME, `/provider/organizations?${queryStringParameters}`, {});
};
