import { Dispatch } from 'redux';
import { ADD_PROGRAM, CLEAR_PROGRAMS, SET_PROGRAMS } from './actionTypes';
import { getAllPrograms } from '../../libs/zephyrxLib';
import { generateProgramMap } from '../../libs/utilsLib';
import { Program, ProgramMap } from '../../Types/Common/Types';
import { programMap as backupProgramMap } from '../../libs/dataLib';

export const setPrograms = (payload: ProgramMap) => ({ type: SET_PROGRAMS, payload });

export const addProgram = (payload: Program) => ({ type: ADD_PROGRAM, payload });

export const fetchProgramMap = () => {
  return async (dispatch: Dispatch) => {
    try {
      const { programs } = await getAllPrograms();
      const programMap = generateProgramMap(programs);
      dispatch(setPrograms(programMap));
    } catch (e) {
      console.log(e)
      const errorCode = e.response.status;
      if (errorCode !== 403) {
        console.log(`REVERTING TO BACKUP PROGRAM MAP`);
        dispatch(setPrograms(backupProgramMap));
      };
    };
  };
};

export const fetchPrograms = () => {
  return async (dispatch: Dispatch) => {
    try {
      const { programs } = await getAllPrograms();
      const programState = programs.reduce((map: ProgramMap, program: Program) => ({ ...map, [program.program.toUpperCase()]: program }), {});
      dispatch(setPrograms(programState));
    } catch (e) {
      console.log(e);
    };
  };
};

export const clearPrograms = () => ({ type: CLEAR_PROGRAMS });