import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { Nav } from "react-bootstrap";
import { useAppSelector } from '../../redux/store';

interface UnauthenticatedNavbarProps { }

export const UnauthenticatedNavbar: React.FC<UnauthenticatedNavbarProps> = () => {
  const navbarText = useAppSelector(state => state.translations, shallowEqual);
  return (
    <Nav.Link>{navbarText.login || 'Log in'}</Nav.Link>
  );
};