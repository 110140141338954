import { batch } from 'react-redux';
import { CLEAR_ORGANIZATIONS, REMOVE_ORGANIZATION, SET_DETAILED_ORGANIZATIONS, SET_ORGANIZATIONS_DROPDOWN, SET_ORGANIZATION_IDS, UPDATE_DETAILED_ORGANIZATIONS, UPDATE_ORGANIZATIONS_FOR_DROPDOWN } from './actionTypes';
import { loadOrganizationDetails, getOrganizationsFromIDs, getOrganization } from '../../libs/organizationLib';
import { zephyrxOrganizationIDs } from '../../libs/dataLib';
import { formatForDropdown } from '../../libs/utilsLib';

/**
 * @param {Object} payload - object with { id: organizationObject }
 * @returns action for dispatch
 */
export const setDetailedOrganizations = (payload) => ({ type: SET_DETAILED_ORGANIZATIONS, payload });

/**
 * @param {Array} payload - organizationIDs
 * @returns action for dispatch
 */
export const setOrganizationIDs = (payload) => ({ type: SET_ORGANIZATION_IDS, payload });

/**
 * @param {Array} payload - array of objects with value, label, id, name, and program
 * @returns action for dispatch
 */
export const setOrganizationsForDropdown = (payload) => ({ type: SET_ORGANIZATIONS_DROPDOWN, payload });

export const updateOrganizationsForDropdown = (payload) => ({ type: UPDATE_ORGANIZATIONS_FOR_DROPDOWN, payload });

export const updateDetailedOrganizations = (id, payload) => ({ type: UPDATE_DETAILED_ORGANIZATIONS, id, payload });

export const clearOrganizations = () => ({ type: CLEAR_ORGANIZATIONS });

export const removeOrganization = (organizationID) => ({ type: REMOVE_ORGANIZATION, payload: organizationID });

/**
 * @param {string} organizationID
 */
export const fetchOrganization = (organizationID) => {
  return async (dispatch) => {
    try {
      const organization = await getOrganization(organizationID);
      dispatch(setDetailedOrganizations([organization]));
    } catch (e) {
      console.log(e);
    };
  };
};

/**
 * @param {Array} organizationIDs
 */
export const fetchOrganizations = (organizationIDs) => {
  return async (dispatch) => {
    try {
      const organizations = await getOrganizationsFromIDs(organizationIDs);
      dispatch(setDetailedOrganizations(organizations));
    } catch (e) {
      console.log(e);
    };
  };
};

/**
 * Only Zephyrx | Test orgs if in PREVIEW environment
 */
export const fetchAllOrganizations = () => {
  return async (dispatch) => {
    try {
      let organizations = await loadOrganizationDetails();
      organizations.sort((a, b) => (a.name.trim().toUpperCase() < b.name.trim().toUpperCase()) ? -1 : (a.name.trim().toUpperCase() > b.name.trim().toUpperCase()) ? 1 : 0);

      // Only ZEPHYRx | Test orgs on PREVIEW
      if (process.env.REACT_APP_STAGE.toLowerCase() === 'preview') {
        organizations = organizations.filter(org =>
          org.name.toLowerCase().includes('zephyrx')
          || org.name.toLowerCase().includes('test')
          || zephyrxOrganizationIDs.includes(org.organizationID)
          || org.program.toLowerCase() === 'kiosk-test'
          || org.program.toLowerCase() === 'zephyrx'
          || org.program.toLowerCase() === 'uatajaxstudy'
          || org.program.toLowerCase() === 'd8210c00005-test')
      };

      const detailedOrganizations = organizations.reduce((prevState, org) => {
        const organizationID = org.organizationID;
        prevState[organizationID] = org;
        return prevState;
      }, {});

      const organizationsForDropdown = formatForDropdown(organizations);

      const organizationIDs = organizations.map(org => org.organizationID);

      batch(() => {
        dispatch(setDetailedOrganizations(detailedOrganizations));
        dispatch(setOrganizationsForDropdown(organizationsForDropdown));
        dispatch(setOrganizationIDs(organizationIDs));
      });
    } catch (e) {
      console.log(e);
    };
  };
};
