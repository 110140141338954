import { generateSSOPoolsMap } from '../../libs/utilsLib';
import { CLEAR_POOLS, REMOVE_POOL, SET_POOL, SET_POOLS } from './actionTypes';

const INITIAL_STATE = {};

export const ssoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_POOLS:
      return { ...action.payload };
    case SET_POOL:
      const pools = [...Object.values(state).filter(item => item.domain !== action.domain), action.payload];
      pools.sort((a, b) => a.domain <= b.domain ? -1 : 1);
      return generateSSOPoolsMap(pools);
    case REMOVE_POOL:
      const newState = { ...state };
      delete newState[action.domain];
      return newState;
    case CLEAR_POOLS:
      return INITIAL_STATE;
    default:
      return state;
  };
};