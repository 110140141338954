// This must be the first line in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'formdata-polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import App from './App';
import { VuplexListener } from './containers/VuplexListener';

import * as serviceWorker from './serviceWorker';
import { connectToApis } from './config';
import { store } from './redux/store';

import './index.scss';

require('dotenv').config();
connectToApis();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ToastProvider placement="bottom-right">
        <VuplexListener>
          <App />
        </VuplexListener>
      </ToastProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
