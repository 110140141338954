import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CgClose } from 'react-icons/cg';
import { shallowEqual } from 'react-redux';

import { FilterDropdown } from '../FilterDropdown/FilterDropdown';

import { OrgSelectOption } from '../../Types/MiscTypes';
import { useAppSelector } from '../../redux/store';

interface ChangeOrganizationModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  selected: OrgSelectOption;
  organizationList: OrgSelectOption[];
  onOrganizationChange: (orgSelectOption: OrgSelectOption) => void;
};

export const ChangeOrganizationModal: React.FC<ChangeOrganizationModalProps> = ({ show, setShow, selected, organizationList, onOrganizationChange }) => {
  const translationText = useAppSelector(state => state.translations, shallowEqual);
  const handleClose = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose} >
      <Modal.Header className="justify-content-center pt-5">
        <Modal.Title>{translationText.selectOrganization || translationText.selectViewingOrganization || 'Select Organization'}</Modal.Title>
        <CgClose size='30px' className='Close' onClick={handleClose} cursor='pointer' data-testid='ChangeOrganizationModal-Close' />
      </Modal.Header>
      <Modal.Body>
        <FilterDropdown
          selected={selected}
          organizationList={organizationList}
          onOrganizationChange={onOrganizationChange}
        />
        <p>{translationText.selectOrganizationText || 'This will switch your dashboard to the selected program.'}</p>
      </Modal.Body>
      <Modal.Footer className="pt-0 pb-3 justify-content-center">
        <Button variant='secondary' onClick={handleClose}>
          {translationText.close || 'Close'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};