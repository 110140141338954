import { AnyAction, Dispatch } from 'redux';
import { batch } from 'react-redux';
import { getOrganizationsForProvider } from '../../libs/organizationProviderLib';
import { formatForDropdown } from '../../libs/utilsLib';
import { CLEAR_ORGANIZATIONS_FOR_PROVIDER, SET_ORGANIZATIONS_FOR_PROVIDER, SET_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN, SET_DETAILED_ORGANIZATIONS_FOR_PROVIDER, SET_ORGANIZATIONS_FOR_PROVIDER_IDS, SET_SELECTED_ORGANIZATION_FOR_DROPDOWN, UPDATE_DETAILED_ORGANIZATIONS_FOR_PROVIDER, UPDATE_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN } from './actionTypes';
import { OrganizationType } from '../../Types/Common/Types';
import { OrgSelectOption } from '../../Types/MiscTypes';
import { getProviderActiveOrInvitedAllowedOrganizations } from '../../libs/v2/provider';

export const setOrganizationsForProvider = (payload: any) => ({ type: SET_ORGANIZATIONS_FOR_PROVIDER, payload });

export const setOrganizationsForProviderIDs = (payload: string[] | 'noOrganizations') => ({ type: SET_ORGANIZATIONS_FOR_PROVIDER_IDS, payload });

export const setOrganizationsForProviderDropdown = (payload: OrgSelectOption[]) => ({ type: SET_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN, payload });

export const updateOrganizationsForProviderDropdown = (id: string, payload: OrgSelectOption) => ({ type: UPDATE_ORGANIZATIONS_FOR_PROVIDER_DROPDOWN, payload });

export const updateDetailedOrganizationForProvider = (id: string, payload: OrganizationType) => ({ type: UPDATE_DETAILED_ORGANIZATIONS_FOR_PROVIDER, payload, id });

export const setSelectedOrg = (payload: OrgSelectOption) => ({ type: SET_SELECTED_ORGANIZATION_FOR_DROPDOWN, payload });

export const setDetailedOrganizationsForProvider = (payload: Record<string, OrganizationType>) => ({ type: SET_DETAILED_ORGANIZATIONS_FOR_PROVIDER, payload });

export const clearOrganizationsForProvider = () => ({ type: CLEAR_ORGANIZATIONS_FOR_PROVIDER });

export const fetchOrganizationsForProvider = (providerID: string) => {
  return async (dispatch: Dispatch) => {
    const { organizationIDs }: { organizationIDs: string[]; } = await getOrganizationsForProvider(providerID);
    dispatch(setOrganizationsForProvider({ organizationIDs }));
  };
};

export const fetchActiveOrganizationsForProvider = () => {
  return async (dispatch: Dispatch) => {
    const { organizations: allowedOrganizations } = await getProviderActiveOrInvitedAllowedOrganizations(['organizations']);
    if (allowedOrganizations.length === 0) {
      dispatch(setOrganizationsForProviderIDs('noOrganizations'));
    } else {
      dispatch(setOrganizationsForProviderIDs(allowedOrganizations.map(organization => organization.organizationID)));
    };

    dispatch(fetchActiveOrganizationsForProviderDetails(allowedOrganizations) as unknown as AnyAction);
  };
};

export const fetchActiveOrganizationsForProviderDetails = (detailedOrganizations: OrganizationType[]) => {
  return async (dispatch: Dispatch) => {
    const organizationsForProviderDropdown = formatForDropdown(detailedOrganizations);
    const detailedOrgObject = detailedOrganizations.reduce((acc: Record<string, OrganizationType>, cur: OrganizationType) => ({ ...acc, [cur.organizationID]: cur }), {});
    batch(() => {
      dispatch(setDetailedOrganizationsForProvider(detailedOrgObject));
      dispatch(setOrganizationsForProviderDropdown(organizationsForProviderDropdown));
    });
  };
};