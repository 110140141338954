import { Reducer } from "redux";
import { SET_LANGUAGE } from "./actionTypes";

// const userLanguage = navigator.language ? navigator.language : navigator.userLanguage ? navigator.userLanguage : 'en';
// const INITIAL_STATE = userLanguage.toLowerCase() === 'en-us' ? 'en' : userLanguage;
const INITIAL_STATE = 'en' as string;

interface ActionType {
  type: string;
  payload: string;
};

export const languageReducer: Reducer<typeof INITIAL_STATE, ActionType> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  };
};