import { API } from "aws-amplify";
import { formatPredicted } from "./dataLib";

const SERIES_API = "pft-series";

export function checkSeriesAPI() {
  return API.get(SERIES_API, "/check");
}

export function addSeries(patientID) {
  return API.post(SERIES_API, "/add", {
    body: JSON.stringify({ patientID })
  });
}

export function getReport(series, patientID) {
  if (typeof (series) === 'object') {
    if (series.patientID) patientID = series.patientID;
    series = series.seriesID;
  }
  return API.get(SERIES_API, `/report/${patientID}/${series}`);
}

export function getSeries(series, patientID) {
  if (typeof (series) === 'object') {
    if (series.patientID) patientID = series.patientID;
    series = series.seriesID;
  }
  return API.get(SERIES_API, `/${patientID}/${series}`);
}

export function getRecentSeries(patientID) {
  return API.get(SERIES_API, `/recent/${patientID}`);
}

export function getBestFVCSeries(patientID) {
  return API.get(SERIES_API, `/best-fvc/${patientID}`);
}

export function getBestFIVCSeries(patientID) {
  return API.get(SERIES_API, `/best-fivc/${patientID}`);
}

export function getBestPEFSeries(patientID) {
  return API.get(SERIES_API, `/best-pef/${patientID}`);
}

export function getBestPIFSeries(patientID) {
  return API.get(SERIES_API, `/best-pif/${patientID}`);
}

export function getMaxBreath(patientID) {
  return API.get(SERIES_API, `/max-breath/${patientID}`);
}

export function getAverageBreath(patientID, limit = 10) {
  return API.get(SERIES_API, `/avg-breath/${patientID}/${limit}`);
}

export function getPatientPFTSeries(patientID) {
  return API.get(SERIES_API, `/patient/${patientID}`);
}

export function getBestPEFEffort(patientID) {
  return API.get(SERIES_API, `/best-pef/${patientID}`);
}

export function getFlowVolumeForSeries(patientID, seriesID) {
  return API.get(SERIES_API, `/flowVolume/${patientID}/${seriesID}`);
}

export function updateSeries(updatedSeries) {
  return API.put(SERIES_API, `/${updatedSeries.patientID}/${updatedSeries.seriesID}`, {
    body: updatedSeries
  });
}

export function removeSeries(series, patientID) {
  if (typeof (series) === 'object') {
    if (series.patientID) patientID = series.patientID;
    series = series.seriesID;
  }
  return API.get(SERIES_API, `/pft-series/remove/${patientID}/${series}`);
}

export function removeSeriesFromPatient(patientID) {
  return API.get(SERIES_API, `/pft-series/patient/remove/${patientID}`);
}

export function endSeries(patientID) {
  return API.get(SERIES_API, `/pft-series/patient/remove/${patientID}`);
}

export const recoverSeriesNotes = async () => {
  const recoveredNotes = JSON.parse(localStorage.getItem('series-notes-recovery'));
  const recoveredPositions = JSON.parse(localStorage.getItem('series-position-recovery'));
  if (recoveredNotes) {
    localStorage.removeItem('series-notes-recovery');
    return (await Promise.all(Object.keys(recoveredNotes).map(async id => {
      const updatedSeries = {
        patientID: recoveredNotes[id].patientID,
        seriesID: id,
        notes: recoveredNotes[id].notes,
        patientPosition: recoveredPositions ? recoveredPositions[id].position : '--',
      }
      await updateSeries(updatedSeries);
    })));
  }
  return null;
}

export const getAllSeriesFromIDs = async (seriesIDs, providerID) =>
  (await
    Promise.all(seriesIDs.map(
      async seriesID => {
        try {
          const provider = await getReport(seriesID, providerID);
          return provider;
        } catch (e) {
          console.log(seriesID, e);
          return null;
        }
      }))).filter(series => !!series);

export function createFallbackFvcSeries(seriesID, sessionID, providerID, patientID, demographicID, spirometerID = "") {
  return API.post(SERIES_API, `/upload`, {
    body: {
      patientID,
      providerID,
      spirometerID,
      seriesID,
      demographicID,
      updated: Date.now(),
      created: Date.now(),
      notes: "",
      fvc: 0.0,
      fvcPred: 0.0,
      fvcPercPred: 0.0,
      fvcZ: 0.0,
      fvcLln: 0.0,
      fev1: 0.0,
      fev1Pred: 0.0,
      fev1PercPred: 0.0,
      fev1Z: 0.0,
      fev1Lln: 0.0,
      fev1Fvc: 0.0,
      fev1FvcPred: 0.0,
      fev1FvcZ: 0.0,
      fev1FvcLln: 0.0,
      fev1FvcPercPred: 0.0,
      fef2575: 0.0,
      fef2575Pred: 0.0,
      fef2575PercPred: 0.0,
      fef2575Z: 0.0,
      fef2575Lln: 0.0,
      fivc: 0.0,
      quality: 0,
      originalQuality: 0,
      pef: 0.0,
      pif: 0.0,
      magnitude: 0.0,
      fev6: 0.0,
      bev: 0.0,
      fet: 0.0,
      fit: 0.0,
      netVolume: 0.0,
      pefTime: 0.0,
      acceptable: 0,
      repeatable: 0,
      flowVolumeCurve: "",
      compressedCurve: true,
      fvcLetterGrade: "",
      fev1LetterGrade: "",
      timezoneName: "",
      roundtripTime: new Date().toISOString(),
      rejected: 0,
      deviceModel: "",
      appVersion: "",
      sessionID,
      pftType: "fvc",
      firmwareVersion: ""
    }
  });
}

export function createFallbackPefSeries(seriesID, sessionID, providerID, patientID, demographicID, spirometerID = "") {
  return API.post(SERIES_API, `/upload`, {
    body: {
      patientID,
      providerID,
      spirometerID,
      seriesID,
      demographicID,
      sessionID,
      notes: "",
      quality: 0,
      originalQuality: 0,
      magnitude: 0.0,
      acceptable: 0,
      repeatable: 0,
      flowVolumeCurve: "",
      compressedCurve: true,
      rejected: 0,
      deviceModel: "",
      appVersion: "",
      pftType: "pef",
      firmwareVersion: "",
      bev: 0,
      pef: 0,
      pefLln: 0,
      pefPercPred: 0,
      pefPred: 0,
      pefTime: 0,
      pefZ: 0,
      fev1: 0,
      updated: Date.now(),
      created: Date.now(),
      roundtripTime: new Date().toISOString(),
    }
  });
};

export function createFallbackSvcSeries(seriesID, sessionID, providerID, patientID, demographicID, spirometerID = "") {
  return API.post(SERIES_API, `/upload`, {
    body: {
      patientID,
      providerID,
      spirometerID,
      seriesID,
      demographicID,
      updated: Date.now(),
      created: Date.now(),
      notes: "",
      vc: 0.0,
      vcPred: 0.0,
      vcPercPred: 0.0,
      vcLln: 0.0,
      vcZ: 0.0,
      ic: 0.0,
      setOrSit: 0.0,
      ev1: 0.0,
      ev1Vc: 0.0,
      et: 0.0,
      it: 0.0,
      tt: 0.0,
      etc: 0.0,
      itc: 0.0,
      quality: 0,
      originalQuality: 0,
      magnitude: 0.0,
      netVolume: 0.0,
      acceptable: 0,
      repeatable: 0,
      flowVolumeCurve: "",
      compressedCurve: true,
      fvcLetterGrade: "",
      fev1LetterGrade: "",
      timezoneName: "",
      roundtripTime: new Date().toISOString(),
      rejected: 0,
      deviceModel: "",
      appVersion: "",
      sessionID,
      pftType: "svc",
      firmwareVersion: ""
    }
  });
};

/**
 * @param {*} updatedSeries - the updated Series (from the API)
 * @param {*[]} detailedSeries - the current detailed series you are viewing and wish to clone, update, and dispatch to the redux store
 * @returns newDetailedSeries - a clone of detailedSeries with the updatedSeries in place of the existing series with the same seriesID
 */
export const createUpdatedDetailedSeries = (updatedSeries, detailedSeries) => {
  const newDetailedSeries = [...detailedSeries];
  for (let i = 0; i < newDetailedSeries.length; i += 1) {
    if (newDetailedSeries[i].seriesID !== updatedSeries.seriesID) continue;

    newDetailedSeries[i] = updatedSeries;
    return newDetailedSeries;
  };
  return newDetailedSeries;
};

/**
 * @param {*} series - an FVC series
 * @param {'fvc' | 'fev1'} measurement - measurement type
 * @param {boolean | undefined} useAts2019
 * @returns {string} a string of the variability
 */
export const generateSeriesVariabilityString = (series, measurement, useAts2019) => {
  if (measurement === 'fev1') {
    const variability = useAts2019
      ? series?.qualityReport?.bestMeasurements?.fev1Variability?.toFixed(2) ?? 'N/A'
      : series?.fev1Variability?.toFixed(2) ?? 'N/A';

    return variability;
  };

  if (measurement === 'fvc') {
    const variability = useAts2019
      ? series?.qualityReport?.bestMeasurements?.fvcVariability?.toFixed(2) ?? 'N/A'
      : series?.fvcVariability?.toFixed(2) ?? 'N/A';

    return variability;
  };

  return 'N/A';
};

/**
 * @param {*} series An FVC series
 * @param {'fev1' | 'fvc'} measurement measurement type
 * @param {boolean | undefined} useAts2019
 * @returns {string} a string of the variability percentage
 */
export const generateSeriesVariabilityPercentageString = (series, measurement, useAts2019) => {
  if (measurement === 'fev1') {
    const variabilityPercentage = useAts2019
      ? series.qualityReport?.bestMeasurements?.fev1VariabilityPerc
        ? series.qualityReport?.bestMeasurements?.fev1VariabilityPerc
        : (series.qualityReport?.bestMeasurements?.fev1Variability !== undefined && series.qualityReport?.bestMeasurements?.fev1)
          ? `${((series.qualityReport.bestMeasurements.fev1Variability / series.qualityReport.bestMeasurements.fev1) * 100)?.toFixed(2)}%`
          : 'N/A'
      : series?.fev1Variability !== undefined && series.fev1
        ? `${((series.fev1Variability / series.fev1) * 100)?.toFixed(2)}%`
        : 'N/A';

    return variabilityPercentage;
  };

  if (measurement === 'fvc') {
    const variabilityPercentage = useAts2019
      ? series.qualityReport?.bestMeasurements?.fvcVariabilityPerc
        ? series.qualityReport?.bestMeasurements?.fvcVariabilityPerc
        : (series.qualityReport?.bestMeasurements?.fvcVariability !== undefined && series.qualityReport?.bestMeasurements?.fvc)
          ? `${((series.qualityReport.bestMeasurements.fvcVariability / series.qualityReport.bestMeasurements.fvc) * 100)?.toFixed(2)}%`
          : 'N/A'
      : series?.fvcVariability !== undefined && series.fvc
        ? `${((series.fvcVariability / series.fvc) * 100)?.toFixed(2)}%`
        : 'N/A';

    return variabilityPercentage;
  };

  return 'N/A';
};

/**
 * @param {*} series best measurements for a series
 * @param {string} measurement the measurement (e.g "fev1PercPred")
 * @param {string} fallbackNumeratorMeasurement the fallback numerator (e.g "fev1")
 * @param {string} fallbackDenominatorMeasurement the fallback denominator (e.g "fev1Pred")
 * @param {boolean | undefined} useAts2019 - used to determine whether or not to use series[measurement]
 * @returns {string} percentage string (e.g "79%")
 */
export const generatePercentPredictedString = (series, measurement, fallbackNumeratorMeasurement, fallbackDenominatorMeasurement, useAts2019) => {
  const result = series[measurement] !== undefined && useAts2019
    ? formatPredicted(series[measurement] * 100)
    : series[fallbackDenominatorMeasurement]
      ? formatPredicted((series[fallbackNumeratorMeasurement] / series[fallbackDenominatorMeasurement]) * 100.0)
      : '0%';

  return result;
};