import { SET_ENTITIES, SET_ENTITY, REMOVE_ENTITY, CLEAR_BILLING, SET_OPTIONS } from './actionTypes';
import { generateBillingEntityMap } from '../../libs/utilsLib';
const INITIAL_STATE = {};

export const billingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ENTITIES:
      return { ...state, entities: action.payload };
    case SET_ENTITY:
      const entities = [...Object.values(state.entities).filter(item => item.billingID !== action.id), action.payload];
      entities.sort((a, b) => a.displayTag <= b.displayTag ? -1 : 1);
      return { ...state, entities: generateBillingEntityMap(entities) };
    case REMOVE_ENTITY:
      const newState = { ...state };
      delete newState?.entities[action.payload];
      return { ...newState };
    case SET_OPTIONS:
      return { ...state, options: action.payload };
    case CLEAR_BILLING:
      return INITIAL_STATE;
    default:
      return state;
  };
};