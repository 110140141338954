import * as React from 'react';
import { Container } from 'react-bootstrap';
import { shallowEqual, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { parseVuplexEvent, conditionalLog } from '../libs/utilsLib';
import { setIsInSession, setIsKioskMode, setKiosk, setMobileDevice, setSelectedOrganization, setIsSpirometerConnected, setResetBluetoothCompleted } from '../redux/kiosk/actionCreators';
import { useAppSelector } from '../redux/store';
import { Kiosk, MobileDevice } from '../Types/Common/KioskTypes';
import { OrganizationType } from '../Types/Common/Types';

const { useEffect } = React;

interface VuplexListenerProps { }

// VUPLEX IS HOW THE KIOSK MOBILE APP COMMUNICATES WITH THE IN-APP BROWSER
export const VuplexListener: React.FC<VuplexListenerProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { isKioskMode, mobileDevice } = useAppSelector(state => state.kiosk);
  const translationText = useAppSelector(state => state.translations, shallowEqual);

  useEffect(() => {
    const vuplexMessageListener = (event: any) => {
      // console.log({ event })
      const { type, message } = parseVuplexEvent(event);
      conditionalLog(true, type, message);
      switch (type) {
        case 'mobileDevice':
          if (!mobileDevice) dispatch(setMobileDevice(message as MobileDevice));
          break;
        case 'organization':
          dispatch(setSelectedOrganization(message as OrganizationType));
          break;
        case 'kiosk':
          dispatch(setKiosk(message as Kiosk));
          break;
        case 'inSession':
          dispatch(setIsInSession(message));
          break;
        case 'spirometerConnected':
          addToast(<Container>{translationText.spirometer || 'Spirometer'} {translationText.connected || 'Connected'}: {message}</Container>, { autoDismiss: true, appearance: 'info' });
          dispatch(setIsSpirometerConnected(true));
          break;
        case 'spirometerDisconnected':
          addToast(<Container>{translationText.spirometer || 'Spirometer'} {translationText.disconnected || 'Disconnected'}</Container>, { autoDismiss: true, appearance: 'warning' });
          dispatch(setIsSpirometerConnected(false));
          break;
        case 'spirometerConnectionStatus':
          // message of this type is true/false - this message is to check the connection when returning from a session and to set it into redux
          if (message) {
            dispatch(setIsSpirometerConnected(true));
          } else {
            dispatch(setIsSpirometerConnected(false));
          }
          break;
        case 'resetBluetoothComplete':
          dispatch(setResetBluetoothCompleted(true));
          break;
        default:
          break;
      };

      if (!isKioskMode) dispatch(setIsKioskMode(true));
    };

    const addVuplexMessageListener = () => window?.vuplex?.addEventListener('message', vuplexMessageListener);

    if (window?.vuplex) {
      addVuplexMessageListener();
    } else {
      window.addEventListener('vuplexready', addVuplexMessageListener);
    };

    return () => {
      window?.vuplex?.removeEventListener('message', vuplexMessageListener);
    }
  }, [addToast, dispatch, isKioskMode, mobileDevice, translationText]);


  return (
    <>
      {children}
    </>
  );
};