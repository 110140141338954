import { applyMiddleware, createStore, Middleware } from "redux";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer, RootState } from './rootReducer';

const logger = createLogger({
  collapsed: true,
});

const middlewares: Middleware[] = [thunk];
if (process.env.REACT_APP_DEV) {
  console.log('Initializing redux-logger');
  middlewares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export type AppDispatch = typeof store.dispatch;

// Use throughout app instead of plain useDispatch and useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;