import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Nav, Navbar, NavDropdown, Container, Collapse } from 'react-bootstrap';

import { ChangeOrganizationModal } from '../Modals/ChangeOrganizationModal';

import { getProviderFromOrganization } from '../../libs/organizationProviderLib';
import { updateViewingAs } from '../../redux/provider/actionCreators';
import { RootState } from '../../redux/rootReducer';
import { OrgSelectOption } from '../../Types/MiscTypes';
import { setSelectedOrg } from '../../redux/organizationProvider/actionCreators';
import { fetchPatients } from '../../redux/patients/actionCreators';
import { isEmptyObjectOrArray } from '../../libs/utilsLib';

import './Navbars.css';

const { useState } = React;

interface SuperAdminNavProps {
  showingSuperAdminNav: boolean;
};

export const SuperAdminNav: React.FC<SuperAdminNavProps> = ({ showingSuperAdminNav }) => {
  const dispatch = useDispatch();
  const authenticatedProvider = useSelector((state: RootState) => state.provider, shallowEqual);
  const { organizationsDropdown } = useSelector((state: RootState) => state.organizations, shallowEqual);
  const { organizationsForProviderDropdown, selectedOrg } = useSelector((state: RootState) => state.organizationsForProvider, shallowEqual);
  const [showChangeOrganizationModal, setShowChangeOrganizationModal] = useState(false);
  const handleClickNavDropdown = () => setShowChangeOrganizationModal(true);

  const changeOrganization = async (newSelectedOrg: OrgSelectOption) => {
    try {
      let permission = await getProviderFromOrganization(newSelectedOrg.id, authenticatedProvider.providerID);
      const updatedViewingAs = {
        organizationID: newSelectedOrg.id,
        name: newSelectedOrg.name,
        program: newSelectedOrg.program,
        orgAdmin: permission?.orgAdmin ?? 0,
        readOnly: permission?.readOnly ?? false
      };
      const populatedOrg = authenticatedProvider.fullAdmin ? organizationsDropdown?.find((org: OrgSelectOption) => org.id === newSelectedOrg.id) : organizationsForProviderDropdown?.find((org: OrgSelectOption) => org.id === newSelectedOrg.id);
      await dispatch(updateViewingAs(authenticatedProvider.providerID, updatedViewingAs));
      dispatch(setSelectedOrg(populatedOrg as OrgSelectOption));
      if (window.location.pathname.includes('/patient/')) window.location.reload();

      dispatch(fetchPatients(newSelectedOrg.id));
    } catch (e) {
      console.log(e);
    } finally {
      setShowChangeOrganizationModal(false);
    };
  };

  return (
    authenticatedProvider.fullAdmin && !isEmptyObjectOrArray(selectedOrg)
      ? (
        <>
          <Collapse in={showingSuperAdminNav} timeout={300} mountOnEnter unmountOnExit >
            <Container className="SuperAdminNav">
              <Navbar.Text as='h6' className="text-dark mb-0">Viewing As:</Navbar.Text>
              {/* <Navbar.Collapse> */}
              <Nav className="mr-auto">
                <NavDropdown title={`${selectedOrg.name} (${selectedOrg.displayProgram || selectedOrg.program})`} id="SuperAdminNav-Dropdown" onClick={handleClickNavDropdown} className='d-inline' />
              </Nav>
              {/* </Navbar.Collapse> */}
            </Container>
          </Collapse >
          <ChangeOrganizationModal show={showChangeOrganizationModal} setShow={setShowChangeOrganizationModal} selected={selectedOrg} organizationList={organizationsDropdown} onOrganizationChange={changeOrganization} />
        </>
      )
      : null
  )
};