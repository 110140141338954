import { generateSSOPoolsMap } from "../../libs/utilsLib";
import { CLEAR_POOLS, REMOVE_POOL, SET_POOL, SET_POOLS } from "./actionTypes";
import { getSSOPools, createSSOPool, deleteSSOPool } from '../../libs/zephyrxLib';


export const setSSOPools = (payload) => ({ type: SET_POOLS, payload });

export const setSSOPool = (domain, payload) => ({ type: SET_POOL, payload, domain });

export const removedSSOPool = (domain) => ({ type: REMOVE_POOL, domain });

export const clearSSOPools = () => ({ type: CLEAR_POOLS });

export const fetchSSOPools = () => {
  return async (dispatch) => {
    try {
      const { saml: sso } = await getSSOPools();
      sso.sort((a, b) => a.domain <= b.domain ? -1 : 1);
      const ssoMap = generateSSOPoolsMap(sso);
      dispatch(setSSOPools(ssoMap));
    } catch (e) {
      console.log(e);
      dispatch(clearSSOPools());
    };
  };
};

/**
 * @param {{ domain: string, identityProviderName: string, metadataUrl: string }} params 
 */
export const addSSOPool = (params) => {
  return async (dispatch) => {
    try {
      const newSSOPool = await createSSOPool(params);
      const { domain } = newSSOPool;
      dispatch(setSSOPool(domain, newSSOPool));
    } catch (e) {
      console.log(e);
      dispatch(clearSSOPools());
    };
  };
};

/**
 * @param {string} domain the domain for the pool to delete
 */
export const removeSSOPool = (domain) => {
  return async (dispatch) => {
    try {
      const response = await deleteSSOPool(domain);
      if (!response.domain) throw new Error('Error deleting SSO Pool');
      dispatch(removedSSOPool(response.domain));
    } catch (e) {
      console.log(e);
      dispatch(clearSSOPools());
    };
  };
};