export const SET_SERIES = 'SET_SERIES';

export const SET_ALL_SERIES = 'SET_ALL_SERIES';

export const SET_DETAILED_SERIES = 'SET_DETAILED_SERIES';

export const SET_DETAILED_CHARTS = 'SET_DETAILED_CHARTS';

export const CLEAR_DETAILED_SERIES = 'CLEAR_DETAILED_SERIES';

export const SET_SERIES_DEMOGRAPHICS = 'SET_SERIES_DEMOGRAPHICS';

export const SET_CHART_REPORT = 'SET_CHART_REPORT';

export const SET_SERIES_BASELINES = 'SET_SERIES_BASELINES';

export const CLEAR_SERIES = 'CLEAR_SERIES';

export const SET_PFT_TYPE = 'SET_PFT_TYPE';
