import { API } from "aws-amplify";

const DEMOGRAPHIC_API = "patient-demographic";

export function checkPatientDemographicAPI() {
  return API.get(DEMOGRAPHIC_API, "/check");
}

export function debugGet() {
  return API.get(DEMOGRAPHIC_API, "/debug-get");
}

export function createPatientDemographic(patientDemographic) {
  return API.post(DEMOGRAPHIC_API, `/${patientDemographic.patientID}`, {
    body: patientDemographic
  });
}

export function getPatientDemographic(patientID, demographicID) {
  if (typeof (patientID) === 'object') {
    patientID = patientID.patientID;
    demographicID = patientID.demographicID;
  }
  return API.get(DEMOGRAPHIC_API, `/${patientID}/${demographicID}`);
}

export function updateSingleSeriesDemographic(patientID, demographicID, updatedDemographic) {
  return API.put(DEMOGRAPHIC_API, `/${patientID}/${demographicID}`, {
    body: updatedDemographic
  });
}

export function updateAllSeriesDemographicOnDay(patientID, demographicID, updatedDemographic, dateString) {
  return API.put(DEMOGRAPHIC_API, `/${patientID}/${demographicID}`, {
    body: { ...updatedDemographic, day: dateString }
  });
}

/**
 * @param {string} patientID
 * @param {*} updatedDemographic
 * @param {string} start
 * @param {string} end
 * @returns {Promise<{ demographicIDs: import("../Types/Common/BrandedTypes").DemographicID[]; patientID: import("../Types/Common/BrandedTypes").PatientID; shouldUpdateCurrentDemographicID: boolean }>} successful response returns patientID and demographicID[]
 */
export function updateDemographicsInDateRange(patientID, updatedDemographic, start, end) {
  return API.put(DEMOGRAPHIC_API, `/${patientID}`, {
    body: { ...updatedDemographic, start, end }
  });
}

export function getAllPatientDemographic(patient) {
  let patientID = typeof (patient) === 'object' ? patient.patientID : patient;
  return API.get(DEMOGRAPHIC_API, `/${patientID}`);
}

export function removePatientDemographic(patientID, demographicID) {
  if (typeof (patientID) === 'object') {
    patientID = patientID.patientID;
    demographicID = patientID.demographicID;
  }
  return API.get(DEMOGRAPHIC_API, `/remove/${patientID}/${demographicID}`);
}

export async function getLastValidDemographics(patientID) {
  try {
    return await API.get(DEMOGRAPHIC_API, `/valid/${patientID}`);
  } catch {
    console.log("fallback failed, returning {name: noDemographics}")
    return { name: "noDemographics" }
  }
}

export function removeAllPatientDemographic(patient) {
  let patientID = typeof (patient) === 'object' ? patient.patientID : patient;
  return API.get(DEMOGRAPHIC_API, `/remove/all/${patientID}`);
}

export async function fallbackPatientDemographic(patientID) {
  try {
    let allDemos = await debugGet();
    return allDemos[patientID][allDemos[patientID].length - 1];
  } catch {
    console.log("fallback failed, returning {name: noDemographics}")
    return { name: "noDemographics" };
  }
}

export const getDemographics = async (patientID, demographicID) => {
  let patientDemographics;
  try {
    patientDemographics = await getPatientDemographic(patientID, demographicID);
  } catch (e) {
    console.log("Error getting patient demographic");
    console.log(e);
    patientDemographics = await getLastValidDemographics(patientID);
  }
  return patientDemographics;
};

export const genderOptions = (translationText) => [
  { label: translationText.male || 'Male', value: 'MALE' },
  { label: translationText.female || 'Female', value: 'FEMALE' }
];

export const ethnicityOptions = (translationText) =>
  Object.keys(translationText.ethnicities).map((ethnicity) => {
    return { label: translationText.ethnicities[ethnicity], value: ethnicity }
  });