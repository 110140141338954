import { Reducer } from 'redux';
import { formatForDropdown } from '../../libs/utilsLib';
import { OrganizationType, } from '../../Types/Common/Types';
import { OrgSelectOption } from '../../Types/MiscTypes';
import { CLEAR_ORGANIZATIONS, REMOVE_ORGANIZATION, SET_DETAILED_ORGANIZATIONS, SET_ORGANIZATIONS_DROPDOWN, SET_ORGANIZATION_IDS, UPDATE_DETAILED_ORGANIZATIONS, UPDATE_ORGANIZATIONS_FOR_DROPDOWN } from './actionTypes';

const INITIAL_STATE = {
  organizationsDropdown: [] as OrgSelectOption[],
  detailedOrganizations: {} as Record<string, OrganizationType>,
  organizationIDs: [] as string[]
};

interface ActionType {
  type: string;
  payload: OrgSelectOption | OrgSelectOption[] | string[] | Record<string, OrganizationType> | string;
  id?: string;
};

export const organizationsReducer: Reducer<typeof INITIAL_STATE, ActionType> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DETAILED_ORGANIZATIONS:
      return { ...state, detailedOrganizations: action.payload as Record<string, OrganizationType> };
    case SET_ORGANIZATION_IDS:
      return { ...state, organizationIDs: action.payload };
    case SET_ORGANIZATIONS_DROPDOWN:
      return { ...state, organizationsDropdown: action.payload };
    case UPDATE_DETAILED_ORGANIZATIONS:
      if (!action.id || typeof action.payload !== 'object') return state;
      return { ...state, detailedOrganizations: { ...state.detailedOrganizations, [action.id]: { ...action.payload } } };
    case UPDATE_ORGANIZATIONS_FOR_DROPDOWN:
      if (typeof action.payload !== 'object' || Array.isArray(action.payload) || !action.payload.id) return;
      const filteredDropdown = state.organizationsDropdown.filter(org => org.id !== action.payload.id);
      const organizationsDropdown = formatForDropdown([...filteredDropdown, action.payload]);
      return { ...state, organizationsDropdown };
    case REMOVE_ORGANIZATION:
      if (!action.payload || typeof action.payload !== 'string') return state;
      const updatedOrgIDs = state.organizationIDs.filter(orgID => orgID !== action.payload);
      const updatedDropdown = state.organizationsDropdown.filter(dropdown => dropdown.id !== action.payload);
      const updatedDetailedOrgs = { ...state.detailedOrganizations };
      delete updatedDetailedOrgs[action.payload];
      return { organizationIDs: updatedOrgIDs, detailedOrganizations: updatedDetailedOrgs, organizationsDropdown: updatedDropdown };
    case CLEAR_ORGANIZATIONS:
      return INITIAL_STATE;
    default:
      return state;
  };
};