import { API } from "aws-amplify";
import { getProvider } from "./providerLib";
import { ping } from "./zephyrxLib";
import { isEmailInWhitelist } from "./providerLib";

const ORGANIZATION_PROVIDER_API = "organization-provider";

export function checkOrganizationProviderAPI() {
  return API.get(ORGANIZATION_PROVIDER_API, "/check");
}

export function linkProviderToOrganization(organizationProvider) {
  return API.post(ORGANIZATION_PROVIDER_API, "/link", {
    body: organizationProvider
  });
}

export function updateLinkProviderToOrganization(organizationProvider) {
  return API.put(ORGANIZATION_PROVIDER_API, `/${organizationProvider.organizationID}/${organizationProvider.providerID}`, {
    body: organizationProvider
  });
}

export function getProviderFromOrganization(organizationID, providerID) {
  return API.get(ORGANIZATION_PROVIDER_API, `/${organizationID}/${providerID}`);
}

export function removeOrganizationProvider(organizationProvider) {
  return API.put(ORGANIZATION_PROVIDER_API, "/remove", {
    body: organizationProvider
  });
}

export function removeProvider(providerID) {
  return API.get(ORGANIZATION_PROVIDER_API, `/remove/provider/${providerID}`);
}

export function removeProviderFromOrganizationProvider(organizationID, providerID) {
  return API.get(ORGANIZATION_PROVIDER_API, `/remove/${organizationID}/${providerID}`);
}

export function removeOrganizationFromOrganizationProvider(organizationID) {
  return API.get(ORGANIZATION_PROVIDER_API, `/remove/organization/${organizationID}`);
}

export function getProvidersForOrganization(organizationID) {
  return API.get(ORGANIZATION_PROVIDER_API, `/providers/${organizationID}`);
}

export function getOrganizationsForProvider(providerID) {
  return API.get(ORGANIZATION_PROVIDER_API, `/organizations/${providerID}`);
}

export function getActiveOrganizationsForProvider(providerID) {
  return API.get(ORGANIZATION_PROVIDER_API, `/organizations/active/${providerID}`);
}

export async function getSpecificFieldsForProviderOrganizations(providerID, fields = []) {
  let response = await API.post(ORGANIZATION_PROVIDER_API, `/organizations/active`, {
    body: { providerID, projectionExpression: fields }
  });
  return response;
}

export const findOrganizationAdmins = async (organizationID, providerIDArray) =>
  (await
    Promise.all(providerIDArray.map(
      async provider => {
        try {
          const orgProvider = await getProviderFromOrganization(organizationID, provider);
          return (orgProvider?.orgAdmin || orgProvider?.fullAdmin) ? (await getProvider(provider)).name : null;
        } catch (e) {
          console.log(provider, e);
          return null;
        }
      }
    ))
  ).filter(provider => !!provider);

export const getProvidersAdminsFromOrganization = async (organizationID, providers) =>
  (await
    Promise.all(providers.map(
      async provider => {
        try {
          const org = await getProviderFromOrganization(organizationID, provider.providerID);
          provider.orgAdmin = org.orgAdmin;
          provider.status = org.status
          return provider;
        } catch (e) {
          console.log(provider, e);
          return null;
        }
      }
    ))
  ).filter(provider => !!provider);

/**
 * 
 * @param {string[]} organizationIDs 
 * @param {string} providerID 
 * @returns A promise that resolves to the organizationProvider relationship object for every organization associated with this provider
 */
export const getProviderOrganizations = async (organizationIDs, providerID) => {
  const organizations = await Promise.all(organizationIDs.map(async orgID => {
    try {
      const organization = await getProviderFromOrganization(orgID, providerID);
      return organization;
    } catch (e) {
      console.log(e);
      return null;
    }
  }));
  return organizations.filter(org => !!org);
};

export const checkAllowLists = async (detailedOrganizations, provider) => {
  let ipAddress = await ping();
  const activeOrgs = (await Promise.all(detailedOrganizations.map(async organization => {
    let passSettings = organization?.featureSet?.passwordSettings;
    if ((passSettings?.useIpWhitelist && !passSettings?.ipWhitelist?.includes(ipAddress.ipAddress))
      || (passSettings?.useEmailWhitelist && !isEmailInWhitelist(passSettings?.emailWhitelist, provider.email.toLowerCase()))) {
      console.log('allow list tripped')
      return null;
    }
    else return organization;
  }))).filter(organization => !!organization);
  return activeOrgs;
};