export const SET_PATIENT = 'SET_PATIENT';

export const SET_PATIENT_GRAPH = 'SET_PATIENT_GRAPH';

export const SET_PATIENT_DEMOGRAPHICS = 'SET_PATIENT_DEMOGRAPHICS';

export const SET_ORGANIZATION_CONSENT = 'SET_ORGANIZATION_CONSENT';

export const SET_PATIENT_NAME = 'SET_PATIENT_NAME';

export const SET_SPIROMETER_ID = 'SET_SPIROMETER_ID';

export const SET_CFF_ID = 'SET_CFF_ID';

export const SET_EMR_ID = 'SET_EMR_ID';

export const CLEAR_PATIENT = 'CLEAR_PATIENT';