import { Dispatch } from 'redux';
import { SET_PATIENTS, SET_NON_OVERREAD_PATIENTS, CLEAR_PATIENTS, SET_NON_ACCEPTED_PATIENTS } from './actionTypes';
import { generatePatientMap, getBatchedPatientDetails } from '../../libs/patientLib';
import { BatchedPatientDetails, NonAcceptedPatient, } from '../../Types/Common/Types';
// import { getNonOverreadPatients, getNotConsentedPatients, getInitials, getSpirometerFromPatientID, } from '../../libs/patientLib';


export const setPatients = (payload: { [key: string]: BatchedPatientDetails }) => ({ type: SET_PATIENTS, payload });

export const setNonOverreadPatients = (payload: BatchedPatientDetails[]) => ({ type: SET_NON_OVERREAD_PATIENTS, payload });

export const setNonAcceptedPatients = (payload: NonAcceptedPatient[]) => ({ type: SET_NON_ACCEPTED_PATIENTS, payload });

export const clearPatients = () => ({ type: CLEAR_PATIENTS });

export const fetchPatients = (organizationID: string) => {
  return async (dispatch: Dispatch) => {
    const { patients, nonAcceptedPatients } = await getBatchedPatientDetails(organizationID);
    const patientsMap = generatePatientMap(patients);
    dispatch(setPatients(patientsMap));
    dispatch(setNonAcceptedPatients(nonAcceptedPatients));
  };
};

// TODO
// export const fetchNonOverreadPatients = (organizationID) => {
//   return async (dispatch) => {
//     const nonOverreadPatients = await getNonOverreadPatients(organizationID);
//     const nonOverreadPatientIDs = nonOverreadPatients.map(patient => patient.patientID);
//     dispatch(setNonOverreadPatients({ nonOverreadPatients, nonOverreadPatientIDs }));
//   };
// };

// export const fetchNonConsentedPatients = (organizationID) => {
//   return async (dispatch) => {
//     const { patientIDs } = await getNotConsentedPatients(organizationID);
//     const nonConsentedPatients = await Promise.all(patientIDs.map(async patientID => {
//       let results = await Promise.all([getInitials(patientID), getSpirometerFromPatientID(patientID)])
//       return { name: results[0].initials, spirometer: results[1].spirometerIDs[0] }
//     }));
//     dispatch(setNonConsentedPatients({ nonConsentedPatients, nonConsentedPatientIDs: patientIDs }));
//   };
// };
